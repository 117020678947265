/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  HydraError,
  HydraViolationError,
  instanceOfHydraError,
  instanceOfHydraViolationError,
} from './hydra-errors';

export class ServerError extends Error {
  public httpStatusCode = -1;

  public originalError?: Record<string, any>;

  constructor(rawError: any) {
    super(rawError.message);
    this.name = 'ServerError';

    if (rawError.response !== undefined) {
      const error = rawError.response.data;
      let message = '';

      this.httpStatusCode = rawError.response.status;
      this.originalError = error;

      if (instanceOfHydraError(error)) {
        if (instanceOfHydraViolationError(error)) {
          const hydraViolationError = new HydraViolationError(error);

          // if there is only 1 violation the description is just a less descriptive copy
          message =
            hydraViolationError.violations.length === 1
              ? hydraViolationError.violations[0].message
              : hydraViolationError.description;

          if (hydraViolationError.violations.length > 1) {
            hydraViolationError.violations.forEach((violation) => {
              message += `\n${violation.message}`;
            });
          }
        } else {
          const hydraError = new HydraError(error);
          message = hydraError.description;
        }

        this.message = message;
      }
    }
  }
}
