/* eslint-disable require-atomic-updates */
import { initializeDataDog } from '@/DataDog';

import { ConfigurationValidationError } from '@/errors/configuration-validation-error';
import { TestUser } from '@/lib/sdk';
import { AssessmentEngineFactory } from '@/lib/sdk';
import { type Configuration, type ValidatedConfiguration, setupConfiguration } from '../ClientConfiguration';
import { testEnvironments, environmentMap } from '../ConfigurationValidation';
import type { EngineType } from './useTypes';

export const useAssessmentInit = async (configuration: ValidatedConfiguration): Promise<EngineType> => {
  initializeDataDog(configuration);

  const apiHost = environmentMap.get(configuration.apiEnvironment);

  if (apiHost) {
    return AssessmentEngineFactory.getAssessmentEngine(
      apiHost,
      configuration.userId,
      configuration.apiToken,
      configuration.configurationId,
      configuration.displayCareers,
      configuration.displayBadging,
      configuration.enableReportBlocking && !configuration.readOnly,
      configuration.assessmentId,
      configuration.locale
    );
  }

  throw new ConfigurationValidationError('no apiHost value found');
};

export const useConfigurationInit = async (testEnvironment: string, unvalidatedconfiguration: Configuration): Promise<ValidatedConfiguration> => {
  // Only used for local hes developer vue server testing
  if (testEnvironments.includes(unvalidatedconfiguration.apiEnvironment)) {

    //override apiHost value with env value for testing only
    unvalidatedconfiguration.apiEnvironment = testEnvironment as ValidatedConfiguration['apiEnvironment'];

    const apiHost = environmentMap.get(unvalidatedconfiguration.apiEnvironment);

    if (!apiHost) {
      throw new ConfigurationValidationError('invalid environment');
    }

    const 
      testUser = new TestUser(apiHost),
  
      // If configuration.userId is set, existing user and assessment are being used
      userId = unvalidatedconfiguration.userId ? unvalidatedconfiguration.userId : (await testUser.createUser()).id,
      assessmentClient = await testUser.getAssessmentClient(userId);

    unvalidatedconfiguration.userId = userId;
    unvalidatedconfiguration.apiToken = assessmentClient.getToken();
  }

  return setupConfiguration(unvalidatedconfiguration);
};
