import { getArrayFromHydraObject, Client } from './client';
import { ServerError } from './errors/server-error';
import * as Api from './typescript-axios';

export class AssessmentClient extends Client {
  /**
   *  AssessmentApi object
   *
   * @type Api.AssessmentApi
   * @memberof Client
   */
  private assessmentApi: Api.AssessmentApi;

  /**
   *  UserAnswerApi object
   *
   * @type Api.UserAnswerApi
   * @memberof Client
   */
  private userAnswerApi: Api.UserAnswerApi;

  /**
   *  ReportUserAnswerApi object
   *
   * @type Api.ReportUserAnswerApi
   * @memberof Client
   */
  private reportUserAnswerApi: Api.ReportUserAnswerApi;

  /**
   *  ReportElementRatingApi object
   *
   * @type Api.ReportElementRatingApi
   * @memberof Client
   */
  private reportElementRatingApi: Api.ReportElementRatingApi;

  public constructor(basePath: string, token: string, locale?: string) {
    super(basePath, token, locale);
    this.assessmentApi = new Api.AssessmentApi(this.configuration, this.basePath);
    this.userAnswerApi = new Api.UserAnswerApi(this.configuration, this.basePath);
    this.reportUserAnswerApi = new Api.ReportUserAnswerApi(this.configuration, this.basePath);
    this.reportElementRatingApi = new Api.ReportElementRatingApi(this.configuration, this.basePath);
  }

  public async getAssessmentMinimumReads(): Promise<Array<Api.AssessmentMinimumRead>> {
    try {
      const options = { ...this.options };
      
      options.params['include[]'] = 'scores';

      const result = await this.assessmentApi.getAssessmentCollection(
        undefined,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        options,
      );

      return getArrayFromHydraObject<Api.AssessmentMinimumRead>(result);
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getAssessment(assessmentId: string): Promise<Api.AssessmentFullRead> {
    try {
      const result = await this.assessmentApi.getAssessmentItem(assessmentId, this.options);

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }


  public async getMinimumAssessment(assessmentId: string): Promise<Api.AssessmentMinimumRead> {
    try {
      const options = { ...this.options };

      options.params.id = assessmentId;

      const result = await this.assessmentApi.getAssessmentCollection(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        options,
      );
      const objects = getArrayFromHydraObject<Api.AssessmentMinimumRead>(result);

      return objects[0];
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async startAssessment(
    userId: string,
    productConfigurationId: string,
  ): Promise<Api.AssessmentFullRead> {
    try {
      const result = await this.assessmentApi.startAssessmentAssessmentCollection(
        {
          productConfiguration: `/api/product_configurations/${productConfigurationId}`,
          user: `/api/users/${userId}`,
        },
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async updateQuestionAnswer(
    assessmentId: string,
    answerId: string,
    userAnswerId: string,
  ): Promise<Api.UserAnswerFullRead> {
    try {
      const result = await this.userAnswerApi.changeUserAnswerUserAnswerItem(
        userAnswerId,
        {
          assessment: `/api/assessments/${assessmentId}`,
          answer: `/api/answers/${answerId}`,
        },
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async answerQuestion(
    assessmentId: string,
    answerId: string,
  ): Promise<Api.UserAnswerFullRead> {
    try {
      const result = await this.userAnswerApi.respondToQuestionUserAnswerCollection(
        {
          assessment: `/api/assessments/${assessmentId}`,
          answer: `/api/answers/${answerId}`,
        },
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async calculateAssessment(assessmentId: string): Promise<Api.AssessmentFullRead> {
    try {
      const result = await this.assessmentApi.calculateAssessmentAssessmentItem(
        assessmentId,
        {},
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getCareerClusters(): Promise<Api.CareerClusterFullRead[]> {
    try {
      const careerClusterApi = new Api.CareerClusterApi(this.configuration, this.basePath);

      const result = await careerClusterApi.getCareerClusterCollection(
        undefined,
        false,
        this.options,
      );

      return getArrayFromHydraObject<Api.CareerClusterFullRead>(result);
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getCareerClusterRatings(): Promise<Api.CareerClusterRatingFullRead[]> {
    try {
      const careerClusterRatingApi = new Api.CareerClusterRatingApi(
        this.configuration,
        this.basePath,
      );

      const result = await careerClusterRatingApi.getCareerClusterRatingCollection(
        undefined,
        false,
        this.options,
      );

      const objects = getArrayFromHydraObject<Api.AssessmentMinimumRead>(result);

      return Array.isArray(objects) ? objects : [objects];
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async updateCareerClusterRatingQuestion(
    assessmentId: string,
    careerClusterId: string,
    careerClusterRatingId: string,
    careerClusterUserRatingId: string,
  ): Promise<Api.CareerClusterUserRatingFullRead> {
    try {
      const careerClusterUserRatingApi = new Api.CareerClusterUserRatingApi(
        this.configuration,
        this.basePath,
      );

      const result =
        await careerClusterUserRatingApi.changeCareerClusterRatingCareerClusterUserRatingItem(
          careerClusterUserRatingId,
          {
            rating: `/api/career_cluster_ratings/${careerClusterRatingId}`,
          },
          this.options,
        );
      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async answerCareerClusterRatingQuestion(
    assessmentId: string,
    careerClusterId: string,
    careerClusterRatingId: string,
  ): Promise<Api.CareerClusterUserRatingFullRead> {
    try {
      const careerClusterUserRatingApi = new Api.CareerClusterUserRatingApi(
        this.configuration,
        this.basePath,
      );

      const result =
        await careerClusterUserRatingApi.respondToCareerClusterRatingCareerClusterUserRatingCollection(
          {
            assessment: `/api/assessments/${assessmentId}`,
            careerCluster: `/api/career_clusters/${careerClusterId}`,
            rating: `/api/career_cluster_ratings/${careerClusterRatingId}`,
          },

          this.options,
        );
      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async answerAssessmentRatingQuestion(
    assessmentId: string,
    answerId: string,
  ): Promise<Api.UserAnswerFullRead> {
    return this.answerQuestion(assessmentId, answerId);
  }

  public async updateAssessmentRatingQuestion(
    assessmentId: string,
    answerId: string,
    userAnswerId: string,
  ): Promise<Api.UserAnswerFullRead> {
    return this.updateQuestionAnswer(assessmentId, answerId, userAnswerId);
  }

  public static getRawId(fullId: string): string {
    const rawId = fullId.split('/').pop();

    if (rawId !== undefined) {
      return rawId;
    }

    throw new Error('Invalid Id');
  }
}
