/* eslint-disable react/static-property-placement */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export interface Violation {
  propertyPath: string;
  message: string;
}

// Wrapper class for hydra error as it is full of unusable attribute names
export class HydraError {
  public context: string;

  public type: string;

  public title: string;

  public description: string;

  public constructor(object: any) {
    this.context = object['@context'];
    this.type = object['@type'];
    this.title = object['hydra:title'];
    this.description = object['hydra:description'];
  }
}

export class HydraViolationError extends HydraError {
  public violations: Array<Violation>;

  public constructor(object: any) {
    super(object);
    this.violations = object.violations;
  }
}

export function instanceOfViolation(object: any): object is Violation {
  return 'propertyPath' in object && 'message' in object;
}

export function instanceOfHydraError(object: any): object is HydraViolationError {
  return 'hydra:description' in object;
}

export function instanceOfHydraViolationError(object: any): object is HydraViolationError {
  return (
    'hydra:description' in object &&
    'violations' in object &&
    Array.isArray(object.violations) &&
    instanceOfViolation(object.violations[0])
  );
}
