import { AssessmentClient } from '../lib/assessment-client';
import type { CareerClusterAndRatings } from '../lib/career-clusters-and-ratings';
import * as Errors from '../lib/errors';
import * as Api from '../lib/typescript-axios';
import { AssessmentEngine } from './assessment-engine';
import { ReportEngine } from './report-engine';

export class PersonalityAssessmentEngine extends AssessmentEngine {
  public static readonly STATE_RATE_CAREER_CLUSTERS = 'RateCareerClusters';

  public static readonly STATE_RATE_ASSESSMENT = 'RateAssessment';

  public static readonly STATE_TIE_RESOLVEMENT = 'TieResolvement';

  public static readonly STATE_INCONSISTENT = 'Inconsistent';

  private careerClusters: Api.CareerClusterFullRead[];

  private careerClusterRatings: Api.CareerClusterRatingFullRead[];

  private currentCareerClusterUserRatings: Map<
    string,
    Api.CareerClusterUserRatingFullRead
  > = new Map();

  public constructor(
    userId: string,
    assessmentClient: AssessmentClient,
    assessment: Api.AssessmentFullRead,
    careerClusters: Api.CareerClusterFullRead[],
    careerClusterRatings: Api.CareerClusterRatingFullRead[],
    hasCareers: boolean,
    hasBadges: boolean,
  ) {
    super(userId, assessmentClient, assessment, hasCareers, hasBadges);

    this.careerClusters = careerClusters;
    this.careerClusterRatings = careerClusterRatings;
    this.currentCareerClusterUserRatings = this.initCurrentCareerClusterUserRatings();
    this.initializeRatingAnswers();
    this.assessment.status = this.initializeAssessmentState();
    this.currentQuestions = this.initializeCurrentQuestions();
    this.currentUserAnswers = this.initializeCurrentUserAnswers();
    this.questionIndex = this.calculateQuestionIndex();
  }

  protected initializeCurrentQuestions(): Array<Api.QuestionFullRead> {
    return this.assessment.status === PersonalityAssessmentEngine.STATE_TIE_RESOLVEMENT
      ? this.assessment.tiebreakerQuestions
      : this.assessment.questions;
  }

  protected initializeCurrentUserAnswers(): Map<string, Api.UserAnswerFullRead> {
    if (this.assessment.status === PersonalityAssessmentEngine.STATE_TIE_RESOLVEMENT) {
      return AssessmentEngine.getUserAnswerMap(this.assessment.tiebreakerUserAnswers);
    }

    return super.initializeCurrentUserAnswers();
  }

  // The API pushes the state to the maximum it COULD be but we need to complete
  // in the order we use in the SPA
  protected initializeAssessmentState(): string {
    if (
      (this.assessment.status === AssessmentEngine.STATE_ANSWER_QUESTIONS &&
        this.assessment.questions.length === this.assessment.userAnswers.length) ||
      (this.assessment.status === PersonalityAssessmentEngine.STATE_TIE_RESOLVEMENT &&
        this.assessment.tiebreakerQuestions.length === this.assessment.tiebreakerUserAnswers.length)
    ) {
      return PersonalityAssessmentEngine.STATE_RATE_CAREER_CLUSTERS;
    }

    return this.assessment.status;
  }

  private initCurrentCareerClusterUserRatings(): Map<string, Api.CareerClusterUserRatingFullRead> {
    const currentCareerClusterUserRatings = new Map();

    this.assessment.careerClusterUserRatings.forEach((careerClusterUserRating) => {
      currentCareerClusterUserRatings.set(
        careerClusterUserRating.careerCluster.id,
        careerClusterUserRating,
      );
    });

    return currentCareerClusterUserRatings;
  }

  private initCareerClustersAndRatings(): Array<CareerClusterAndRatings> {
    return this.careerClusters.map((careerCluster) => ({
      careerCluster,
      careerClusterRatings: this.careerClusterRatings,
      careerClusterUserRating: this.currentCareerClusterUserRatings.get(careerCluster.id),
    }));
  }

  public canAnswerQuestion(): boolean {
    return [
      AssessmentEngine.STATE_ANSWER_QUESTIONS,
      PersonalityAssessmentEngine.STATE_TIE_RESOLVEMENT,
    ].includes(this.assessment.status);
  }

  public getCurrentClusterRating(careerClusterId: string): Api.CareerClusterUserRatingFullRead {
    return this.currentCareerClusterUserRatings.get(careerClusterId);
  }

  public getPercentComplete(): number {
    const numTiebreakerQuestions = 4;
    let pageCompletionFactor = 0;

    switch (this.getStatus()) {
      case PersonalityAssessmentEngine.STATE_RATE_CAREER_CLUSTERS: {
        pageCompletionFactor = 1;
        break;
      }
      case PersonalityAssessmentEngine.STATE_TIE_RESOLVEMENT: {
        pageCompletionFactor = 2;
        break;
      }
      case PersonalityAssessmentEngine.STATE_RATE_ASSESSMENT: {
        pageCompletionFactor = 3.5;
        break;
      }
      default: {
        pageCompletionFactor = 0;
        break;
      }
    }

    return (
      ((this.assessment.userAnswers.length +
        pageCompletionFactor +
        this.assessment.tiebreakerUserAnswers.length) /
        (this.assessment.questions.length + numTiebreakerQuestions + 4)) *
      100
    );
  }

  private initializeRatingAnswers(): void {
    if (this.assessment.ratingQuestion) {
      this.assessment.ratingQuestion.answers.reverse();
    }
  }

  public async calculateAssessment(): Promise<string> {
    const status = await super.calculateAssessment();

    if (status === PersonalityAssessmentEngine.STATE_RATE_ASSESSMENT) {
      this.initializeRatingAnswers();
    }

    return status;
  }

  protected async initialize(keepCurrentQuestionIndex = false): Promise<void> {
    const [careerClusters, careerClusterRatings] = await Promise.all([
      this.assessmentClient.getCareerClusters(),
      this.assessmentClient.getCareerClusterRatings(),
    ]);
    this.careerClusters = careerClusters;
    this.careerClusterRatings = careerClusterRatings;
    this.currentCareerClusterUserRatings = this.initCurrentCareerClusterUserRatings();
    this.initializeRatingAnswers();
    this.assessment.status = this.initializeAssessmentState();
    super.initialize(keepCurrentQuestionIndex);
  }

  public getCareerClustersAndRatings(): Array<CareerClusterAndRatings> {
    if (this.assessment.status === PersonalityAssessmentEngine.STATE_RATE_CAREER_CLUSTERS) {
      return this.initCareerClustersAndRatings();
    }

    throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
  }

  public async answerCareerClusterRatingQuestion(
    careerClusterId: string,
    careerClusterRatingId: string,
  ): Promise<string> {
    const currentUserRating = this.getCurrentClusterRating(careerClusterId);

    if(currentUserRating && currentUserRating.rating.id === careerClusterRatingId) {
      return this.assessment.status;
    }

    return this.mutex.use(async () => {
      if (this.assessment.status === PersonalityAssessmentEngine.STATE_RATE_CAREER_CLUSTERS) {
        const clusterIndex = this.careerClusters.findIndex(
          (element) => element.id === careerClusterId,
        );
        const careerClusterRatingIndex = this.careerClusterRatings.findIndex(
          (element) => element.id === careerClusterRatingId,
        );

        if (clusterIndex === -1) {
          throw new Error('invalid clusterId');
        }

        if (careerClusterRatingIndex === -1) {
          throw new Error('invalid careerClusterRatingId');
        }

        const careerClusterUserRating = this.currentCareerClusterUserRatings.get(careerClusterId);
        const careerClusterUserRatingId = careerClusterUserRating
          ? careerClusterUserRating.id
          : undefined;

        let careerClusterUserRatingFullRead = null;

        if (careerClusterUserRatingId) {
          careerClusterUserRatingFullRead = await this.assessmentClient.updateCareerClusterRatingQuestion(
            this.assessment.id,
            careerClusterId,
            careerClusterRatingId,
            careerClusterUserRatingId,
          );
        } else {
          careerClusterUserRatingFullRead = await this.assessmentClient.answerCareerClusterRatingQuestion(
            this.assessment.id,
            careerClusterId,
            careerClusterRatingId,
          );
        }

        this.currentCareerClusterUserRatings.set(careerClusterId, careerClusterUserRatingFullRead);

        return this.assessment.status;
      }

      throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
    });
  }

  public getRatingQuestion(): Api.QuestionFullRead {
    if (this.assessment.ratingQuestion) {
      return this.assessment.ratingQuestion;
    }

    throw new Error('assessment.ratingQuestion missing');
  }

  public async answerAssessmentRatingQuestion(ratingId: string): Promise<string> {
    return this.mutex.use(async () => {
      if (this.assessment.status === PersonalityAssessmentEngine.STATE_RATE_ASSESSMENT) {
        if (this.assessment.ratingAnswer) {
          this.assessment.ratingAnswer = await this.assessmentClient.updateAssessmentRatingQuestion(
            this.assessment.id,
            ratingId,
            this.assessment.ratingAnswer.id,
          );
        } else {
          this.assessment.ratingAnswer = await this.assessmentClient.answerAssessmentRatingQuestion(
            this.assessment.id,
            ratingId,
          );
        }
      }

      return this.assessment.status;
    });

    throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
  }

  public async updateAssessmentRatingQuestion(ratingId: string): Promise<string> {
    if (this.assessment.status === PersonalityAssessmentEngine.STATE_REPORT) {
      if (this.assessment.ratingAnswer) {
        const result = await this.assessmentClient.updateAssessmentRatingQuestion(
          this.assessment.id,
          ratingId,
          this.assessment.ratingAnswer.id,
        );
        this.assessment.ratingAnswer = result;

        return this.assessment.status;
      }

      throw new Error('Assessment Rating answer missing');
    }

    throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
  }

  public async getReportEngine(enableReportBlocking = false): Promise<ReportEngine> {
    if (
      this.assessment.status === AssessmentEngine.STATE_REPORT &&
      this.assessment.achieveWorksReport
    ) {
      if (enableReportBlocking) {
        // force API to push the assessment to STATE_REPORT
        const assessmentMinimumRead = await this.assessmentClient.getMinimumAssessment(
          this.assessment.id,
        );
        this.assessment.status = assessmentMinimumRead.status;
      }

      return super.getReportEngine(enableReportBlocking);
    }

    throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
  }

  public setNextState(): string {
    switch (this.assessment.status) {
      case AssessmentEngine.STATE_ANSWER_QUESTIONS: {
        if (this.isCalculateAnswerQuestionsStateAvailable()) {
          this.assessment.status = PersonalityAssessmentEngine.STATE_CALCULATE_ASSESSMENT;
        }
        break;
      }
      case PersonalityAssessmentEngine.STATE_TIE_RESOLVEMENT: {
        if (!this.isTieResolvementStateAvailable()) {
          this.assessment.status = PersonalityAssessmentEngine.STATE_RATE_CAREER_CLUSTERS;
        }
        break;
      }
      case PersonalityAssessmentEngine.STATE_RATE_CAREER_CLUSTERS: {
        if (this.isCalculateRateCareerClustersStateAvailable()) {
          this.assessment.status = PersonalityAssessmentEngine.STATE_CALCULATE_ASSESSMENT;
        }
        break;
      }
      case PersonalityAssessmentEngine.STATE_CALCULATE_ASSESSMENT: {
        if (this.isRateAssessmentStateAvailable()) {
          this.assessment.status = PersonalityAssessmentEngine.STATE_RATE_ASSESSMENT;
        }

        if (this.isTieResolvementStateAvailable()) {
          this.assessment.status = PersonalityAssessmentEngine.STATE_TIE_RESOLVEMENT;
        }
        break;
      }
      case PersonalityAssessmentEngine.STATE_RATE_ASSESSMENT: {
        if (this.assessment.ratingAnswer) {
          this.assessment.status = PersonalityAssessmentEngine.STATE_REPORT;
        }
        break;
      }
      default: {
        throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
      }
    }
    return this.assessment.status;
  }

  public isCalculateAnswerQuestionsStateAvailable(): boolean {
    return this.currentUserAnswers.size === this.currentQuestions.length;
  }

  public isCalculateRateCareerClustersStateAvailable(): boolean {
    return this.careerClusters.length === this.currentCareerClusterUserRatings.size;
  }

  public isRateAssessmentStateAvailable(): boolean {
    return (
      this.isCalculateRateCareerClustersStateAvailable() && !this.isTieResolvementStateAvailable()
    );
  }

  public isTieResolvementStateAvailable(): boolean {
    const tieBreakerUserAnswersLength =
      this.getStatus() === PersonalityAssessmentEngine.STATE_TIE_RESOLVEMENT
        ? this.currentUserAnswers.size
        : this.assessment.tiebreakerUserAnswers.length;

    return this.assessment.tiebreakerQuestions.length > tieBreakerUserAnswersLength;
  }
}
