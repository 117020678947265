import { ReportEngine } from '@/lib/sdk';
import type { CareerUserRatingData } from './../use/useCareerProfileDialog';

export abstract class CareerRatingEngine {
    protected reportEngine: ReportEngine;

    public constructor(reportEngine: ReportEngine) {
      this.reportEngine = reportEngine;
    }

    public abstract deleteCareerUserRating(careerId: string): Promise<void>;

    public abstract createCareerUserRating(careerId: string, rating: number): Promise<CareerUserRatingData>;

    public abstract getCareerUserRating(careerId: string): CareerUserRatingData | null;

    public abstract getCareerUserRatings(): Map<string, CareerUserRatingData>;
}
