// TODO::refactor with parameters functionName, requiredState, actualState
export class AssessmentInvalidStateError extends Error {
  constructor(message: string) {
    super();

    if (this.stack) {
      const throwingFunction = this.stack.split('at ')[1];
      this.message = `${throwingFunction}: ${message}`;
    }
  }
}
