import { AssessmentClient, Configuration, ServerError, UserApi, type UserFullRead } from '..';

export class TestUser {
  protected options = {
    auth: {
      username: 'hes',
      password: '',
    },
  };

  protected basePath = 'http://localhost:8000';

  protected configuration: Configuration;

  public constructor(basePath?: string) {
    this.basePath = basePath || this.basePath;

    this.configuration = new Configuration({
      basePath: this.basePath,
    });
  }

  public getBasePath(): string {
    return this.basePath;
  }

  public async createUser(): Promise<UserFullRead> {
    const userApi = new UserApi(this.configuration, this.basePath);
    const testUser = {
      firstName: 'hes-test-user',
      institution: '/api/institutions/1',
      isUnder13: false,
      customData: '1234',
    };

    try {
      const result = await userApi.createUserUserCollection(testUser, this.options);

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getAssessmentClient(userId: string): Promise<AssessmentClient> {
    const userApi = new UserApi(this.configuration, this.basePath);

    try {
      return await userApi
        .getTokenUserItem(userId, this.options)
        .then((result) => new AssessmentClient(this.basePath, result.data.token));
    } catch (error) {
      throw new ServerError(error);
    }
  }
}