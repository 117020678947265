import { ref } from 'vue';
import { datadogRum } from '@datadog/browser-rum';
import { AssessmentEngine, PersonalityAssessmentEngine } from '../lib/sdk';
import { ReportProps } from '../ReportProps';
import type { Configuration, ValidatedConfiguration, ICustomColors } from '../ClientConfiguration';
import { consoleErrorEnvironments } from '../ConfigurationValidation';

export const getProductLogoFileName = (productLogo: string, currentLanguage: string): string => {
  const productLogoSplit = productLogo.split('/') as Array<string>;

  return `${currentLanguage}_${productLogoSplit[productLogoSplit.length - 1]}`;
};

export const scrollToTopOfParent = (elementId: string, isAlreadyOpen: boolean): void => {
  setTimeout(() => {
    const elementToScroll = document.getElementById(elementId);

    if (elementToScroll && !isAlreadyOpen) {
      elementToScroll.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }, 500);
};

export const openCloseSection = async (reportProps: ReportProps, elementId: string, panelControlType: string, isAlreadyOpen: boolean): Promise<void> => {
  scrollToTopOfParent(`${panelControlType}-${elementId}`, isAlreadyOpen);
  await reportProps.earnReadBadge(elementId);
};

export const logError = (error: Error, unvalidatedconfiguration: Configuration): void => {
  if (consoleErrorEnvironments.includes(unvalidatedconfiguration.apiEnvironment)) {
    console.error(error);
  }

  if (unvalidatedconfiguration.enableDataDog) {
    datadogRum.addError(error, {});
  }
};

export const reloadOnError = async (error: Error, assessmentEngine: AssessmentEngine | PersonalityAssessmentEngine, configuration: ValidatedConfiguration, context: any): Promise<void> => {
  logError(error, configuration);

  await assessmentEngine.reInitialize();
  context.emit('clicked', 'reloadPage');
};

// eslint-disable-next-line
export const getGraphIconPosition = (isRTL: boolean, percentPosition: number): number => isRTL ? 100 - percentPosition : percentPosition;

export const getPrintCustomProperties = (customColors: ICustomColors): string => {
  let styleTag = '<style>.hesAPISPA {';

  for (const [
    key,
    value
  ] of Object.entries(customColors)) {
    styleTag += `--hes-color-${key}: ${value};`;
  }
  styleTag += '}</style>';

  return styleTag;
};
