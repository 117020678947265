import { getArrayFromHydraObject, Client } from './client';
import { ServerError } from './errors/server-error';
import * as Api from './typescript-axios';

export class ReportClient extends Client {
  /**
   *  AssessmentCareerMatchesApi object
   *
   * @type Api.AssessmentCareerMatchesApi
   * @memberof ReportClient
   */
  private assessmentCareerMatchesApi: Api.AssessmentCareerMatchesApi;

  /**
   *  AchieveWorksReportApi object
   *
   * @type Api.AchieveWorksReportApi
   * @memberof ReportClient
   */
  private achieveworksReportApi: Api.AchieveWorksReportApi;

  /**
   *  ReportUserAnswerApi object
   *
   * @type Api.ReportUserAnswerApi
   * @memberof ReportClient
   */
  private reportUserAnswerApi: Api.ReportUserAnswerApi;

  /**
   *  AchieveWorksReportBadgeApi object
   *
   * @type Api.AchieveWorksReportBadgeApi
   * @memberof ReportClient
   */
  private achieveWorksReportBadgeApi: Api.AchieveWorksReportBadgeApi;

  /**
   *  ReportUserAnswerApi object
   *
   * @type Api.reportElementBadgesApi
   * @memberof ReportClient
   */
  private reportElementBadgesApi: Api.ReportElementBadgesApi;

  /**
   *  ReportElementRatingApi object
   *
   * @type Api.ReportElementRatingApi
   * @memberof ReportClient
   */
  private reportElementRatingApi: Api.ReportElementRatingApi;

  /**
   *  CareerApi object
   *
   * @type Api.CareerApi
   * @memberof ReportClient
   */
  private careerApi: Api.CareerApi;

  /**
   *  CareerApi object
   *
   * @type Api.CareerApi
   * @memberof ReportClient
   */
  private careerWageApi: Api.CareerWageApi;

  /**
   *  CareerApi object
   *
   * @type Api.CareerApi
   * @memberof ReportClient
   */
  private careerUserRatingApi: Api.CareerUserRatingApi;

  /**
   *  AchieveWorksReportRatingApi object
   *
   * @type Api.AchieveWorksReportRatingApi
   * @memberof ReportClient
   */
  private achieveWorksReportRatingApi: Api.AchieveWorksReportRatingApi;

  /**
   *  QuestionApi object
   *
   * @type Api.QuestionApi
   * @memberof ReportClient
   */
  private questionApi: Api.QuestionApi;

  public constructor(basePath: string, token: string, locale?: string) {
    super(basePath, token, locale);
    this.assessmentCareerMatchesApi = new Api.AssessmentCareerMatchesApi(
      this.configuration,
      this.basePath,
    );
    this.achieveworksReportApi = new Api.AchieveWorksReportApi(this.configuration, this.basePath);
    this.reportUserAnswerApi = new Api.ReportUserAnswerApi(this.configuration, this.basePath);
    this.achieveWorksReportBadgeApi = new Api.AchieveWorksReportBadgeApi(
      this.configuration,
      this.basePath,
    );
    this.reportElementBadgesApi = new Api.ReportElementBadgesApi(this.configuration, this.basePath);
    this.reportElementRatingApi = new Api.ReportElementRatingApi(this.configuration, this.basePath);
    this.careerApi = new Api.CareerApi(this.configuration, this.basePath);
    this.careerWageApi = new Api.CareerWageApi(this.configuration, this.basePath);
    this.careerUserRatingApi = new Api.CareerUserRatingApi(this.configuration, this.basePath);
    this.achieveWorksReportRatingApi = new Api.AchieveWorksReportRatingApi(
      this.configuration,
      this.basePath,
    );
    this.questionApi = new Api.QuestionApi(this.configuration, this.basePath);
  }

  public async getAnswersForQuestion(questionId: string): Promise<Array<Api.AnswerMinimumRead>> {
    try {
      const result = await this.questionApi.apiQuestionsAnswersGetSubresourceQuestionSubresource(
        questionId,
        undefined,
        false,
        this.options,
      );

      return getArrayFromHydraObject<Api.AnswerMinimumRead>(result);
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getAssessmentCareerMatchesByAssessment(): Promise<
    Map<string, Api.AssessmentCareerMatchesFullRead>
  > {
    try {
      const result = await this.assessmentCareerMatchesApi.getAssessmentCareerMatchesCollection(
        undefined,
        false,
        this.options,
      );      
      const assessmentCareerMatchesByAssessment = new Map();      
      const objects = getArrayFromHydraObject<Api.AssessmentCareerMatchesFullRead>(result);

      objects.forEach(
        (assessmentCareerMatchesFullRead: Api.AssessmentCareerMatchesFullRead) => {
          assessmentCareerMatchesByAssessment.set(
            ReportClient.getRawId(assessmentCareerMatchesFullRead.assessment),
            assessmentCareerMatchesFullRead,
          );
        },
      );

      return assessmentCareerMatchesByAssessment;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getReportRatings(isTruTalents = false): Promise<Array<Api.AchieveWorksReportRatingFullRead>> {
    try {
      const options = JSON.parse(JSON.stringify(this.options));

      if(isTruTalents) {
        options.params.isTruTalents = true;
      }

      const result = await this.achieveWorksReportRatingApi.getAchieveWorksReportRatingCollection(
        undefined,
        false,
        options,
      );

      return getArrayFromHydraObject<Api.AchieveWorksReportRatingFullRead>(result);
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getReport(id: string): Promise<Api.AchieveWorksReportFullRead> {
    try {
      const result = await this.achieveworksReportApi.getAchieveWorksReportItem(id, this.options);

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getCareerProfile(careerId: string): Promise<Api.CareerItemFullRead> {
    try {
      const options = { ...this.options };

      options.params['include[]'] = 'relatedCareers';

      const result = await this.careerApi.getCareerItem(careerId, options);

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getCareerWages(careerId: string): Promise<Array<Api.CareerWageFullRead>> {
    try {
      const result = await this.careerWageApi.getCareerWageCollection(
        undefined,
        false,
        careerId,
        undefined,
        this.options,
      );

      return getArrayFromHydraObject<Api.CareerWageFullRead>(result);
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getCareersForReport(reportId: string): Promise<Array<Api.CareerFullRead>> {
    try {
      const result = await this.achieveworksReportApi.getCareersByReportAchieveWorksReportItem(
        reportId,
        this.options,
      );

      return getArrayFromHydraObject<Api.CareerFullRead>(result);
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getAllCareers(): Promise<Array<Api.CareerMinimumRead>> {
    try {
      const result = await this.careerApi.getCareerCollection(undefined, false, this.options);

      return getArrayFromHydraObject<Api.CareerMinimumRead>(result);
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getCareerUserRatings(): Promise<Map<string, Api.CareerUserRatingFullRead>> {
    const careerRating = new Map<string, Api.CareerUserRatingFullRead>();
    try {
      const result = await this.careerUserRatingApi.getCareerUserRatingCollection(
        undefined,
        false,
        this.options,
      );
      const objects = getArrayFromHydraObject<Api.CareerUserRatingFullRead>(result);

      objects.forEach((careerUserRatingFullRead) => {
        careerRating.set(careerUserRatingFullRead.career.id, careerUserRatingFullRead);
      });

      return careerRating;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async deleteCareerUserRating(id: string): Promise<void> {
    try {
      await this.careerUserRatingApi.deleteCareerUserRatingItem(id, this.options);
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async updateCareerUserRating(
    careerUserRatingFullRead: Api.CareerUserRatingFullRead,
    ratingId: string,
  ): Promise<Api.CareerUserRatingFullRead> {
    try {
      const result = await this.careerUserRatingApi.changeCareerUserRatingCareerUserRatingItem(
        careerUserRatingFullRead.id,
        ReportClient.initCareerUserRatingMinimumWrite(
          Client.getRawId(careerUserRatingFullRead.user),
          careerUserRatingFullRead.career.id,
          ratingId,
        ),
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async createCareerUserRating(
    userId: string,
    careerId: string,
    ratingId: string,
  ): Promise<Api.CareerUserRatingFullRead> {
    try {
      const result = await this.careerUserRatingApi.respondToCareerUserRatingCareerUserRatingCollection(
        ReportClient.initCareerUserRatingMinimumWrite(userId, careerId, ratingId),
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getReportUserAnswers(
    reportId: string,
  ): Promise<Map<string, Api.ReportUserAnswerFullRead>> {
    try {
      const result = await this.achieveworksReportApi.getReportUserAnswersByReportAchieveWorksReportItem(
        reportId,
        this.options,
      );
      const answerMap = new Map<string, Api.ReportUserAnswerFullRead>();
      const objects = getArrayFromHydraObject<Api.ReportUserAnswerFullRead>(result);

      objects.forEach((reportUserAnswerFullRead) => {
        answerMap.set(
          reportUserAnswerFullRead.achieveWorksReportQuestion.id,
          reportUserAnswerFullRead,
        );
      });

      return answerMap;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async deleteReportElementRating(id: string): Promise<void> {
    try {
      await this.reportElementRatingApi.deleteReportElementRatingItem(id, this.options);
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getReportElementBadges(
    reportId: string,
  ): Promise<Map<string, Api.ReportElementBadgesFullRead>> {
    try {
      const result = await this.achieveworksReportApi.getReportElementBadgesByReportAchieveWorksReportItem(
        reportId,
        this.options,
      );
      const reportElementBadges = new Map<string, Api.ReportElementBadgesFullRead>();
      const objects = getArrayFromHydraObject<Api.ReportElementBadgesFullRead>(result);      

      objects.forEach((reportElementBadgesFullRead) => {
        reportElementBadges.set(
          ReportClient.getRawId(reportElementBadgesFullRead.achieveWorksReportElement),
          reportElementBadgesFullRead,
        );
      });

      return reportElementBadges;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getReportElementRatings(
    reportId: string,
  ): Promise<Map<string, Api.ReportElementRatingFullRead>> {
    try {
      const achieveworksReportApi = new Api.AchieveWorksReportApi(
        this.configuration,
        this.basePath,
      );
      const result = await achieveworksReportApi.getReportElementRatingsByReportAchieveWorksReportItem(
        reportId,
        this.options,
      );
      const answerMap = new Map<string, Api.ReportElementRatingFullRead>();
      const objects = getArrayFromHydraObject<Api.ReportElementRatingFullRead>(result);

      objects.forEach((reportElementRatingFullRead) => {
        answerMap.set(
          Client.getRawId(reportElementRatingFullRead.achieveWorksReportElement),
          reportElementRatingFullRead,
        );
      });

      return answerMap;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async answerReportQuestion(
    reportId: string,
    questionId: string,
    answerIds: Array<string>,
  ): Promise<Api.ReportUserAnswerFullRead> {
    try {
      const reportUserAnswerMinimumWrite = ReportClient.initReportUserAnswerMinimumWrite(
        reportId,
        questionId,
        answerIds,
      );

      const result = await this.reportUserAnswerApi.postReportUserAnswerCollection(
        reportUserAnswerMinimumWrite,
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async updateReportQuestionAnswer(
    id: string,
    reportId: string,
    questionId: string,
    answerIds: Array<string>,
  ): Promise<Api.ReportUserAnswerFullRead> {
    try {
      const reportUserAnswerMinimumWrite = ReportClient.initReportUserAnswerMinimumWrite(
        reportId,
        questionId,
        answerIds,
      );

      const result = await this.reportUserAnswerApi.putReportUserAnswerItem(
        id,
        reportUserAnswerMinimumWrite,
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getBadges(): Promise<Map<string, Api.AchieveWorksReportBadgeFullRead>> {
    try {
      const badges: Map<string, Api.AchieveWorksReportBadgeFullRead> = new Map();
      const result = await this.achieveWorksReportBadgeApi.getAchieveWorksReportBadgeCollection(
        undefined,
        false,
        this.options,
      );
      const objects = getArrayFromHydraObject<Api.AchieveWorksReportBadgeFullRead>(result);

      objects.forEach((badge) => {
        badges.set(badge.id, badge);
      });

      return badges;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async getBadgeRequirements(
    reportTypeId: string,
  ): Promise<Array<Api.ReportTypeElementBadgeRequirementsFullRead>> {
    const reportTypeElementBadgeRequirementsApi = new Api.ReportTypeElementBadgeRequirementsApi(
      this.configuration,
      this.basePath,
    );

    try {
      const result = await reportTypeElementBadgeRequirementsApi.getReportTypeElementBadgeRequirementsCollection(
        undefined,
        false,
        { headers: this.options.headers, params: { reportTypeId } },
      );

      return getArrayFromHydraObject<Api.ReportTypeElementBadgeRequirementsFullRead>(result);
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async createReportElementBadges(
    reportId: string,
    elementId: string,
    badgeIds: Array<string>,
  ): Promise<Api.ReportElementBadgesFullRead> {
    const reportElementBadgesMinimumWrite = ReportClient.initReportElementBadgesMinimumWrite(
      reportId,
      elementId,
      badgeIds,
    );

    try {
      const result = await this.reportElementBadgesApi.postReportElementBadgesCollection(
        reportElementBadgesMinimumWrite,
        this.options,
      );
      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async updateReportElementBadges(
    id: string,
    reportId: string,
    elementId: string,
    badgeIds: Array<string>,
  ): Promise<Api.ReportElementBadgesFullRead> {
    try {
      const reportElementBadgesMinimumWrite = ReportClient.initReportElementBadgesMinimumWrite(
        reportId,
        elementId,
        badgeIds,
      );

      const result = await this.reportElementBadgesApi.putReportElementBadgesItem(
        id,
        reportElementBadgesMinimumWrite,
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async rateReportElement(
    reportId: string,
    elementId: string,
    ratingId: string,
  ): Promise<Api.ReportElementRatingFullRead> {
    try {
      const reportElementRatingMinimumWrite = ReportClient.initReportElementRatingMinimumWrite(
        reportId,
        elementId,
        ratingId,
      );

      const result = await this.reportElementRatingApi.postReportElementRatingCollection(
        reportElementRatingMinimumWrite,
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  public async updateReportElementRating(
    id: string,
    reportId: string,
    elementId: string,
    ratingId: string,
  ): Promise<Api.ReportElementRatingFullRead> {
    try {
      const reportElementRatingMinimumWrite = ReportClient.initReportElementRatingMinimumWrite(
        reportId,
        elementId,
        ratingId,
      );

      const result = await this.reportElementRatingApi.putReportElementRatingItem(
        id,
        reportElementRatingMinimumWrite,
        this.options,
      );

      return result.data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  private static initReportUserAnswerMinimumWrite(
    reportId: string,
    questionId: string,
    answerIds: Array<string>,
  ): Api.ReportUserAnswerMinimumWrite {
    const answerIdArray = answerIds.map((value) => `/api/achieve_works_report_answers/${value}`);

    return {
      achieveWorksReport: `/api/achieve_works_reports/${reportId}`,
      achieveWorksReportQuestion: `/api/achieve_works_report_questions/${questionId}`,
      achieveWorksReportAnswers: answerIdArray,
    };
  }

  private static initReportElementBadgesMinimumWrite(
    reportId: string,
    elementId: string,
    badgeIds: Array<string>,
  ): Api.ReportElementBadgesMinimumWrite {
    const badgeIdArray = badgeIds.map((value) => `/api/achieve_works_report_badges/${value}`);

    return {
      achieveWorksReport: `/api/achieve_works_reports/${reportId}`,
      achieveWorksReportElement: `/api/achieve_works_report_elements/${elementId}`,
      achieveWorksReportBadges: badgeIdArray,
    };
  }

  private static initReportElementRatingMinimumWrite(
    reportId: string,
    elementId: string,
    ratingId: string,
  ): Api.ReportElementRatingMinimumWrite {
    return {
      achieveWorksReport: `/api/achieve_works_reports/${reportId}`,
      achieveWorksReportElement: `/api/achieve_works_report_elements/${elementId}`,
      achieveWorksReportRating: `/api/achieve_works_report_ratings/${ratingId}`,
    };
  }

  private static initCareerUserRatingMinimumWrite(
    userId: string,
    careerId: string,
    ratingId: string,
  ): Api.CareerUserRatingMinimumWrite {
    return {
      career: `/api/careers/${careerId}`,
      user: `/api/users/${userId}`,
      rating: `/api/career_ratings/${ratingId}`,
    };
  }
}
