/* eslint-disable no-undefined */
import { datadogRum } from '@datadog/browser-rum';
import { getCurrentInstance } from 'vue';
import type { Configuration } from './ClientConfiguration';

export const initializeDataDog = (configuration: Configuration): void => {
  const {
    VITE_DATADOG_ENABLED: enabled,
    VITE_VERSION: version,
    VITE_DATADOG_ENABLE_SESSION_REPLAY: enableSessionReplay,
  } = import.meta.env;

  let {
    VITE_DATADOG_APPLICATION_ID: applicationId,
    VITE_DATADOG_CLIENT_TOKEN: clientToken,
    VITE_DATADOG_SITE: dataDogSite,
  } = import.meta.env;

  if (configuration.apiEnvironment === 'eu_production') {
    applicationId = import.meta.env.VITE_DATADOG_EU_APPLICATION_ID;
    clientToken = import.meta.env.VITE_DATADOG_EU_CLIENT_TOKEN;
    dataDogSite = import.meta.env.VITE_DATADOG_EU_SITE;
  }

  if (enabled === undefined || !JSON.parse(enabled) ||
        version === undefined || applicationId === undefined || clientToken === undefined ||
        !configuration.enableDataDog
  ) {
    return;
  }

  datadogRum.init({
    applicationId,
    clientToken,
    site: dataDogSite,
    service: 'hes-api-client-vue',
    env: configuration.apiEnvironment,
    version,
    traceSampleRate: 100,
    trackUserInteractions: true,
    useCrossSiteSessionCookie: true,
    useSecureSessionCookie: true,
  });

  if (enableSessionReplay !== undefined && JSON.parse(enableSessionReplay)) {
    datadogRum.startSessionReplayRecording();
  }

  datadogRum.setUser({
    id: configuration.userId,
  });

  // Redact sensitive configuration values like the API token before adding it to the DD global context
  const { apiToken, ...redactedConfiguration } = configuration;

  datadogRum.setGlobalContextProperty('configuration', redactedConfiguration);

  const internalInstance = getCurrentInstance();

  if (internalInstance && internalInstance.appContext.app) {
    internalInstance.appContext.app.config.errorHandler = (err, vm, info) => {
       datadogRum.addError(err, {});
    };
  }

};
