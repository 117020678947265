import { find, maxBy} from 'lodash';
import * as Api from '../lib/typescript-axios';

import { AssessmentClient } from '../lib/assessment-client';
import { AssessmentTypeIri } from './assessment-type-iri';
import { BadgeManager } from '../lib/badge-manager';
import { HesMutex } from '../lib/mutex';
import { ReportBlockedError } from '../lib/errors';
import { ReportClient } from '../lib/report-client';

export interface CareerWages {
  average?: string | null | undefined;
  ninetiethPercentile: string | null | undefined;
  seventyFifthPercentile: string | null | undefined;
  fiftiethPercentile: string | null | undefined;
  twentyFifthPercentile: string | null | undefined;
  tenthPercentile: string | null | undefined;
}
export interface CareerWageArea {
  areaId: string;
  areaName: string;
  areaType: string;
  wagesAnnually: CareerWages;
  wagesHourly: CareerWages;
  childAreas: Array<CareerWageArea>;
}

export interface CompareToMeScores {
  skillsScores: Array<Api.AssessmentMacroScoreMinimumRead> | undefined;
  intelligencesScores: Array<Api.AssessmentScoreMinimumRead> | undefined;
}
export class ReportEngine {
  /**
   *  status
   *
   * @type string
   * @memberof ReportEngine
   */
  protected readonly status = 'Report';

  /**
   *  userId
   *
   * @type string
   * @memberof ReportEngine
   */
  protected userId: string;

  /**
   *  mutex
   *
   * @type HesMutex
   * @memberof ReportEngine
   */
  protected mutex: HesMutex;

  /**
   *  allCareersMutex
   *
   * @type HesMutex
   * @memberof ReportEngine
   */
  protected allCareersMutex: HesMutex;

  /**
   *  ReportClient object
   *
   * @type ReportClient
   * @memberof ReportEngine
   */
  protected reportClient: ReportClient;

  /**
   *  AssessmentClient object
   *
   * @type AssessmentClient
   * @memberof ReportEngine
   */
  protected assessmentClient: AssessmentClient;

  /**
   *  assessmentTypeIri
   *
   * @type string
   * @memberof ReportEngine
   */
  protected assessmentTypeIri: string;

  /**
   *  achieveWorksReportTypeId
   *
   * @type string
   * @memberof ReportEngine
   */
  protected achieveWorksReportTypeId: string;

  /**
   *  achieveWorksReport
   *
   * @type Api.AchieveWorksReportFullRead
   * @memberof ReportEngine
   */
  protected achieveWorksReport: Api.AchieveWorksReportFullRead;

  /**
   *  reportUserAnswers
   *
   * @type Array<Api.ReportUserAnswerFullRead>
   * @memberof ReportEngine
   */
  protected reportUserAnswers: Map<string, Api.ReportUserAnswerFullRead>;

  /**
   *  badgeManager
   *
   * @type BadgeManager
   * @memberof ReportEngine
   */
  protected badgeManager: BadgeManager | null;

  /**
   *  reportRatings
   *
   * @type Array<Api.AchieveWorksReportRatingFullRead>
   * @memberof ReportEngine
   */
  protected reportRatings: Array<Api.AchieveWorksReportRatingFullRead>;

  /**
   *  reportElementRatings
   *
   * @type Map<string, Api.ReportElementRatingFullRead>
   * @memberof ReportEngine
   */
  protected reportElementRatings: Map<string, Api.ReportElementRatingFullRead>;

  /**
   *  reportCareers
   *
   * @type Array<Api.CareerFullRead>
   * @memberof ReportEngine
   */
  protected reportCareers: Array<Api.CareerFullRead> = new Array<Api.CareerFullRead>();

  /**
   *  allCareers
   *
   * @type Array<Api.CareerFullRead>
   * @memberof ReportEngine
   */
  protected allCareers: Array<Api.CareerMinimumRead> = new Array<Api.CareerMinimumRead>();

  /**
   *  assessmentMinimumReads
   *
   * @type Array<Api.CareerFullRead>
   * @memberof ReportEngine
   */
  protected assessmentMinimumReads: Array<Api.AssessmentMinimumRead> =
    new Array<Api.AssessmentMinimumRead>();

  /**
   *  assessmentCareerMatches
   *
   * @type Array<Api.AssessmentCareerMatchesFullRead>
   * @memberof ReportEngine
   */
  protected assessmentCareerMatchesByAssessment: Map<string, Api.AssessmentCareerMatchesFullRead> =
    new Map<string, Api.AssessmentCareerMatchesFullRead>();

  /**
   *  assessmentRatingAnswers
   *
   * @type Array<Api.AnswerMinimumRead>
   * @memberof ReportEngine
   */
  protected assessmentRatingAnswers: Array<Api.AnswerMinimumRead> =
    new Array<Api.AnswerMinimumRead>();

  /**
   *  combinedCareers
   *
   * @type Array<Api.CareerFullRead>
   * @memberof ReportEngine
   */
  protected combinedCareers: Array<Api.CareerMinimumRead> = new Array<Api.CareerMinimumRead>();

  /**
   *  careerUserRatings
   *
   * @type Map<string, Api.CareerUserRatingFullRead>
   * @memberof ReportEngine
   */
  protected careerUserRatings: Map<string, Api.CareerUserRatingFullRead> = new Map<
    string,
    Api.CareerUserRatingFullRead
  >();

  /**
   *  compareToMeScores
   *
   * @type CompareToMeScores
   * @memberof ReportEngine
   */
  protected compareToMeScores: CompareToMeScores | undefined;

  private static async initPropertyValues(
    reportClient: ReportClient,
    achieveWorksReportId: string,
    hasCareers: boolean,
    hasBadges: boolean,
    mutex: HesMutex,
  ): Promise<{
    achieveWorksReport: Api.AchieveWorksReportFullRead;
    reportUserAnswers: Map<string, Api.ReportUserAnswerFullRead>;
    badgeManager: BadgeManager | null;
    reportRatings: Array<Api.AchieveWorksReportRatingFullRead>;
    reportElementRatings: Map<string, Api.ReportElementRatingFullRead>;
    reportCareers: Array<Api.CareerFullRead>;
    careerUserRatings: Map<string, Api.CareerUserRatingFullRead>;
    assessmentRatingAnswers: Array<Api.AnswerMinimumRead>;
  }> {
    const reportEnginePromises: Array<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | Promise<any>
      | null
      | Array<Api.CareerFullRead>
      | Map<string, Api.CareerUserRatingFullRead>
      | Array<Api.AnswerMinimumRead>
    > = [];

    const achieveWorksReport = await reportClient.getReport(achieveWorksReportId);

    reportEnginePromises.push(
      reportClient.getReportUserAnswers(achieveWorksReportId),
      reportClient.getReportRatings(),
      reportClient.getReportElementRatings(achieveWorksReportId),
    );

    if (hasBadges) {
      reportEnginePromises.push(
        BadgeManager.getBadgeManager(
          ReportEngine.getIdFromIri(achieveWorksReport.productConfiguration.achieveWorksReportType),
          achieveWorksReport.id,
          reportClient,
          mutex,
        ),
      );
    } else {
      reportEnginePromises.push(null);
    }

    if (hasCareers) {
      reportEnginePromises.push(
        reportClient.getCareersForReport(achieveWorksReportId),
        reportClient.getCareerUserRatings(),
      );
    } else {
      reportEnginePromises.push(
        new Array<Api.CareerFullRead>(),
        new Map<string, Api.CareerUserRatingFullRead>(),
      );
    }

    if (achieveWorksReport.assessmentRating) {
      reportEnginePromises.push(
        reportClient.getAnswersForQuestion(achieveWorksReport.assessmentRating.answer.question.id),
      );
    } else {
      reportEnginePromises.push(new Array<Api.AnswerMinimumRead>());
    }

    // eslint-disable-next-line prefer-const
    const [
      reportUserAnswers,
      reportRatings,
      reportElementRatings,
      badgeManager,
      reportCareers,
      careerUserRatings,
      assessmentRatingAnswers,
    ] = await Promise.all(reportEnginePromises);

    return {
      achieveWorksReport,
      reportUserAnswers,
      badgeManager,
      reportRatings,
      reportElementRatings,
      reportCareers,
      careerUserRatings,
      assessmentRatingAnswers,
    };
  }

  public static async getReportEngine(
    basePath: string,
    userId: string,
    token: string,
    achieveWorksReportId: string,
    hasCareers: boolean,
    hasBadges: boolean,
    enableReportBlocking: boolean,
    isResultReviewed: boolean,
    locale?: string,
  ): Promise<ReportEngine> {
    const reportClient = new ReportClient(basePath, token, locale);
    const assessmentClient = new AssessmentClient(basePath, token, locale);

    if (enableReportBlocking && !isResultReviewed) {
      throw new ReportBlockedError();
    }

    const mutex = new HesMutex();
    const allCareersMutex = new HesMutex();
    const propertyValues = await ReportEngine.initPropertyValues(
      reportClient,
      achieveWorksReportId,
      hasCareers,
      hasBadges,
      mutex,
    );

    return new ReportEngine(
      userId,
      reportClient,
      assessmentClient,
      propertyValues.achieveWorksReport,
      propertyValues.reportUserAnswers,
      propertyValues.badgeManager,
      propertyValues.reportRatings,
      propertyValues.reportElementRatings,
      propertyValues.reportCareers,
      propertyValues.careerUserRatings,
      propertyValues.assessmentRatingAnswers,
      mutex,
      allCareersMutex,
    );
  }

  public constructor(
    userId: string,
    reportClient: ReportClient,
    assessmentClient: AssessmentClient,
    report: Api.AchieveWorksReportFullRead,
    reportUserAnswers: Map<string, Api.ReportUserAnswerFullRead>,
    badgeManager: BadgeManager | null,
    reportRatings: Array<Api.AchieveWorksReportRatingFullRead>,
    reportElementRatings: Map<string, Api.ReportElementRatingFullRead>,
    reportCareers: Array<Api.CareerFullRead>,
    careerUserRatings: Map<string, Api.CareerUserRatingFullRead>,
    assessmentRatingAnswers: Array<Api.AnswerMinimumRead>,
    mutex: HesMutex,
    allCareersMutex: HesMutex,
  ) {
    this.userId = userId;
    this.reportClient = reportClient;
    this.assessmentClient = assessmentClient;
    this.assessmentTypeIri = report.productConfiguration.assessmentType;
    this.achieveWorksReportTypeId = report.productConfiguration.achieveWorksReportType;
    this.achieveWorksReport = report;
    this.reportUserAnswers = reportUserAnswers;
    this.badgeManager = badgeManager;
    this.reportRatings = reportRatings;
    this.reportElementRatings = reportElementRatings;
    this.reportCareers = reportCareers;
    this.careerUserRatings = careerUserRatings;
    this.assessmentRatingAnswers = assessmentRatingAnswers.reverse();
    this.mutex = mutex;
    this.allCareersMutex = allCareersMutex;
    this.normalizeScores();
    this.initializeRatingAnswers();
  }

  private normalizeScores(): void {
    if (this.assessmentTypeIri === AssessmentTypeIri.PERSONALITY_ASSESSMENT_TYPE_IRI) {
      const oldRange = 2;
      const newRange = 100;

      this.achieveWorksReport.assessmentScores.forEach(
        (achieveWorksReportAssessmentScoreFullRead, index) => {
          this.achieveWorksReport.assessmentScores[index].score =
            ((achieveWorksReportAssessmentScoreFullRead.score + 1) * newRange) / oldRange;
        },
      );
    }
  }

  private initializeRatingAnswers(): void {
    if (this.achieveWorksReport.assessmentRating) {
      this.achieveWorksReport.assessmentRating.answer.question.answers =
        this.assessmentRatingAnswers;
    }
  }

  public getUserId(): string {
    return this.userId;
  }

  public getLocale(): string {
    return this.reportClient.getLocale();
  }

  public changeLanguage($language: string): Promise<Api.AchieveWorksReportFullRead> {
    this.reportClient.setLocale($language);
    return this.reInitialize();
  }

  public async reInitialize(): Promise<Api.AchieveWorksReportFullRead> {
    const propertyValues = await ReportEngine.initPropertyValues(
      this.reportClient,
      this.achieveWorksReport.id,
      this.reportCareers.length > 0,
      this.badgeManager !== null,
      new HesMutex(),
    );

    this.achieveWorksReport = propertyValues.achieveWorksReport;
    this.reportUserAnswers = propertyValues.reportUserAnswers;
    this.badgeManager = propertyValues.badgeManager;
    this.reportRatings = propertyValues.reportRatings;
    this.reportElementRatings = propertyValues.reportElementRatings;
    this.reportCareers = propertyValues.reportCareers;
    this.careerUserRatings = propertyValues.careerUserRatings;
    this.assessmentRatingAnswers = propertyValues.assessmentRatingAnswers;

    this.normalizeScores();
    this.initializeRatingAnswers();

    return this.achieveWorksReport;
  }

  public getReport(): Api.AchieveWorksReportFullRead {
    return this.achieveWorksReport;
  }

  public getReportRatings(): Array<Api.AchieveWorksReportRatingFullRead> {
    return this.reportRatings;
  }

  public getCareerRange(): Api.CareerRangeMinimumRead | null {
    return this.achieveWorksReport.careerRange || null;
  }

  public getReportElementRatings(): Map<string, Api.ReportElementRatingFullRead> {
    return this.reportElementRatings;
  }

  public getBadgeManager(): BadgeManager | null {
    return this.badgeManager;
  }

  public getReportCareers(): Api.CareerFullRead[] {
    return this.reportCareers;
  }

  public async getAssessmentMinimumReads(): Promise<Array<Api.AssessmentMinimumRead>> {
    if (this.assessmentMinimumReads.length === 0) {
      this.assessmentMinimumReads = await this.assessmentClient.getAssessmentMinimumReads();
    }

    return this.assessmentMinimumReads;
  }

  public async getCareerProfile(careerId: string): Promise<Api.CareerItemFullRead> {
    return this.reportClient.getCareerProfile(careerId);
  }

  public async getCareerWages(careerId: string): Promise<CareerWageArea | null> {
    const careerWages = await this.reportClient.getCareerWages(careerId);

    const nationCareerWages = careerWages.find(
      (careerWageFullRead) => careerWageFullRead.parentAreaId === null,
    );

    if (nationCareerWages) {
      return ReportEngine.getCareerWageArea(nationCareerWages, careerWages);
    }

    return null;
  }

  private static getCareerWageArea(
    parentCareerWageFullRead: Api.CareerWageFullRead,
    careerWages: Array<Api.CareerWageFullRead>,
  ): CareerWageArea {
    const childWageAreas = [] as Array<CareerWageArea>;

    careerWages.forEach((careerWageFullRead) => {
      if (
        parentCareerWageFullRead.areaType !== 'metro' &&
        careerWageFullRead.parentAreaId === parentCareerWageFullRead.areaId
      ) {
        childWageAreas.push(ReportEngine.getCareerWageArea(careerWageFullRead, careerWages));
      }
    });

    return {
      areaId: parentCareerWageFullRead.areaId,
      areaName: parentCareerWageFullRead.areaName,
      areaType: parentCareerWageFullRead.areaType,
      wagesAnnually: {
        average: parentCareerWageFullRead.meanAverageWage,
        ninetiethPercentile: parentCareerWageFullRead.annualWage90thPercentile,
        seventyFifthPercentile: parentCareerWageFullRead.annualWage75thPercentile,
        fiftiethPercentile: parentCareerWageFullRead.annualWage50thPercentile,
        twentyFifthPercentile: parentCareerWageFullRead.annualWage25thPercentile,
        tenthPercentile: parentCareerWageFullRead.annualWage10thPercentile,
      },
      wagesHourly: {
        average: parentCareerWageFullRead.meanHourlyWage,
        ninetiethPercentile: parentCareerWageFullRead.hourlyWage90thPercentile,
        seventyFifthPercentile: parentCareerWageFullRead.hourlyWage75thPercentile,
        fiftiethPercentile: parentCareerWageFullRead.hourlyWage50thPercentile,
        twentyFifthPercentile: parentCareerWageFullRead.hourlyWage25thPercentile,
        tenthPercentile: parentCareerWageFullRead.hourlyWage10thPercentile,
      },
      childAreas: childWageAreas,
    };
  }

  public async getAllCareers(): Promise<Array<Api.CareerMinimumRead>> {
    return this.allCareersMutex.use(async () => {
      if (this.allCareers.length === 0) {
        this.allCareers = await this.reportClient.getAllCareers(); 
      }

      return this.allCareers;
    });
  }

  public getCareerUserRating(careerId: string): Api.CareerUserRatingFullRead | null {
    const careerUserRating = this.careerUserRatings.get(careerId);
    if (careerUserRating) {
      return careerUserRating;
    }

    return null;
  }

  public getCareerUserRatings(): Map<string, Api.CareerUserRatingFullRead> {
    return this.careerUserRatings;
  }

  public async deleteCareerUserRating(careerId: string): Promise<void> {
    const careerUserRating = this.careerUserRatings.get(careerId);

    if (careerUserRating) {
      await this.reportClient.deleteCareerUserRating(careerUserRating.id);
      this.careerUserRatings.delete(careerId);
    }
  }

  public async createCareerUserRating(
    careerId: string,
    ratingId: string,
  ): Promise<Api.CareerUserRatingFullRead> {
    return this.mutex.use(async () => {
      const careerUserRatingFullRead = this.careerUserRatings.get(careerId);
      const newCareerUserRatingFullRead = await (careerUserRatingFullRead
        ? this.reportClient.updateCareerUserRating(careerUserRatingFullRead, ratingId)
        : this.reportClient.createCareerUserRating(this.userId, careerId, ratingId));

      this.careerUserRatings.set(
        newCareerUserRatingFullRead.career.id,
        newCareerUserRatingFullRead,
      );

      return newCareerUserRatingFullRead;
    });
  }

  public async answerReportQuestion(questionId: string, answerIds: Array<string>): Promise<void> {
    await this.mutex.use(async () => {
      let reportUserAnswerFullRead;

      if (this.reportUserAnswers !== undefined) {
        reportUserAnswerFullRead = this.reportUserAnswers.get(questionId);

        reportUserAnswerFullRead = await (reportUserAnswerFullRead === undefined
          ? this.reportClient.answerReportQuestion(
              this.achieveWorksReport.id,
              questionId,
              answerIds,
            )
          : this.reportClient.updateReportQuestionAnswer(
              reportUserAnswerFullRead.id,
              this.achieveWorksReport.id,
              questionId,
              answerIds,
            ));

        this.reportUserAnswers.set(
          reportUserAnswerFullRead.achieveWorksReportQuestion.id,
          reportUserAnswerFullRead,
        );
      }
    });
  }

  public async deleteReportElementRating(elementId: string): Promise<void> {
    await this.mutex.use(async () => {
      const reportElementRating = this.reportElementRatings.get(elementId);

      if (reportElementRating) {
        await this.reportClient.deleteReportElementRating(reportElementRating.id);
        this.reportElementRatings.delete(elementId);
      }
    });
  }

  public async rateReportElement(
    elementId: string,
    ratingId: string,
  ): Promise<Api.ReportElementRatingFullRead> {
    return this.mutex.use(async () => {
      let reportElementRatingFullRead;

      reportElementRatingFullRead = this.reportElementRatings.get(elementId);

      reportElementRatingFullRead = await (reportElementRatingFullRead === undefined
        ? this.reportClient.rateReportElement(this.achieveWorksReport.id, elementId, ratingId)
        : this.reportClient.updateReportElementRating(
            reportElementRatingFullRead.id,
            this.achieveWorksReport.id,
            elementId,
            ratingId,
          ));

      this.reportElementRatings.set(
        ReportEngine.getIdFromIri(reportElementRatingFullRead.achieveWorksReportElement),
        reportElementRatingFullRead,
      );

      return reportElementRatingFullRead;
    });
  }

  public getReportUserAnswer(questionId: string): Api.ReportUserAnswerFullRead | null {
    const reportUserAnswerFullRead = this.reportUserAnswers.get(questionId);

    if (reportUserAnswerFullRead !== undefined) {
      return reportUserAnswerFullRead;
    }

    return null;
  }

  public getReportElementRating(elementId: string): Api.ReportElementRatingFullRead | null {
    const reportElementRatingFullRead = this.reportElementRatings.get(elementId);
    if (reportElementRatingFullRead !== undefined) {
      return reportElementRatingFullRead;
    }

    return null;
  }

  protected async getAssessmentCareerMatchesByAssessment(): Promise<
    Map<string, Api.AssessmentCareerMatchesFullRead>
  > {
    if (this.assessmentCareerMatchesByAssessment.size === 0) {
      this.assessmentCareerMatchesByAssessment =
        await this.reportClient.getAssessmentCareerMatchesByAssessment();
    }

    return this.assessmentCareerMatchesByAssessment;
  }

  // careers in range 1 cannot be used in combined careers
  private isCareerInCareerRange1(careerIri: string): boolean {
    const maxIdCareerRange1 = 999;
    const careerId = ReportEngine.getIdFromIri(careerIri);

    return parseInt(careerId, 10) <= maxIdCareerRange1;
  }

  protected async getCareerMatchesByAssessmentType(
    reportAssessmentIri?: string,
    reportAssessmentTypeIri?: string,
  ): Promise<Map<string, Array<Api.CareerMatchFullRead>>> {
    const careerMatchesByAssessmentType: Map<string, Array<Api.CareerMatchFullRead>> = new Map();
    const assessmentCareerMatchesByAssessment = await this.getAssessmentCareerMatchesByAssessment();
    const assessmentMinimumReads = await this.getAssessmentMinimumReads();

    [
      AssessmentTypeIri.PERSONALITY_ASSESSMENT_TYPE_IRI,
      AssessmentTypeIri.SKILLS_ASSESSMENT_TYPE_IRI,
      AssessmentTypeIri.INTELLIGENCES_ASSESSMENT_TYPE_IRI,
    ].forEach((assessmentTypeIri: string) => {
      const latestCompletedAssessment = ReportEngine.getLatestCompletedAssessment(
        assessmentTypeIri,
        assessmentMinimumReads,
      );

      if (latestCompletedAssessment) {
        const assessmentCareerMatches = assessmentCareerMatchesByAssessment.get(
          latestCompletedAssessment.id,
        );

        if (assessmentCareerMatches && assessmentCareerMatches.careerMatches.length > 0 && !this.isCareerInCareerRange1(assessmentCareerMatches.careerMatches[0].career)) {
          careerMatchesByAssessmentType.set(
            assessmentTypeIri,
            assessmentCareerMatches.careerMatches,
          );
        }
      }
    });

    if (reportAssessmentIri && reportAssessmentTypeIri) {
      const assessmentCareerMatches = assessmentCareerMatchesByAssessment.get(reportAssessmentIri);

      if (assessmentCareerMatches && assessmentCareerMatches.careerMatches.length > 0 && !this.isCareerInCareerRange1(assessmentCareerMatches.careerMatches[0].career)) {
        careerMatchesByAssessmentType.set(
          reportAssessmentTypeIri,
          assessmentCareerMatches.careerMatches,
        );
      }
    }

    return careerMatchesByAssessmentType;
  }

  public getAssessmentId(): string {
    return ReportClient.getRawId(this.achieveWorksReport.assessment);
  }

  public async getCombinedCareersForReport(): Promise<Array<Api.CareerMinimumRead> | undefined> {
    return this.getCombinedCareers(this.getAssessmentId(), this.assessmentTypeIri);
  }

  public async getCombinedCareers(
    assessmentId?: string,
    assessmentTypeIri?: string,
  ): Promise<Array<Api.CareerMinimumRead> | undefined> {
    if (this.combinedCareers.length === 0) {
      const careerMatchesByAssessmentType = await this.getCareerMatchesByAssessmentType(
        assessmentId,
        assessmentTypeIri,
      );

      if (careerMatchesByAssessmentType.size === 1) {
        return;
      }

      const allCareers = await this.getAllCareers();
      const careerScores: Map<string, number> = new Map();

      careerMatchesByAssessmentType.forEach((careerMatches) => {
        careerMatches.forEach((careerMatch: Api.CareerMatchFullRead) => {
          const careerScore = careerScores.get(careerMatch.career);
          const newCareerScore = careerScore
            ? careerScore + careerMatch.matchScore
            : careerMatch.matchScore;

          careerScores.set(careerMatch.career, newCareerScore);
        });
      });

      const orderedCareerScores = [...careerScores].sort((a, b) => b[1] - a[1]).slice(0, 25);

      this.combinedCareers = orderedCareerScores.map((careerScore) => {
        if (careerScore && careerScore[0]) {
          const careerFullReadFound = allCareers.find((careerMinimumRead: Api.CareerMinimumRead) =>
          `/api/careers/${careerMinimumRead.id}` === careerScore[0] ? careerMinimumRead : false,
          );

          if (careerFullReadFound) {
            return careerFullReadFound;
          }

          throw new Error(`no CareerFullRead found for the CareerMatch ${careerScore[0]}`);
        }

        throw new Error('CareerMatch has no value');
      });
    }

    // eslint-disable-next-line consistent-return
    return this.combinedCareers;
  }

  public async getCompareToMeScores(): Promise<CompareToMeScores | undefined> {
    if (this.compareToMeScores) {
      return this.compareToMeScores;
    }

    if (this.reportCareers.length > 0) {
      this.compareToMeScores = { intelligencesScores: undefined, skillsScores: undefined };
      const assessmentMinimumReads = await this.getAssessmentMinimumReads();

      if (
        this.achieveWorksReport.assessment &&
        this.assessmentTypeIri &&
        [
          AssessmentTypeIri.INTELLIGENCES_ASSESSMENT_TYPE_IRI,
          AssessmentTypeIri.SKILLS_ASSESSMENT_TYPE_IRI,
        ].includes(this.assessmentTypeIri)
      ) {
        this.setCompareToMeScoresForReportAssessment();
      }

      if (!this.compareToMeScores.intelligencesScores) {
        this.setCompareToMeScoresForLatestAssessment(
          AssessmentTypeIri.INTELLIGENCES_ASSESSMENT_TYPE_IRI,
          assessmentMinimumReads,
        );
      }

      if (!this.compareToMeScores.skillsScores) {
        this.setCompareToMeScoresForLatestAssessment(
          AssessmentTypeIri.SKILLS_ASSESSMENT_TYPE_IRI,
          assessmentMinimumReads,
        );
      }

      return this.compareToMeScores;
    }
    return this.compareToMeScores;
  }

  public getToken(): string {
    return this.reportClient.getToken();
  }

  public static getIdFromIri(fullId: string): string {
    const id = fullId.split('/').pop();

    if (id !== undefined) {
      return id;
    }

    throw new Error('Invalid Id');
  }

  public getUser(): Api.UserFullRead {
    return this.achieveWorksReport.user;
  }

  public getProductConfiguration(): Api.ProductConfigurationFullRead {
    return this.achieveWorksReport.productConfiguration;
  }

  // eslint-disable-next-line class-methods-use-this
  public getStatus(): string {
    return this.status;
  }

  public async updateAssessmentRatingQuestion(ratingId: string): Promise<void> {
    await this.mutex.use(async () => {
      const { assessmentRating } = this.getReport();

      if (assessmentRating) {
        const result = await this.assessmentClient.updateAssessmentRatingQuestion(
          ReportEngine.getIdFromIri(this.getReport().assessment),
          ratingId,
          assessmentRating.id,
        );

        this.getReport().assessmentRating = result;
        this.initializeRatingAnswers();
      } else {
        throw new Error('Assessment Rating answer missing');
      }
    });
  }

  protected async setCompareToMeScoresForReportAssessment(): Promise<void> {
    const assessmentMinimumRead: Api.AssessmentMinimumRead | undefined = find(
      await this.getAssessmentMinimumReads(),
      (assessmentMinimumReadItem: Api.AssessmentMinimumRead) =>
        assessmentMinimumReadItem
          ? `api/assessments/${assessmentMinimumReadItem.id}` === this.achieveWorksReport.assessment
          : false,
    );

    if (assessmentMinimumRead && this.compareToMeScores) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.assessmentTypeIri === AssessmentTypeIri.INTELLIGENCES_ASSESSMENT_TYPE_IRI
        ? (this.compareToMeScores.intelligencesScores = assessmentMinimumRead.scores)
        : (this.compareToMeScores.skillsScores = assessmentMinimumRead.macroScores);
    }
  }

  protected static getLatestCompletedAssessment(
    assessmentTypeIri: string,
    assessmentMinimumReads: Api.AssessmentMinimumRead[],
  ): Api.AssessmentMinimumRead | undefined {
    return assessmentMinimumReads
      ? // eslint-disable-next-line consistent-return
        maxBy(assessmentMinimumReads, (assessmentMinimumRead): string | undefined => {
          if (
            assessmentMinimumRead.completedAt !== null &&
            assessmentTypeIri === assessmentMinimumRead.assessmentType
          ) {
            return assessmentMinimumRead.completedAt;
          }
        })
      : undefined;
  }

  protected setCompareToMeScoresForLatestAssessment(
    assessmentTypeIri: string,
    assessmentMinimumReads: Api.AssessmentMinimumRead[],
  ): void {
    const assessmentMinimumReadSpecificAssessmentType = ReportEngine.getLatestCompletedAssessment(
      assessmentTypeIri,
      assessmentMinimumReads,
    );

    if (assessmentMinimumReadSpecificAssessmentType && this.compareToMeScores) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      assessmentTypeIri === AssessmentTypeIri.INTELLIGENCES_ASSESSMENT_TYPE_IRI
        ? (this.compareToMeScores.intelligencesScores =
            assessmentMinimumReadSpecificAssessmentType.scores)
        : (this.compareToMeScores.skillsScores =
            assessmentMinimumReadSpecificAssessmentType.macroScores);
    }
  }
}
