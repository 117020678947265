import * as Api from '../lib/typescript-axios';
import * as Errors from '../lib/errors';

import { AssessmentClient } from '../lib/assessment-client';
import { AssessmentTypeIri } from './assessment-type-iri';
import { HesMutex } from '../lib/mutex';
import { ReportEngine } from './report-engine';

export class AssessmentEngine {
  public static readonly STATE_ANSWER_QUESTIONS = 'AnswerQuestions';

  public static readonly STATE_CALCULATE_ASSESSMENT = 'CalculateAssessment';

  public static readonly STATE_REPORT = 'Report';

  protected mutex = new HesMutex();

  /**
   *  userId
   *
   * @type string
   * @memberof AssessmentEngine
   */
  protected userId: string;

  /**
   *  Client object
   *
   * @type Client
   * @memberof AssessmentEngine
   */
  protected assessmentClient: AssessmentClient;

  /**
   *  nextQuestionIndex
   *
   * @type number
   * @memberof AssessmentEngine
   */
  protected questionIndex: number;

  /**
   *  currentQuestions
   *
   * @type Array<Api.QuestionFullRead>
   * @memberof AssessmentEngine
   */
  protected currentQuestions: Array<Api.QuestionFullRead>;

  /**
   *  currentUserAnswers
   *
   * @type Array<Api.UserAnswerFullRead>
   * @memberof AssessmentEngine
   */
  protected currentUserAnswers: Map<string, Api.UserAnswerFullRead> = new Map();

  /**
   *  assessment
   *
   * @type Api.AssessmentFullRead
   * @memberof AssessmentEngine
   */
  protected assessment: Api.AssessmentFullRead;

  /**
   *  hasCareers
   *
   * @type boolean
   * @memberof AssessmentEngine
   */
  protected hasCareers: boolean;

  /**
   *  hasBadges
   *
   * @type boolean
   * @memberof AssessmentEngine
   */
  protected hasBadges: boolean;

  public constructor(
    userId: string,
    client: AssessmentClient,
    assessment: Api.AssessmentFullRead,
    hasCareers: boolean,
    hasBadges: boolean,
  ) {
    this.userId = userId;
    this.assessmentClient = client;
    this.assessment = assessment;
    this.currentQuestions = this.initializeCurrentQuestions();
    this.currentUserAnswers = this.initializeCurrentUserAnswers();
    this.questionIndex = this.calculateQuestionIndex();
    this.hasBadges = hasBadges;

    this.hasCareers =
      hasCareers &&
      AssessmentTypeIri.ASSESSMENTS_TYPES_WITH_CAREERS.includes(
        this.assessment.productConfiguration.assessmentType,
      );
  }

  protected initializeCurrentQuestions(): Array<Api.QuestionFullRead> {
    return this.assessment.questions;
  }

  protected static getUserAnswerMap(
    userAnswers: Array<Api.UserAnswerFullRead>,
  ): Map<string, Api.UserAnswerFullRead> {
    const currentUserAnswers = new Map();

    userAnswers.forEach((userAnswer) => {
      currentUserAnswers.set(userAnswer.answer.question.id, userAnswer);
    });

    return currentUserAnswers;
  }

  protected initializeCurrentUserAnswers(): Map<string, Api.UserAnswerFullRead> {
    return AssessmentEngine.getUserAnswerMap(this.assessment.userAnswers);
  }

  protected calculateQuestionIndex(): number {
    return this.currentUserAnswers.size - 1;
  }

  public getQuestionIndex(): number {
    return this.questionIndex;
  }

  public getLocale(): string {
    return this.assessmentClient.getLocale();
  }

  public changeLanguage($language: string): Promise<string> {
    this.assessmentClient.setLocale($language);
    return this.reInitialize(true);
  }

  protected initializeAssessmentState(): string {
    return this.assessment.status;
  }

  protected async initialize(keepCurrentQuestionIndex = false): Promise<void> {
    this.currentQuestions = this.initializeCurrentQuestions();
    this.currentUserAnswers = this.initializeCurrentUserAnswers();

    /* the purpose of keepCurrentQuestionIndex is to keep you on the same question
      when you are reloading due to a language change. Otherwise you would be shown the first
      unanswered question */
    if (keepCurrentQuestionIndex && this.questionIndex !== -1) {
      this.questionIndex -= 1;
    } else {
      this.questionIndex = this.calculateQuestionIndex();
    }
  }

  public async reInitialize(keepCurrentQuestionIndex = false): Promise<string> {
    this.mutex = new HesMutex();
    this.assessment = await this.assessmentClient.getAssessment(this.assessment.id);
    await this.initialize(keepCurrentQuestionIndex);

    return this.assessment.status;
  }

  public getUser(): Api.UserFullRead {
    return this.assessment.user;
  }

  public getCompletedAt(): Date | null {
    const { completedAt } = this.assessment;

    if (completedAt) {
      return new Date(completedAt);
    }

    return null;
  }

  public getAssessment(): Api.AssessmentFullRead {
    return this.assessment;
  }

  public getProductConfiguration(): Api.ProductConfigurationFullRead {
    return this.assessment.productConfiguration;
  }

  public getStatus(): string {
    return this.assessment.status;
  }

  public async answerQuestion(questionId: string, answerId: string): Promise<string> {
    return this.mutex.use(async () => {
      if (this.canAnswerQuestion()) {
        const question = this.currentQuestions[this.questionIndex];
        const questionAnswer = question.answers.find((answer) => answer.id === answerId);
        let userAnswer = this.currentUserAnswers.get(questionId);

        if (question.id === questionId && questionAnswer) {
          if (userAnswer) {
            this.currentUserAnswers.set(
              questionId,
              await this.assessmentClient.updateQuestionAnswer(
                this.assessment.id,
                answerId,
                userAnswer.id,
              ),
            );
          } else {
            userAnswer = await this.assessmentClient.answerQuestion(this.assessment.id, answerId);

            if (this.getStatus() === AssessmentEngine.STATE_ANSWER_QUESTIONS) {
              this.assessment.userAnswers.push(userAnswer);
            } else {
              this.assessment.tiebreakerUserAnswers.push(userAnswer);
            }

            this.currentUserAnswers.set(questionId, userAnswer);
          }

          if (!this.hasNextQuestion()) {
            this.setNextState();
          }

          return this.assessment.status;
        }

        const errorMessage =
          question.id !== questionId
            ? `questionId ${questionId} does not match ${question.id} at questionIndex ${this.questionIndex}`
            : `invalid answerId: ${answerId} for question ${question.id} (questionId ${questionId} sent) at questionIndex ${this.questionIndex}`;

        throw new Errors.AssessmentInvalidStateError(errorMessage);
      }

      throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
    });
  }

  // is there another question to answer
  public hasNextQuestion(): boolean {
    return this.canAnswerQuestion() && this.questionIndex + 1 <= this.currentQuestions.length - 1;
  }

  // has the user already answered the next question
  public isNextQuestionAvailable(): boolean {
    return (
      this.hasNextQuestion() &&
      this.currentUserAnswers.has(this.currentQuestions[this.questionIndex].id)
    );
  }

  public getPercentComplete(): number {
    return (this.currentUserAnswers.size / this.currentQuestions.length) * 100;
  }

  public getNextQuestion(): Api.QuestionFullRead {
    if (!this.canAnswerQuestion()) {
      throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
    }

    this.questionIndex += 1;

    return this.currentQuestions[this.questionIndex];
  }

  public getUserAnswer(questionId: string): Api.UserAnswerFullRead | undefined {
    return this.currentUserAnswers.get(questionId);
  }

  public hasPreviousQuestion(): boolean {
    return this.canAnswerQuestion() && this.questionIndex !== 0;
  }

  public getPreviousQuestion(): Api.QuestionFullRead {
    if (this.canAnswerQuestion()) {
      this.questionIndex = this.questionIndex === 0 ? 0 : this.questionIndex - 1;

      return this.currentQuestions[this.questionIndex];
    }

    throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
  }

  public canAnswerQuestion(): boolean {
    return this.assessment.status === AssessmentEngine.STATE_ANSWER_QUESTIONS;
  }

  public getUserAnswers(): Map<string, Api.UserAnswerFullRead> {
    return this.currentUserAnswers;
  }

  public async calculateAssessment(): Promise<string> {
    return this.mutex.use(async () => {
      if (this.assessment.status === AssessmentEngine.STATE_CALCULATE_ASSESSMENT) {
        this.assessment = await this.assessmentClient.calculateAssessment(this.assessment.id);
        this.currentQuestions = this.initializeCurrentQuestions();
        this.currentUserAnswers = this.initializeCurrentUserAnswers();
        this.questionIndex = this.calculateQuestionIndex();

        return this.assessment.status;
      }
    
      throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
    });
  }

  public async getReportEngine(enableReportBlocking = false): Promise<ReportEngine> {
    if (
      this.assessment.status === AssessmentEngine.STATE_REPORT &&
      this.assessment.achieveWorksReport
    ) {
      const achieveWorksReportId = AssessmentClient.getRawId(this.assessment.achieveWorksReport);

      if (achieveWorksReportId !== undefined) {
        return ReportEngine.getReportEngine(
          this.assessmentClient.getBasePath(),
          this.userId,
          this.assessmentClient.getToken(),
          achieveWorksReportId,
          this.hasCareers,
          this.hasBadges,
          enableReportBlocking,
          this.assessment.isResultReviewed,
          this.assessmentClient.getLocale(),
        );
      }
    }

    throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
  }

  public getAssessmentId(): string {
    return this.assessment.id;
  }

  public getIntroductionText(): string {
    return this.assessment.introductionText;
  }

  public getProductId(): string {
    return AssessmentClient.getRawId(this.assessment.productConfiguration.productId);
  }

  public setNextState(): string {
    switch (this.assessment.status) {
      case AssessmentEngine.STATE_ANSWER_QUESTIONS: {
        if (!this.hasNextQuestion()) {
          this.assessment.status = AssessmentEngine.STATE_CALCULATE_ASSESSMENT;
        }
        break;
      }
      case AssessmentEngine.STATE_CALCULATE_ASSESSMENT: {
        this.assessment.status = AssessmentEngine.STATE_REPORT;
        break;
      }
      default: {
        throw new Errors.AssessmentInvalidStateError(`invalid state: ${this.assessment.status}`);
      }
    }
    return this.assessment.status;
  }
}
