/* eslint-disable no-empty-function, no-param-reassign */
import * as t from 'io-ts';
import { either } from 'fp-ts/lib/Either';
import * as ApiClient from './lib/sdk';
import type { CareerUserRatingData, CareerLinkData } from './use/useCareerProfileDialog';
import { defaultLocales, defaultLanguage, availableLanguages } from './i18n/Languages';
import { validCareerRatingRangeValues, isoCountryCodes, stateAbbreviations, timezones } from './ConfigurationValidation';
import { ConfigurationValidationError } from './errors/configuration-validation-error';

export type CallbackFunction = (data: any) => void;

export interface CareerRating{
  onetId: string,
  rating: number,
}

export interface CareerSection {
  displayAllCareers?: boolean;
  displayCombinedCareers?: boolean;
  careerRatingRange?: number;
  careerRatings?: Array<CareerRating>,
  careerRatingIcon?:
  | 'star'
  | 'heart'
  ;
  careerProfiles?: {
    displayLink?: boolean
    displayCareerProfileOnClick?: boolean
    onCareerLinkClick?: (careerLinkData: CareerLinkData) => void;
  };
  onCareerRatingChange?: (careerUserRating: CareerUserRatingData) => void;
}

export interface ValidatedCareerSection{
  displayAllCareers: boolean;
  displayCombinedCareers: boolean;
  careerRatingRange: number;
  careerRatings?: Array<CareerRating>,
  careerRatingIcon:
  | 'star'
  | 'heart'
  ;
  careerProfiles: {
    displayLink: boolean
    displayCareerProfileOnClick: boolean
    onCareerLinkClick: (careerLinkData: CareerLinkData) => void;
  };
  onCareerRatingChange: (careerUserRating: CareerUserRatingData) => void;
}

export interface ICustomColors {
  primary?: string,
  success?: string,
  error?: string,
  warning?: string,
  text?: string,
  ratings?: string,
  brand1?: string,
  brand2?: string,
  brand3?: string,
  brand4?: string,
  brand5?: string,
}

export interface Configuration {
    userId: string;
    apiToken: string;
    apiEnvironment:
    | 'sg_production'
    | 'eu_production'
    | 'production'
    | 'pre_production'
    | 'staging'
    | 'test'
    | 'local'
    | 'localvue'
    | 'preview'
    | 'ci'
    ;
    configurationId: string;
    assessmentId?: string;
    locale?: string;
    locales?: Array<string>;
    displayCareers?: boolean;
    displayWages?: boolean;
    displayBadging?: boolean;
    enableReportBlocking?: boolean;
    displayActivities?: boolean;
    customCssUrl?: string;
    displayJobLinks?: boolean;
    indeedSubDomain?: string;
    isoCountryCode?: string;
    stateAbbreviation?: string
    timezone?: string;
    readOnly?: boolean;
    clientBaseUrl: string
    transmogrifierUrl?: string
    applicationDivId?: string
    enableCoverPageOption?: boolean;
    enableDataDog?: boolean;
    careerSection?: CareerSection;
    customColors?: ICustomColors;
    onAssessmentStart?: (assessment: ApiClient.AssessmentFullRead) => void;
    onAssessmentComplete?: (assessment: ApiClient.AssessmentFullRead) => void;
    onAnswerQuestion?: (userQuestionAnswer: ApiClient.UserAnswerFullRead) => void;
}

// duplicate interface with no attributes optional except those that can be undefined
// this allows us to not test for undefined for non-optional attributes when using them
export interface ValidatedConfiguration {
  userId: string;
  apiToken: string;
  apiEnvironment:
  | 'sg_production'
  | 'eu_production'
  | 'production'
  | 'pre_production'
  | 'staging'
  | 'test'
  | 'local'
  | 'localvue'
  | 'preview'
  | 'ci'
  ;
  configurationId: string;
  locale: string;
  locales: Array<string>;
  displayCareers: boolean;
  displayWages: boolean;
  displayBadging: boolean;
  enableReportBlocking: boolean;
  displayActivities: boolean;
  displayJobLinks: boolean;
  isoCountryCode: string;
  timezone: string;
  readOnly: boolean;
  clientBaseUrl: string
  transmogrifierUrl: string
  applicationDivId: string
  enableCoverPageOption: boolean;
  enableDataDog: boolean;
  careerSection: ValidatedCareerSection
  onAssessmentStart: (assessment: ApiClient.AssessmentFullRead) => void;
  onAssessmentComplete: (assessment: ApiClient.AssessmentFullRead) => void;
  onAnswerQuestion: (userQuestionAnswer: ApiClient.UserAnswerFullRead) => void;
  assessmentId?: string;
  customCssUrl?: string;
  indeedSubDomain?: string;
  stateAbbreviation?: string;
  customColors?: ICustomColors;
}

export const
  // validates theConfiguration value is a locale that can create a Language object
  LanguageFromString = new t.Type<string, string, unknown>(
    'LanguageFromString',
    (u): u is string => typeof u === 'string',
    (u, c) => either.chain(t.string.validate(u, c), s => {
      const isValidLanguage = availableLanguages.some(language => language.value === s);

      return isValidLanguage ? t.success(s) : t.failure(u, c);
    }),
    t.identity,
  ),
  CareerRatingRange = new t.Type<number, number, unknown>(
    'CareerRatingRange',
    (u: unknown): u is number => typeof u === 'number' && validCareerRatingRangeValues.includes(u),
    (input, context) => {
      if (typeof input === 'number' && validCareerRatingRangeValues.includes(input)) {
        return t.success(input);
      }

      return t.failure(input, context);
    },
    t.identity,
  ),

  CareerRatingValidator = new t.Type<CareerRating, object, unknown>(
    'CareerRatingValidator',
    (u: unknown): u is CareerRating => {
      const careerRating = u as CareerRating;

      return (
        careerRating !== null &&
        Object.prototype.hasOwnProperty.call(careerRating, 'onetId') &&
        typeof careerRating.onetId === 'string' &&
        Object.prototype.hasOwnProperty.call(careerRating, 'rating') &&
        validCareerRatingRangeValues.includes(careerRating.rating));
    },
    (input, context) => {
      const careerRating = input as CareerRating;

      if (
        careerRating !== null &&
        Object.prototype.hasOwnProperty.call(careerRating, 'onetId') &&
        typeof careerRating.onetId === 'string' &&
        Object.prototype.hasOwnProperty.call(careerRating, 'rating') &&
        validCareerRatingRangeValues.includes(careerRating.rating)
      ) {
        return t.success(careerRating);
      }

      return t.failure(input, context);
    },
    t.identity,
  ),


  // validates that the value is a string and in the array of country codes
  IsoCountryCode = new t.Type<string, string, unknown>(
    'IsoCountryCode',
    (u: unknown): u is string => typeof u === 'string' && isoCountryCodes.includes(u),
    (input, context) => {
      if (typeof input === 'string' && isoCountryCodes.includes(input)) {
        return t.success(input);
      }

      return t.failure(input, context);
    },
    t.identity,
  ),
  // validates that the value is a string and is in the array of 2 letter state/province abbrev
  StateAbbreviation = new t.Type<string, string, unknown>(
    'StateAbbreviation',
    (u: unknown): u is string => typeof u === 'string' && stateAbbreviations.includes(u),
    (input, context) => {
      if (typeof input === 'string' && stateAbbreviations.includes(input)) {
        return t.success(input);
      }

      return t.failure(input, context);
    },

    t.identity,
  ),
  Timezone = new t.Type<string, string, unknown>(
    'Timezone',
    (u: unknown): u is string => typeof u === 'string' && timezones.includes(u),
    (input, context) => {
      if (typeof input === 'string' && timezones.includes(input)) {
        return t.success(input);
      }

      return t.failure(input, context);
    },

    t.identity,
  ),
  CallbackFunctionValidator = new t.Type<CallbackFunction, CallbackFunction, unknown>(
    'AssessmentCallbackFunctionValidator',
    (u: unknown): u is CallbackFunction => {
      const fn = (data: ApiClient.UserAnswerFullRead): void => { };

      return typeof u === typeof fn;
    },
    (input, context) => {
      const fn = (data: ApiClient.UserAnswerFullRead): void => { };

      if (typeof input === typeof fn) {
        return t.success(Object as () => CallbackFunction);
      }

      return t.failure(input, context);
    },
    t.identity
  ),
  ConfigurationRequiredValidator = t.type({
    userId: t.string,
    apiToken: t.string,
    clientBaseUrl: t.string,
    apiEnvironment: t.union([
      t.literal('sg_production'),
      t.literal('eu_production'),
      t.literal('production'),
      t.literal('pre_production'),
      t.literal('staging'),
      t.literal('test'),
      t.literal('local'),
      t.literal('localvue'),
      t.literal('preview'),
      t.literal('ci')
    ]),
    configurationId: t.string
  }),
  CareerProfilesOptionalValidator = t.partial({
    displayLink: t.boolean,
    displayCareerProfileOnClick: t.boolean,
    onCareerLinkClick: CallbackFunctionValidator
  }),
  CareerSectionOptionalValidator = t.partial({
    displayAllCareers: t.boolean,
    displayCombinedCareers: t.boolean,
    careerRatingRange: CareerRatingRange,
    careerRatings: t.array(CareerRatingValidator),
    careerRatingIcon: t.union([
      t.literal('star'),
      t.literal('heart')
    ]),
    careerProfiles: CareerProfilesOptionalValidator,
    onCareerRatingChange: CallbackFunctionValidator,
  }),
  ConfigurationOptionalValidator = t.partial({
    assessmentId: t.string,
    locale: LanguageFromString,
    locales: t.array(LanguageFromString),
    displayCareers: t.boolean,
    displayWages: t.boolean,
    displayBadging: t.boolean,
    displayJobLinks: t.boolean,
    isoCountryCode: IsoCountryCode,
    stateAbbreviation: StateAbbreviation,
    enableReportBlocking: t.boolean,
    displayActivities: t.boolean,
    customCssUrl: t.string,
    indeedSubDomain: t.string,
    timezone: Timezone,
    readOnly: t.boolean,
    transmogrifierUrl: t.string,
    applicationDivId: t.string,
    onAssessmentStart: CallbackFunctionValidator,
    onAssessmentComplete: CallbackFunctionValidator,
    onAnswerQuestion: CallbackFunctionValidator,
    enableCoverPageOption: t.boolean,
    enableDataDog: t.boolean,
    careerSection: CareerSectionOptionalValidator
  }),
  ConfigurationValidator = t.intersection([
    ConfigurationRequiredValidator,
    ConfigurationOptionalValidator,
  ], 'ConfigurationValidator'),
  throwIfError = (result: t.Validation<Configuration>) => {
    // eslint-disable-next-line no-underscore-dangle
    if (result._tag === 'Left') {
      let message = 'Configuration Invalid\n';

      result.left.forEach(invalid => {
        message += `invalid value for ${invalid.context[2].key}: ${invalid.context[2].actual}\n`;
      });

      throw new ConfigurationValidationError(message);
    }
  },
  getValue = (value: boolean | string | undefined, defaultValue: boolean | string): boolean | string => {
    if (typeof value !== 'undefined' && value !== null) {
      return value;
    }

    return defaultValue;
  },
  getDefaultedCareerSection = (unvalidatedCareerSection?: CareerSection): ValidatedCareerSection => {
    const careerSection = { ...unvalidatedCareerSection } as ValidatedCareerSection;

    careerSection.displayAllCareers = getValue(careerSection.displayAllCareers, true) as boolean;
    careerSection.displayCombinedCareers = getValue(careerSection.displayCombinedCareers, true) as boolean;
    careerSection.careerRatingRange = isNaN(careerSection.careerRatingRange) ? 3 : careerSection.careerRatingRange;
    careerSection.careerRatingIcon = careerSection.careerRatingIcon ? careerSection.careerRatingIcon : 'star';
    careerSection.onCareerRatingChange = careerSection.onCareerRatingChange
      ? careerSection.onCareerRatingChange : (careerUserRatingData: CareerUserRatingData): void => { };

    const careerProfiles = careerSection.careerProfiles && typeof careerSection.careerProfiles === 'object'
      ? careerSection.careerProfiles
      : {
        displayLink: true,
        displayCareerProfileOnClick: true,
        onCareerLinkClick: (careerLinkData: CareerLinkData): void => { }
      };

    careerProfiles.displayLink = getValue(careerProfiles.displayLink, true) as boolean;
    careerProfiles.displayCareerProfileOnClick = getValue(careerProfiles.displayCareerProfileOnClick, true) as boolean;
    careerProfiles.onCareerLinkClick = careerProfiles.onCareerLinkClick
      ? careerProfiles.onCareerLinkClick : (careerLinkData: CareerLinkData): void => { };
    careerSection.careerProfiles = careerProfiles;


    return careerSection;
  },
  setupConfiguration = (unvalidatedconfiguration: Configuration): ValidatedConfiguration => {
    // validate the configuration
    const result: t.Validation<Configuration> = ConfigurationValidator.decode(unvalidatedconfiguration);
    // eslint-disable-next-line new-cap
    const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';

    let environment = '';

    switch (unvalidatedconfiguration.apiEnvironment) {
    case 'sg_production':
      environment = '.sg';
      break;
    case 'eu_production':
      environment = '.eu';
      break;
    case 'production':
      environment = '';
      break;
    case 'local':
    case 'localvue':
      environment = '-dev.staging';
      break;
      case 'preview':
        environment = '-dev.staging';
        break;      
    default:
      environment = '.staging';
      break;
    }

    const transmogrifierUrlByEnvironment = `https://transmogrifier${environment}.humanesources.com/v1/pdfs`;

    throwIfError(result);

    unvalidatedconfiguration.locale = getValue(unvalidatedconfiguration.locale, defaultLanguage.value) as string;
    unvalidatedconfiguration.locales = unvalidatedconfiguration.locales ? unvalidatedconfiguration.locales : defaultLocales;
    unvalidatedconfiguration.isoCountryCode = getValue(unvalidatedconfiguration.isoCountryCode, 'US') as string;
    unvalidatedconfiguration.displayCareers = getValue(unvalidatedconfiguration.displayCareers, true) as boolean;
    unvalidatedconfiguration.displayWages = getValue(unvalidatedconfiguration.displayWages, true) as boolean;
    unvalidatedconfiguration.displayBadging = getValue(unvalidatedconfiguration.displayBadging, false) as boolean;
    unvalidatedconfiguration.displayJobLinks = getValue(unvalidatedconfiguration.displayJobLinks, true) as boolean;
    unvalidatedconfiguration.enableReportBlocking = getValue(unvalidatedconfiguration.enableReportBlocking, false) as boolean;
    unvalidatedconfiguration.displayActivities = getValue(unvalidatedconfiguration.displayActivities, true) as boolean;
    unvalidatedconfiguration.timezone = getValue(unvalidatedconfiguration.timezone, defaultTimezone) as string;
    unvalidatedconfiguration.readOnly = getValue(unvalidatedconfiguration.readOnly, false) as boolean;
    unvalidatedconfiguration.enableCoverPageOption = getValue(unvalidatedconfiguration.enableCoverPageOption, false) as boolean;
    unvalidatedconfiguration.enableDataDog = getValue(unvalidatedconfiguration.enableDataDog, true) as boolean;
    unvalidatedconfiguration.transmogrifierUrl = unvalidatedconfiguration.transmogrifierUrl ? unvalidatedconfiguration.transmogrifierUrl : transmogrifierUrlByEnvironment;
    unvalidatedconfiguration.applicationDivId = unvalidatedconfiguration.applicationDivId ? unvalidatedconfiguration.applicationDivId : 'app';
    unvalidatedconfiguration.onAssessmentStart = unvalidatedconfiguration.onAssessmentStart ? unvalidatedconfiguration.onAssessmentStart : (data: ApiClient.AssessmentFullRead): void => { };
    unvalidatedconfiguration.onAssessmentComplete = unvalidatedconfiguration.onAssessmentComplete ? unvalidatedconfiguration.onAssessmentComplete : (data: ApiClient.AssessmentFullRead): void => { };
    unvalidatedconfiguration.onAnswerQuestion = unvalidatedconfiguration.onAnswerQuestion ? unvalidatedconfiguration.onAnswerQuestion : (data: ApiClient.UserAnswerFullRead): void => { };
    unvalidatedconfiguration.careerSection = getDefaultedCareerSection(unvalidatedconfiguration.careerSection);

    const validatedConfiguration = { ...unvalidatedconfiguration } as ValidatedConfiguration;

    return validatedConfiguration;
  };
