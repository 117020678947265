import type { CareerMinimumRead, ReportEngine } from '@/lib/sdk';
import type { CareerRating } from '../ClientConfiguration';
import { CareerRatingEngine } from './career-rating-engine';
import type { CareerUserRatingData } from '../use/useCareerProfileDialog';

export class AlternativeCareerRatingEngine extends CareerRatingEngine {
  protected allCareers: Array<CareerMinimumRead>;

    protected careerRatings: Map<string, CareerUserRatingData> = new Map<string, CareerUserRatingData>();

    public constructor(reportEngine: ReportEngine, allCareers: Array<CareerMinimumRead>, careerRatings: Array<CareerRating>) {
      super(reportEngine);

      this.allCareers = allCareers;

      careerRatings.forEach(careerRating => {
        const careerFullRead = this.allCareers.find(career => career.onetId === careerRating.onetId);

        if (careerFullRead) {
          this.careerRatings.set(careerFullRead.id, this.getCareerUserRatingData(careerRating, careerFullRead.id));
        }
      });
    }

    public deleteCareerUserRating(careerId: string): Promise<void> {
      this.careerRatings.delete(careerId);

      // eslint-disable-next-line no-undefined
      return new Promise(resolve => resolve(undefined));
    }

    public createCareerUserRating(careerId: string, rating: number): Promise<CareerUserRatingData> {
      const career = this.getCareer(careerId);

      const careerUserRatingData = {
        careerId,
        onetId: career.onetId,
        userId: this.reportEngine.getUserId(),
        rating: rating + 1
      };

      this.careerRatings.set(careerId, careerUserRatingData);

      return new Promise(resolve => resolve(careerUserRatingData));
    }

    public getCareerUserRating(careerId: string): CareerUserRatingData | null {
      const careerUserRatingData = this.careerRatings.get(careerId);

      if (careerUserRatingData) {
        return careerUserRatingData;
      }

      return null;
    }

    public getCareerUserRatings(): Map<string, CareerUserRatingData> {
      return this.careerRatings;
    }

    private getCareer(careerId: string): CareerMinimumRead {
      const career = this.allCareers.find(careerItem => careerItem.id === careerId);

      if (career) {
        return career;
      }

      throw new Error(`careerId: ${careerId} does not exist`);
    }

    private getCareerUserRatingData(careerRating: CareerRating, careerId: string): CareerUserRatingData {
      return {
        careerId,
        onetId: careerRating.onetId,
        userId: this.reportEngine.getUserId(),
        rating: careerRating.rating
      };
    }
}
