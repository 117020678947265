/* eslint-disable consistent-return */
// TODO:determine why prettier auto-removes return undefined

import * as Api from './typescript-axios';

import { HesMutex } from './mutex';
import { ReportClient } from './report-client';

/**
 * Used to differentiate types of ReportTypeElementBadgeRequirementsFullRead.
 */
export enum BadgeEarnType {
  ATTRIBUTE_COMPLETED = 'element_attribute_completed',
  BADGES_REQUIRED = 'badges_required',
  BADGES_REQUIRED_SHADOW = 'badges_required_shadow',
}

/**
 * Used to differentiate display types.
 */
export enum BadgeDisplayType {
  PARENT_BADGE = 'parent_badge',
  BADGE = 'badge',
}

/**
 * The key for most of the Maps used in BadgeManager
 *
 * Produces a unique id for an Element and a Badge.
 */
export class ElementBadgeKey {
  public static getElementBadgeKey(elementId: string, badgeId: string): string {
    return `${elementId}--${badgeId}`;
  }
}
/**
 * The status of a Badge attached to an Element.
 */
export interface ElementBadge {
  elementId: string;
  badge: Api.AchieveWorksReportBadgeFullRead;
  badgeEarnType: BadgeEarnType;
  badgeDisplayType: BadgeDisplayType;
  isEarned: boolean;
}

export interface ElementBadgeStatus {
  reportElementBadgesFullRead: Api.ReportElementBadgesFullRead | undefined;
  elementBadges: Map<string, ElementBadge>;
}

/**
 * Updates ReportElementBadgesFullRead on the API server and manages the status of  Badges on Elements.
 */
export class BadgeManager {
  static readonly READ_BADGE_ID = '4';

  private reportId: string;

  private reportClient: ReportClient;

  private mutex: HesMutex;

  /**
   * Map of badgeId to badge for all badges.
   */
  private badges: Map<string, Api.AchieveWorksReportBadgeFullRead>;

  /**
   * The main tool for ui to use.
   *
   * Map of ElementBadgeKey to status of a badge for an element.
   */
  private elementBadgeStatuses: Map<string, ElementBadgeStatus> = new Map();

  /**
   * Map of ElementBadgeKey to ReportElementBadgesFullRead.
   */
  // private earnedBadges: Map<string, Api.ReportElementBadgesFullRead> = new Map();

  /**
   * Map of ElementBadgeKey to array of ElementIds that must have the badge of ElementBadgeKey earned
   * before the ElementId of ElementBadgeKey can earn this badge.
   */
  private childElementBadgesRequired: Map<string, Array<string>> = new Map();

  /**
   * Map of ElementBadgeKey to array of ElementIds that must have the badge of ElementBadgeKey earned
   * on ElementId of ElementBadgeKey before they can earn this badge.
   */
  private parentElementBadgesWhoRequire: Map<string, Array<string>> = new Map();

  /**
   * Initializes and returns a BadgeManager.
   */
  public static async getBadgeManager(
    reportTypeId: string,
    reportId: string,
    reportClient: ReportClient,
    mutex: HesMutex,
  ): Promise<BadgeManager> {
    const badgeManagerPromises: Array<
      | Promise<Map<string, Api.AchieveWorksReportBadgeFullRead>>
      | Promise<Map<string, Api.ReportElementBadgesFullRead>>
      | Promise<Array<Api.ReportTypeElementBadgeRequirementsFullRead>>
      | Map<string, Api.AchieveWorksReportBadgeFullRead>
      | Map<string, Api.ReportElementBadgesFullRead>
      | Array<Api.ReportTypeElementBadgeRequirementsFullRead>
    > = [];

    badgeManagerPromises.push(
      reportClient.getBadges(),
      reportClient.getReportElementBadges(reportId),
      reportClient.getBadgeRequirements(reportTypeId),
    );

    const [badges, earnedBadges, reportTypeElementBadgeRequirementsFullReads] = await Promise.all(
      badgeManagerPromises,
    );

    return new BadgeManager(
      reportId,
      badges as Map<string, Api.AchieveWorksReportBadgeFullRead>,
      reportClient,
      reportTypeElementBadgeRequirementsFullReads as Array<Api.ReportTypeElementBadgeRequirementsFullRead>,
      earnedBadges as Map<string, Api.ReportElementBadgesFullRead>,
      mutex,
    );
  }

  private constructor(
    reportId: string,
    badges: Map<string, Api.AchieveWorksReportBadgeFullRead>,
    reportClient: ReportClient,
    reportTypeElementBadgeRequirementsFullReads: Array<Api.ReportTypeElementBadgeRequirementsFullRead>,
    earnedBadges: Map<string, Api.ReportElementBadgesFullRead>,
    mutex: HesMutex,
  ) {
    this.reportId = reportId;
    this.reportClient = reportClient;
    this.badges = badges;
    this.initBadgeMaps(reportTypeElementBadgeRequirementsFullReads, earnedBadges);
    this.mutex = mutex;
  }

  /**
   * Returns the status of all element/badge combinations.
   *
   * Used to set the initial value of all badges in the UI
   * and can be used as live data as it is updated on each badge earned.
   */
  public getElementBadgeStatuses(): Map<string, ElementBadgeStatus> {
    return this.elementBadgeStatuses;
  }

  /**
   * Earns the read badge for the element and possibly the parent badge.
   *
   * Returns the badges earned and updates the elementBadges.
   */
  public async earnReadBadge(elementIdEarned: string): Promise<Array<ElementBadge>> {
    return this.mutex.use(async () => this.earnBadges(elementIdEarned, BadgeManager.READ_BADGE_ID));
  }

  /**
   * Earns the element's activity badge and possibly the parent badge.
   *
   * Returns the badges earned and updates the elementBadges.
   */
  public async earnActivityBadge(elementIdEarned: string): Promise<Array<ElementBadge>> {
    return this.mutex.use(async () => {
      const elementBadgeStatus = this.elementBadgeStatuses.get(elementIdEarned);

      if (elementBadgeStatus) {
        // activities have only one badge
        const elementBadge = elementBadgeStatus.elementBadges.values().next().value;

        const badgesEarned: Array<ElementBadge> = await this.earnBadges(
          elementBadge.elementId,
          elementBadge.badge.id,
        );

        return badgesEarned;
      }

      throw new Error('element has no Activity badge');
    });
  }

  public getElementBadgesRequired(): Map<string, Array<string>> {
    return this.childElementBadgesRequired;
  }

  public getElementBadgesWhoRequire(): Map<string, Array<string>> {
    return this.parentElementBadgesWhoRequire;
  }

  /**
   * Earns the badge for the element and possibly the parent badge.
   *
   * Returns the badges earned and updates the elementBadges.
   */

  private async earnBadges(elementId: string, badgeId: string): Promise<Array<ElementBadge>> {
    const badgesEarned: Array<ElementBadge> = [];

    if (!this.badgeCanBeEarned(elementId, badgeId)) {
      return badgesEarned;
    }

    const badgeEarned: ElementBadge | undefined = await this.updateReportElementBadges(
      elementId,
      badgeId,
    );

    if (badgeEarned) {
      badgesEarned.push(badgeEarned);
    }

    const parentBadgesEarned: Array<ElementBadge> = await this.earnParentBadges(elementId, badgeId);
    badgesEarned.push(...parentBadgesEarned);

    return badgesEarned;
  }

  /**
   * Earns the badge for the parent element(s) that require this element/badge.
   *
   * Returns the badges earned and updates the elementBadges.
   */

  private async earnParentBadges(elementId: string, badgeId: string): Promise<Array<ElementBadge>> {
    const elementBadgeKey = ElementBadgeKey.getElementBadgeKey(elementId, badgeId);
    const parentBadges: Array<ElementBadge> = [];

    const parentElementsWhoRequire = this.parentElementBadgesWhoRequire.get(elementBadgeKey);

    if (parentElementsWhoRequire) {
      const elementBadgePromises: Array<Promise<ElementBadge | undefined>> = [];
      parentElementsWhoRequire.forEach((elementWhoRequires) => {
        elementBadgePromises.push(this.earnParentBadge(elementWhoRequires, badgeId));
      });

      const elementBadges = await Promise.all(elementBadgePromises);

      elementBadges.forEach((elementBadge) => {
        if (elementBadge) {
          parentBadges.push(elementBadge);
        }
      });
    }

    return parentBadges;
  }

  /**
   * Earns the badge for the parent element that require this element/badge
   * if all badges required have been earned.
   *
   * Returns the badge earned or undefined if not earned.
   */

  private async earnParentBadge(
    elementWhoRequires: string,
    badgeId: string,
  ): Promise<ElementBadge | undefined> {
    if (this.badgeCanBeEarned(elementWhoRequires, badgeId)) {
      return this.updateReportElementBadges(elementWhoRequires, badgeId);
    }
  }

  /**
   * Determines if all badges required have been earned.
   */

  private badgeCanBeEarned(elementId: string, badgeId: string): boolean {
    let canBeEarned = false;
    const elementsRequired: Array<string> | undefined = this.childElementBadgesRequired.get(
      ElementBadgeKey.getElementBadgeKey(elementId, badgeId),
    );

    if (elementsRequired && elementsRequired.length > 0) {
      let earnedBadgeCount = 0;
      elementsRequired.forEach((elementIdRequired) => {
        if (
          this.elementBadgeStatuses.get(elementIdRequired)?.elementBadges.get(badgeId)?.isEarned
        ) {
          earnedBadgeCount += 1;
        }
      });

      if (earnedBadgeCount === elementsRequired.length) {
        canBeEarned = true;
      }
    } else {
      canBeEarned = true;
    }

    return canBeEarned;
  }

  /**
   * Updates ReportElementBadges on the API server.
   *
   * Returns the badge earned.
   */

  private async updateReportElementBadges(
    elementId: string,
    badgeId: string,
  ): Promise<ElementBadge | undefined> {
    const elementBadgeKey = ElementBadgeKey.getElementBadgeKey(elementId, badgeId);
    const elementBadgeStatus = this.elementBadgeStatuses.get(elementId);
    let reportElementBadges: Api.ReportElementBadgesFullRead;

    if (this.elementBadgeStatuses.get(elementId)?.elementBadges.get(badgeId)?.isEarned) {
      return;
    }

    if (elementBadgeStatus && elementBadgeStatus.reportElementBadgesFullRead) {
      const badgeIds: Array<string> =
        elementBadgeStatus.reportElementBadgesFullRead.achieveWorksReportBadges.map(
          (badge) => badge.id,
        );

      badgeIds.push(badgeId);

      reportElementBadges = await this.reportClient.updateReportElementBadges(
        elementBadgeStatus.reportElementBadgesFullRead.id,
        this.reportId,
        elementId,
        badgeIds,
      );
    }

    reportElementBadges = await this.reportClient.createReportElementBadges(
      this.reportId,
      elementId,
      [badgeId],
    );

    return this.updateMaps(elementBadgeKey, elementId, badgeId, reportElementBadges);
  }

  /**
   * Updates the status Maps.
   *
   * Returns the ElementBadge earned.
   */

  private updateMaps(
    elementBadgeKey: string,
    elementId: string,
    badgeId: string,
    reportElementBadgesFullRead: Api.ReportElementBadgesFullRead,
  ): ElementBadge {
    const elementBadgeStatus = this.elementBadgeStatuses.get(elementId);

    if (elementBadgeStatus) {
      elementBadgeStatus.reportElementBadgesFullRead = reportElementBadgesFullRead;

      const badgeElement = elementBadgeStatus.elementBadges.get(badgeId);

      if (badgeElement) {
        badgeElement.isEarned = true;

        return badgeElement;
      }

      throw new Error('badgeId does not exist in elementBadges');
    }
    throw new Error('elementId does not exist in elementBadges');
  }

  private initBadgeMaps(
    reportTypeElementBadgeRequirementsFullReads: Array<Api.ReportTypeElementBadgeRequirementsFullRead>,
    earnedBadges: Map<string, Api.ReportElementBadgesFullRead>,
  ): void {
    const elementBadgeStatuses: Map<string, ElementBadgeStatus> = new Map();

    reportTypeElementBadgeRequirementsFullReads.forEach(
      (reportTypeElementBadgeRequirementsFullRead) => {
        const elementIri = reportTypeElementBadgeRequirementsFullRead.achieveWorksReportElement;
        const elementId = ReportClient.getRawId(elementIri);
        const reportElementBadgesFullRead = earnedBadges.get(elementId);
        const badge = this.badges.get(
          ReportClient.getRawId(reportTypeElementBadgeRequirementsFullRead.achieveWorksReportBadge),
        );
        const isEarned = BadgeManager.getIsEarned(
          reportElementBadgesFullRead,
          reportTypeElementBadgeRequirementsFullRead.achieveWorksReportElement,
          reportTypeElementBadgeRequirementsFullRead.achieveWorksReportBadge,
        );

        const badgeEarnType =
          reportTypeElementBadgeRequirementsFullRead.badgeEarnType ===
          BadgeEarnType.ATTRIBUTE_COMPLETED
            ? BadgeEarnType.ATTRIBUTE_COMPLETED
            : BadgeEarnType.BADGES_REQUIRED;

        const badgeDisplayType =
          reportTypeElementBadgeRequirementsFullRead.badgeDisplayType === BadgeDisplayType.BADGE
            ? BadgeDisplayType.BADGE
            : BadgeDisplayType.PARENT_BADGE;

        if (badge) {
          if (badgeEarnType === BadgeEarnType.BADGES_REQUIRED) {
            const elementIds: Array<string> = [];

            reportTypeElementBadgeRequirementsFullRead.achieveWorksReportElementBadgesRequired.forEach(
              (elementRequiredIri): void => {
                const elementIdRequired = ReportClient.getRawId(elementRequiredIri);
                const elementBadgekey: string = ElementBadgeKey.getElementBadgeKey(
                  elementIdRequired,
                  badge.id,
                );

                if (!this.parentElementBadgesWhoRequire.has(elementBadgekey)) {
                  this.parentElementBadgesWhoRequire.set(elementBadgekey, [elementId]);
                } else {
                  this.parentElementBadgesWhoRequire.get(elementBadgekey)?.push(elementId);
                }

                elementIds.push(elementIdRequired);
              },
            );

            this.childElementBadgesRequired.set(
              ElementBadgeKey.getElementBadgeKey(elementId, badge.id),
              elementIds,
            );
          }

          const elementBadge = {
            elementId,
            badge,
            badgeEarnType,
            badgeDisplayType,
            isEarned,
          };

          if (!elementBadgeStatuses.has(elementId)) {
            const elementBadges: Map<string, ElementBadge> = new Map([[badge.id, elementBadge]]);

            elementBadgeStatuses.set(elementId, { reportElementBadgesFullRead, elementBadges });
          } else {
            elementBadgeStatuses.get(elementId)?.elementBadges.set(badge.id, elementBadge);
          }
        }
      },
    );

    this.elementBadgeStatuses = elementBadgeStatuses;
    this.selfHealShadowBadges(reportTypeElementBadgeRequirementsFullReads);
  }

  private async selfHealShadowBadges(
    reportTypeElementBadgeRequirementsFullReads: Array<Api.ReportTypeElementBadgeRequirementsFullRead>,
  ): Promise<void> {
    const elementBadgePromises: Array<Promise<ElementBadge | undefined>> = [];

    reportTypeElementBadgeRequirementsFullReads.forEach((reportTypeElementBadgeRequirements) => {
      const elementId = ReportClient.getRawId(
        reportTypeElementBadgeRequirements.achieveWorksReportElement,
      );
      const badgeId = ReportClient.getRawId(
        reportTypeElementBadgeRequirements.achieveWorksReportBadge,
      );
      const isShadowBadge =
        reportTypeElementBadgeRequirements.badgeEarnType === BadgeEarnType.BADGES_REQUIRED_SHADOW;
      const elementBadge = this.elementBadgeStatuses.get(elementId)?.elementBadges?.get(badgeId);

      if (
        isShadowBadge &&
        elementBadge &&
        !elementBadge.isEarned &&
        this.badgeCanBeEarned(elementId, badgeId)
      ) {
        elementBadgePromises.push(this.earnParentBadge(elementId, badgeId));
      }
    });

    await Promise.all(elementBadgePromises);
  }

  private static getIsEarned(
    reportElementBadgesFullRead: Api.ReportElementBadgesFullRead | undefined,
    elementIri: string,
    badgeIri: string,
  ): boolean {
    if (reportElementBadgesFullRead) {
      const achieveWorksReportBadge = reportElementBadgesFullRead.achieveWorksReportBadges.find(
        (badge) => badge.id === ReportClient.getRawId(badgeIri),
      );

      return !!achieveWorksReportBadge;
    }

    return false;
  }
}
