import { AxiosHeaders, type AxiosResponse } from 'axios';

import * as Api from './typescript-axios';

interface Headers {
  Authorization: string,
  'Accept-Language': string;
}

export type Hydra<T extends {}> = T & { 'hydra:member': Array<T>};

export const getArrayFromHydraObject = <T extends {}>(object: AxiosResponse): Array<T> => {
  const { "hydra:member": members } = object.data as Hydra<T>; 
  
  return members;
}

export class Client {
  /**
   * parameter for basic security
   *
   * @type string
   * @memberof Client
   */
  protected token: string;

  /**
   *  base path of API server
   *
   * @type string
   * @memberof Client
   */
  protected basePath: string;

  /**
   *  API endpoint method options
   *
   * @type {}
   * @memberof Client
   */
  protected options: {
    headers: AxiosHeaders;
    params: {
      id?: string;
      pagination?: boolean;
      'include[]'?: string;
      isTruTalents?: boolean;
    };
  };

  /**
   *  API endpoint configuration
   *
   * @type Api.Configuration
   * @memberof Client
   */
  protected configuration: Api.Configuration;

  protected constructor(basePath: string, token: string, locale?: string) {
    this.token = token;
    this.basePath = basePath;
    const clientLocale = typeof locale === 'undefined' ? 'en-US' : locale;

    this.options = {
      headers: new AxiosHeaders({
        Authorization: `Bearer ${this.token}`,
        'Accept-Language': clientLocale,
      }),
      params: {},
    };

    this.configuration = new Api.Configuration({
      basePath: this.basePath,
    });
  }

  /**
   * getBasePath
   */
  public getBasePath(): string {
    return this.basePath;
  }

  /**
   * getToken
   */
  public getToken(): string {
    return this.token;
  }

  public getLocale(): string {
    return this.options.headers['Accept-Language'];
  }

  public setLocale(locale: string): void {
    this.options.headers['Accept-Language'] = locale;
  }

  public static getRawId(fullId: string): string {
    const rawId = fullId.split('/').pop();

    if (rawId !== undefined) {
      return rawId;
    }

    throw new Error('Invalid Id');
  }
}
