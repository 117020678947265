<script setup lang="ts">
import { onErrorCaptured, onBeforeMount, type Ref, ref, watch, defineAsyncComponent, shallowRef} from 'vue';
import { useLocale } from 'vuetify'
import { useI18n } from 'vue-i18n'
import "@fortawesome/fontawesome-svg-core/styles.css";

import { AssessmentEngine, PersonalityAssessmentEngine, ReportEngine, ReportBlockedError, ServerError }from '@/lib/sdk';
import type { Configuration, ValidatedConfiguration } from '@/ClientConfiguration';
import { deprecatedConfigurationIds } from '@/ConfigurationValidation';
import { useAssessmentInit, useConfigurationInit } from '@/use/useAssessmentInit';
import * as languages from '@/i18n/Languages';
import { AssessmentEngineLogicError } from '@/errors/assessment-engine-logic-error';
import { DeprecatedConfigurationStartError } from '@/errors/deprecated-configuration-start-error';
import { logError } from '@/use/useSharedScripts';
import { IncompleteAssessmentReadOnlyError } from '@/errors/incomplete-assessment-readonly-error';
import { ReportProps } from '@/ReportProps';
import { type EngineType } from '@/use/useTypes';

const props = defineProps<{
  unvalidatedconfiguration: Configuration;
}>();

declare global {
  interface Window {
    dummyHesAssessmentClientConfiguration: Configuration,
    startHesAssessmentClient: (unvalidatedconfiguration: Configuration) => void
    loadHesAssessment: (unvalidatedconfiguration: Configuration) => void
  }
}

const
  { t, locale } = useI18n(),
  { current } = useLocale(),
  assessmentEngine: Ref<AssessmentEngine | PersonalityAssessmentEngine | null> = ref(null),
  reportEngine: Ref<ReportEngine | null> = ref(null),
  reportProps: Ref<ReportProps| null> = ref(null),
  configuration: Ref<ValidatedConfiguration> = ref({}) as Ref<ValidatedConfiguration>,
  page = ref('Loading'),
  nextPage: Ref<string|null> = ref(null),
  component = shallowRef(defineAsyncComponent(() =>import(`@/components/${page.value}.vue`))),
  message = ref(''),
  isReportBlocked = ref(false),
  zero = 0,
  renderCount = ref(zero),
  productSuiteClass: Ref<string | undefined> = ref(''),
  isError = ref(false),
  errorMessage = ref(''),
  errorType = ref(''),
  resetError = (): void => {
    isError.value = false;
    errorMessage.value = '';
    errorType.value = '';
  },

  handleError = (error: unknown): void => {
    if (error instanceof Error) {
      logError(error, props.unvalidatedconfiguration);

      message.value = error.message;

      if (error instanceof ReportBlockedError) {
        message.value = `${t('Your report is currently locked. In order to view your report, you will need to contact the person who instructed you to do the assessment, or another person at your site who can unlock the report. Reviewing the report with a qualified individual, who can answer questions and explain things clearly, will provide the most benefit to you.')}`;
        isReportBlocked.value = true;
      } else if (error instanceof ServerError && error.httpStatusCode === 401) {
        message.value = `${t('Please reload the page and try again')}`;
      } else if (error instanceof IncompleteAssessmentReadOnlyError) {
        message.value = `${t('Assessment incomplete. You cannot view an incomplete assessment.')}`;
      }

      nextPage.value = 'Error';
    }

    throw error;
  },
  setLocale = (newLocale: string): void => {
    if (newLocale && languages.getLanguage(newLocale)) {
      if (newLocale !== current.value) {
        locale.value = newLocale;
        current.value = newLocale;
      }
    } else {
      handleError(new Error('locale does not exist'));
    }
  },
  setReportProperties = async (engine: EngineType) => {
    if (engine instanceof ReportEngine) {
      reportEngine.value = engine;
    } else {
      reportEngine.value = await engine.getReportEngine(configuration.value.enableReportBlocking);
    }

    reportProps.value = await ReportProps.getReportProps(reportEngine.value.getReport().elements[0], reportEngine.value, configuration.value);
    productSuiteClass.value = reportEngine.value.getProductConfiguration().productSuite;
  },
  reloadPage = async () => {
    let locale = assessmentEngine.value === null ? configuration.value.locale : assessmentEngine.value.getLocale();

    if (page.value === 'Report' && reportEngine.value === null) {
      if (assessmentEngine.value === null) {
        handleError(new Error('The assessment engine did not initialize'));
      } else {
        try {
          await setReportProperties(assessmentEngine.value);
        } catch (error) {
          handleError(error);
        }
      }
    }

    locale = reportEngine.value === null ? locale : reportEngine.value.getLocale();
    setLocale(locale);
    renderCount.value += 1;
  },
  displayCurrentState = async (fn: string): Promise<void> => {
    if (fn === 'reloadPage') {
      reloadPage();

      return;
    }

    if (assessmentEngine.value !== null) {
      switch (assessmentEngine.value.getStatus()) {
      case AssessmentEngine.STATE_ANSWER_QUESTIONS: {
        nextPage.value = 'Question';
        break;
      }
      case PersonalityAssessmentEngine.STATE_RATE_CAREER_CLUSTERS: {
        nextPage.value = 'RateCareerClusters';
        break;
      }
      case AssessmentEngine.STATE_CALCULATE_ASSESSMENT: {
        if (assessmentEngine.value !== null) {
          await assessmentEngine.value.calculateAssessment();
        }

        if (assessmentEngine.value instanceof PersonalityAssessmentEngine) {
          nextPage.value = null;
          await displayCurrentState('displayCurrentState');
        } else {
          nextPage.value = 'Loading';
        }

        break;
      }
      case PersonalityAssessmentEngine.STATE_TIE_RESOLVEMENT: {
        nextPage.value = 'Question';
        break;
      }
      case PersonalityAssessmentEngine.STATE_RATE_ASSESSMENT: {
        nextPage.value = 'RateAssessment';
        break;
      }
      case AssessmentEngine.STATE_REPORT: {
        nextPage.value = 'Loading';
        break;
      }
      default: {
        throw new AssessmentEngineLogicError('Invalid state');
      }
      }
    }
  },
  setCSSCustomProperties = () => {
    if (configuration.value.customColors) {
      const root: HTMLDivElement | null = document.getElementsByClassName('hesAPISPA')[0] as HTMLDivElement;

      if (root) {
        for (const [
          key,
          value
        ] of Object.entries(configuration.value.customColors)) {
          root.style.setProperty(`--hes-color-${key}`, value);
        }
      }
    }
  };

if (props.unvalidatedconfiguration.locale) {
  setLocale(props.unvalidatedconfiguration.locale);
}

onErrorCaptured((error: Error): boolean | void => {
  isError.value = true;
  errorMessage.value = error.message;
  errorType.value = error.name;
  logError(error, props.unvalidatedconfiguration);

  return false;
});

const loadHesAssessment = async (unvalidatedconfiguration: Configuration): Promise<void> => {
  try {
      if (!props.unvalidatedconfiguration.assessmentId && deprecatedConfigurationIds.includes(props.unvalidatedconfiguration.configurationId)) {
        throw new DeprecatedConfigurationStartError(`${t('This version of Skills cannot be started.  Please use the new configuration.')}`);
      }

      // eslint-disable-next-line require-atomic-updates
      configuration.value = await useConfigurationInit(import.meta.env.VITE_TEST_ENVIRONMENT, props.unvalidatedconfiguration);

      const
        engine = await useAssessmentInit(configuration.value),
        thisWindow = window as any;

      if (thisWindow.Cypress) {
        thisWindow.assessmentId = engine.getAssessmentId();
        thisWindow.userId = engine.getUser().id;
      }

      setLocale(configuration.value.locale as string);

      if (engine instanceof ReportEngine) {
        await setReportProperties(engine);
        nextPage.value = 'Loading';
      } else {
        // instructors cannot view incomplete assessments
        if (configuration.value.readOnly === true) {
          throw new IncompleteAssessmentReadOnlyError();
        }

        assessmentEngine.value = engine;
        if (configuration.value.onAssessmentStart && !configuration.value.assessmentId) {
          configuration.value.onAssessmentStart(assessmentEngine.value.getAssessment());
        }

        nextPage.value = 'IntroText';
        productSuiteClass.value = assessmentEngine.value.getProductConfiguration().productSuite;
      }
      setCSSCustomProperties();
    } catch (error) {
      handleError(error);
    }
  };

onBeforeMount(async () => {
  await loadHesAssessment(props.unvalidatedconfiguration);
});

watch(
  nextPage, async() => {
    try {
      if (nextPage.value) {
        if (nextPage.value === 'Loading') {
          page.value = nextPage.value;

          // If report is accessed after completing the assessment
          if (assessmentEngine.value !== null) {
            // eslint-disable-next-line max-depth
            if (configuration.value.onAssessmentComplete) {
              configuration.value.onAssessmentComplete(assessmentEngine.value.getAssessment());
            }
            page.value = 'Report';
            await setReportProperties(assessmentEngine.value);
            component.value = defineAsyncComponent(() => import(`./components/${page.value}.vue`));
          // If report is accessed directly
          } else if (reportEngine.value) {
            page.value = 'Report';
            component.value = defineAsyncComponent(() => import(`./components/${page.value}.vue`));
          }
        } else {
          page.value = nextPage.value;
          component.value = defineAsyncComponent(() => import(`./components/${page.value}.vue`));
        }
      }
    } catch (error) {
      handleError(error);
    }
  },
  { flush: 'sync' }
);

window.loadHesAssessment = loadHesAssessment;
</script>

<template>
  <v-app
    :id="configuration.applicationDivId"
    light
    :class="productSuiteClass"
    data-cy="app-wrapper"
  >
    <v-alert
      :model-value="isError"
      type="error"
      elevation="10"
      origin="center"
      transition="slide-y-transition"
      class="hes-alert"
      :title="t('ERROR')"
      @click="resetError();"
    >
      <template #text>
        <p>
          {{ t('The following error has occurred') }} :
        </p>
        <p>
          <b>{{ errorType }}</b>
          <br>
          {{ errorMessage }}
        </p>
        <p>
          {{ t('Please reload the page and try again.') }}
        </p>
        <p>
          {{ t('If the error continues, contact your system administrator.') }}
        </p>
      </template>
    </v-alert>

    <component
      :is="component"
      :key="renderCount"
      :assessment-engine="assessmentEngine"
      :report-engine="reportEngine"
      :report-props="reportProps"
      :configuration="configuration"
      :message="message"
      :is-report-blocked="isReportBlocked"
      @clicked="displayCurrentState"
    />
  </v-app>
</template>

<style lang="scss" scoped>
.hes-alert {
  position: fixed;
  top: 10px;
  width: 90%;
  left: 5%;
  z-index: 2500;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}
</style>
