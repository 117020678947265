import '@mdi/font/css/materialdesignicons.css';

import { createApp, defineAsyncComponent, nextTick } from 'vue';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import 'vuetify/styles';
import '@/assets/scss/global.scss';
import { createVuetify } from 'vuetify';
import '@fortawesome/fontawesome-free/css/all.css';
import { library, config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faCircle,
  faStar,
} from '@fortawesome/free-regular-svg-icons';
import {
  faCaretRight,
  faCaretLeft,
  faCaretDown,
  faCircleCheck,
  faCheck,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleChevronDown,
  faChevronRight,
  faChevronLeft,
  faExclamationCircle,
  faTriangleExclamation,
  faRotateRight,
  faSearch,
  faStar as faStarSolid,
  faPrint,
  faFilePdf,
  faUpRightFromSquare,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import { i18n, useI18n } from './i18n';
import App from './App.vue';
import { type Configuration } from './ClientConfiguration'; 

declare global {
  interface Window {
    dummyHesAssessmentClientConfiguration: Configuration,
    startHesAssessmentClient: (unvalidatedconfiguration: Configuration) => void
  }
}

export const main = (unvalidatedconfiguration: Configuration): void => {
    const vuetify = createVuetify({
      theme: false,
      locale: {
        adapter: createVueI18nAdapter({ i18n, useI18n }),
        rtl: {
          default: false,
          'ar-SA': true,
          'ar-EG': true,
        },
      },
    });
    
    library.add(
      faCaretRight,
      faCaretLeft,
      faCaretDown,
      faCircle,
      faCircleCheck,
      faCheck,
      faCircleChevronLeft,
      faCircleChevronRight,
      faCircleChevronDown,
      faChevronRight,
      faChevronLeft,
      faExclamationCircle,
      faTriangleExclamation,
      faRotateRight,
      faSearch,
      faStar,
      faStarSolid,
      faPrint,
      faFilePdf,
      faUpRightFromSquare,
      faUser,
    );

    try {
      unvalidatedconfiguration.applicationDivId = unvalidatedconfiguration.applicationDivId ? unvalidatedconfiguration.applicationDivId : 'app';
      const vueApp = document.getElementById(unvalidatedconfiguration.applicationDivId);

      if (vueApp) {
        const hesVueAppWrapper = document.createElement('div');

        hesVueAppWrapper.classList.add('hesAPISPA');
        hesVueAppWrapper.appendChild(vueApp.cloneNode(true));
        vueApp.replaceWith(hesVueAppWrapper);
      }

      if (!unvalidatedconfiguration.clientBaseUrl) {
        let baseUrl = '';
        const scripts = document.getElementsByTagName('script');

        for (let i = 0; i < scripts.length; i += 1) {
          if (scripts[i].src.endsWith('assessment-client.es.js')) {
            baseUrl = scripts[i].src.replace('/assessment-client.es.js', '');
          }
        }

        if (!baseUrl) {
          throw new Error('client js url cannot be found');
        }

        unvalidatedconfiguration.clientBaseUrl = baseUrl;
      }

      const { head } = document;

      if (![
        'localvue',
        'ci'
      ].includes(unvalidatedconfiguration.apiEnvironment)) {
        const css = document.createElement('link');

        css.setAttribute('rel', 'stylesheet');
        css.setAttribute('type', 'text/css');
        css.setAttribute('media', 'all');
        css.setAttribute('href', `${unvalidatedconfiguration.clientBaseUrl}/assets/assessment-client.css`);
        head.appendChild(css);
      }

      const app = createApp(App, {
        unvalidatedconfiguration
      })
      .component('font-awesome-icon', FontAwesomeIcon)
      .component('Activity', defineAsyncComponent(() => import('./components/reportComponents/Activity.vue')))
      .component('ActivityContainer', defineAsyncComponent(() => import('./components/reportComponents/ActivityContainer.vue')))
      .component('Badges', defineAsyncComponent(() => import('./components/reportComponents/Badges.vue')))
      .component('CareerList', defineAsyncComponent(() => import('./components/reportComponents/CareerList.vue')))
      .component('CombiningIntelligences', defineAsyncComponent(() => import('./components/reportComponents/CombiningIntelligences.vue')))
      .component('EmotionalIntelligencesTraits', defineAsyncComponent(() => import('./components/reportComponents/EmotionalIntelligencesTraits.vue')))
      .component('EmotionalIntelligencesResultsText', defineAsyncComponent(() => import('./components/reportComponents/EmotionalIntelligencesResultsText.vue')))
      .component('Error', defineAsyncComponent(() => import('./components/Error.vue')))
      .component('GraphSection', defineAsyncComponent(() => import('./components/reportComponents/GraphSection.vue')))
      .component('GraphSectionGraph', defineAsyncComponent(() => import('./components/reportComponents/GraphSectionGraph.vue')))
      .component('GraphGroupSection', defineAsyncComponent(() => import('./components/reportComponents/GraphGroupSection.vue')))
      .component('GraphSubGroupingSection', defineAsyncComponent(() => import('./components/reportComponents/GraphSubGroupingSection.vue')))
      .component('Header', defineAsyncComponent(() => import('./components/Header.vue')))
      .component('IntelligencesGraphSection', defineAsyncComponent(() => import('./components/reportComponents/IntelligencesGraphSection.vue')))
      .component('IntelligencesGraphSectionGraph', defineAsyncComponent(() => import('./components/reportComponents/IntelligencesGraphSectionGraph.vue')))
      .component('IntelligencesTopCareers', defineAsyncComponent(() => import('./components/reportComponents/IntelligencesTopCareers.vue')))
      .component('TruTalentsReport', defineAsyncComponent(() => import('./components/TruTalentsReport.vue')))
      .component('Rating', defineAsyncComponent(() => import('./components/reportComponents/Rating.vue')))
      .component('Report', defineAsyncComponent(() => import('./components/Report.vue')))
      .component('Section', defineAsyncComponent(() => import('./components/reportComponents/Section.vue')))
      .component('SkillsGraphSection', defineAsyncComponent(() => import('./components/reportComponents/SkillsGraphSection.vue')))
      .component('SkillsGraphSectionGraph', defineAsyncComponent(() => import('./components/reportComponents/SkillsGraphSectionGraph.vue')))
      .component('StarRating', defineAsyncComponent(() => import('./components/reportComponents/StarRating.vue')))
      .component('SubSection', defineAsyncComponent(() => import('./components/reportComponents/SubSection.vue')))
      .component('SummaryReportIntelligences', defineAsyncComponent(() => import('./components/reportComponents/SummaryReportIntelligences.vue')))
      .component('SummaryReportLearningProductivity', defineAsyncComponent(() => import('./components/reportComponents/SummaryReportLearningProductivity.vue')))
      .component('SummaryReportPersonality', defineAsyncComponent(() => import('./components/reportComponents/SummaryReportPersonality.vue')))
      .component('SummaryReportSkills', defineAsyncComponent(() => import('./components/reportComponents/SummaryReportSkills.vue')))
      .component('TablePagination', defineAsyncComponent(() => import('./components/reportComponents/TablePagination.vue')))
      .component('TextBlock', defineAsyncComponent(() => import('./components/reportComponents/TextBlock.vue')))
      .component('TextBlockActivityWrapper', defineAsyncComponent(() => import('./components/reportComponents/TextBlockActivityWrapper.vue')));
      
      app.use(i18n);
      app.use(vuetify)    
      app.config.performance = true;
      app.config.performance = import.meta.env.VUE_APP_ENABLE_PERFORMANCE === 'true';
      
      app.directive('focus', {
        mounted(el, binding) {
          if (binding.value) {
            el.focus();
          }
        },
        updated(el, binding) {
          if (binding.value) {
            nextTick(() => {
              el.focus();
            });
          }
        }
      });
      
      app.mount(`#${unvalidatedconfiguration.applicationDivId}`);

    } catch (error) {
      if (error instanceof Error) {
        document.write(`<pre>${error.message}</pre>`);
      }

      throw error;
    }
  }

  // this is left here to ensure vite crawls the js code.  Otherwise it does not ouput any.
  if(window.dummyHesAssessmentClientConfiguration) {
    const unvalidatedconfiguration = window.dummyHesAssessmentClientConfiguration || {} as Configuration;
  
    main(unvalidatedconfiguration);
  } 

 window.startHesAssessmentClient = main;

