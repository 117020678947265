export class AssessmentTypeIri {
  public static readonly INTELLIGENCES_ASSESSMENT_TYPE_IRI = '/api/assessment_types/1';

  public static readonly LEARNING_AND_PRODUCTIVITY_ASSESSMENT_TYPE_IRI = '/api/assessment_types/2';

  public static readonly LEARNING_AND_PRODUCTIVITY_COLLEGE_ASSESSMENT_TYPE_IRI =
    '/api/assessment_types/3';

  public static readonly PERSONALITY_ASSESSMENT_TYPE_IRI = '/api/assessment_types/4';

  public static readonly SKILLS_ASSESSMENT_TYPE_IRI = '/api/assessment_types/8';

  public static readonly TRUTALENTS_ASSESSMENT_TYPE_IRI = '/api/assessment_types/9';

  public static readonly ASSESSMENTS_TYPES_WITH_CAREERS = [
    AssessmentTypeIri.INTELLIGENCES_ASSESSMENT_TYPE_IRI,
    AssessmentTypeIri.PERSONALITY_ASSESSMENT_TYPE_IRI,
    AssessmentTypeIri.SKILLS_ASSESSMENT_TYPE_IRI,
  ];
}
