type Task = () => void;

export class HesMutex {
  private tasks: Task[];

  private count: number;

  private sched: () => void;

  private release: () => void;

  constructor(count = 1) {
    this.tasks = [];
    this.count = count;

    // Bind the sched and release methods to the current instance
    this.sched = () => {
      while (this.count > 0 && this.tasks.length > 0) {
        this.count -= 1;
        const next = this.tasks.shift();
        if (next) {
          next();
        }
      }
    };

    this.release = () => {
      this.count += 1;
      this.sched();
    };
  }

  public acquire(): Promise<Task> {
    let resolver: (release: Task) => void;

    const task: Promise<Task> = new Promise((resolve) => {
      resolver = resolve;
    });

    this.tasks.push(() => resolver(this.release));

    // Schedule the next task
    // if (typeof process !== 'undefined' && process.nextTick) {
    //   process.nextTick(this.sched);
    // } else {
    //   setTimeout(this.sched, 0);
    // }
    setTimeout(this.sched, 0);

    return task;
  }

  public async use<T>(f: () => Promise<T>): Promise<T> {
    const release = await this.acquire();
    try {
      return await f();
    } finally {
      release();
    }
  }
}
