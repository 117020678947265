import { createI18n, type I18n, useI18n } from 'vue-i18n';

import en from './en-US.json';
import pt from './pt-BR.json'; 
import es from './es-ES.json';
import fr from './fr-CA.json';
import zh from './zh-CN.json';
import arEg from './ar-EG.json';
import arSa from './ar-SA.json';
import enAu from './en-AU.json';

export const messages = {
  'en-US': en,
  'pt-BR': pt,
  'es-ES': es,
  'fr-CA': fr,
  'zh-CN': zh,
  'ar-EG': arEg,
  'ar-SA': arSa,
  'en-AU': enAu,
};

export type ValidLocale = 'en-US' | 'es-ES' | 'pt-BR' | 'fr-CA' | 'zh-CN' | 'ar-EG' | 'ar-SA' | 'en-AU';

export const i18n: I18n = createI18n({
  legacy: false,
  allowComposition: true,
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages: messages,
  globalInjection: true,
});

export { useI18n };

export const getI18n = (locale?: string) => {
  const currentLocale = locale ? locale: 'en-US';

  return useI18n({
    locale: currentLocale,
    messages: messages
  });
}

