import { 
  AssessmentTypeIri, 
  type QuestionTypeFullRead, 
  type AchieveWorksReportElementFullRead, 
  type AchieveWorksReportAssessmentScoreFullRead, 
  ReportEngine, 
  BadgeManager, 
  type ElementBadge, 
  type ElementBadgeStatus 
} from './lib/sdk';
import { type ValidatedConfiguration } from './ClientConfiguration';
import { DefaultCareerRatingEngine } from './career-rating/default-career-rating-engine';
import { AlternativeCareerRatingEngine } from './career-rating/alternative-career-rating-engine';
import { CareerRatingEngineFactory } from './career-rating/career-rating-engine-factory';
import { AssessmentTypeInitializationError } from './errors/assessment-type-initialization-error';
import { GraphDataInitializationError } from './errors/graph-data-initialization-error';

interface CssClassMap {
  [key: string]: string; // This means each key is a string, and its value is also a string.
}

export interface CssClasses {
  'sectionIcon': CssClassMap,
  'sectionBackgroundColor': string,
  'graphGrouping'? : CssClassMap,
  'graphGroupSection': string,
  'questionTypeIcon'? : CssClassMap,
  'sectionLargeIcon'? : CssClassMap,
  'macroQuestionTypeIcon'?: CssClassMap,
};

export interface AssessmentScore {'questionType': QuestionTypeFullRead, 'score': number, 'name': string, 'description': string, 'abbrev': string}

export class ReportProps {
    public configuration: ValidatedConfiguration;

    public element: AchieveWorksReportElementFullRead;

    public reportEngine: ReportEngine;

    public displayTypeGroup: string;

    public cssClasses: CssClasses;

    public assessmentTypeIri: string;

    public assessmentType: String;

    public careerRatingEngine: DefaultCareerRatingEngine | AlternativeCareerRatingEngine;

    // eslint-disable-next-line max-params
    private constructor(
      element: AchieveWorksReportElementFullRead,
      reportEngine: ReportEngine,
      configuration: ValidatedConfiguration,
      careerRatingEngine: DefaultCareerRatingEngine | AlternativeCareerRatingEngine
    ) {
      this.configuration = configuration;
      this.reportEngine = reportEngine;
      this.assessmentTypeIri = reportEngine.getProductConfiguration().assessmentType;
      this.element = element;
      this.displayTypeGroup = this.getDisplayTypeGroup();
      this.cssClasses = this.getCssClasses();
      this.assessmentType = this.getAssessmentType();
      this.careerRatingEngine = careerRatingEngine;
    }

    public static async getReportProps(
      element: AchieveWorksReportElementFullRead,
      reportEngine: ReportEngine,
      configuration: ValidatedConfiguration,
    ): Promise<ReportProps> {
      const careerRatingEngine = await CareerRatingEngineFactory.getCareerRatingEngine(reportEngine, configuration.careerSection.careerRatings);
      const reportProps = new ReportProps(element, reportEngine, configuration, careerRatingEngine);

      return reportProps;
    }

    public createReportPropsForElement(element: AchieveWorksReportElementFullRead): ReportProps {
      return new ReportProps(
        element,
        this.reportEngine,
        this.configuration,
        this.careerRatingEngine
      );
    }

    public isBadgingEnabled(): boolean {
      return Boolean(this.configuration.displayBadging);
    }

    public getIndeedBaseUrl(): string {
      let indeedBaseUrl = 'https://www.indeed.com';

      if (this.configuration.indeedSubDomain) {
        indeedBaseUrl = `https://${this.configuration.indeedSubDomain}.indeed.com`;
      }

      return indeedBaseUrl;
    }

    public getBadgeManager(): BadgeManager | null {
      return this.reportEngine.getBadgeManager();
    }

    public getElementBadgeStatuses(): Map<string, ElementBadgeStatus> | null {
      const badgeManager = this.getBadgeManager();

      if (badgeManager) {
        return badgeManager.getElementBadgeStatuses();
      }

      return null;
    }

    public getElementBadges(elementId: string): Array<ElementBadge> | null {
      const elementBadgeStatuses = this.getElementBadgeStatuses();

      if (elementBadgeStatuses) {
        const elementBadgeStatus = elementBadgeStatuses.get(elementId);

        if (elementBadgeStatus) {
          return Array.from(elementBadgeStatus.elementBadges.values());
        }
      }

      return null;
    }

    public getActivityElementBadge(elementId: string): ElementBadge | null {
      const elementBadgeStatuses = this.getElementBadgeStatuses();

      if (elementBadgeStatuses) {
        const elementBadgeStatus = elementBadgeStatuses.get(elementId);

        if (elementBadgeStatus) {
          const elementBadge = elementBadgeStatus.elementBadges.values().next().value;

          if (elementBadge) {
            return elementBadge;
          }
        }
      }

      return null;
    }

    public getElementBadge(elementId: string, badgeId: string): ElementBadge | null {
      const elementBadgeStatuses = this.getElementBadgeStatuses();

      if (elementBadgeStatuses) {
        const elementBadgeStatus = elementBadgeStatuses.get(elementId);

        if (elementBadgeStatus) {
          const elementBadge = elementBadgeStatus.elementBadges.get(badgeId);

          if (elementBadge) {
            return elementBadge;
          }
        }
      }

      return null;
    }

    public async earnReadBadge(elementId: string): Promise<Array<ElementBadge>> {
      const badgeManager = this.getBadgeManager();
      const elementReadBadge = this.getElementBadge(elementId, BadgeManager.READ_BADGE_ID);
      let elementBadges = [] as Array<ElementBadge>;

      if (badgeManager && elementReadBadge && !elementReadBadge.isEarned && !this.configuration.readOnly) {
        elementBadges = await badgeManager.earnReadBadge(elementId);
      }

      return elementBadges;
    }

    public getDisplayTypeGroup (): string {
      let displayTypeGroup = this.element.displayType;

      switch (this.element.displayType) {
      case 'TextVersus':
      case 'TextBlockActivityWrapper':
      case 'IntroTextBlock':
        displayTypeGroup = 'TextBlock';
        break;
      case 'SkillsSimpleFacetGraphSection':
        displayTypeGroup = 'SkillsGraphSection';
        break;
      case 'LearningAndProductivityGraphSection':
        displayTypeGroup = 'GraphSection';
        break;
      case 'EmotionalIntelligencesGraph':
        displayTypeGroup = 'IntelligencesGraphSection';
        break;
      case 'pRating':
        displayTypeGroup = 'Rating';
        break;
      default:
        displayTypeGroup = this.element.displayType;
      }

      return displayTypeGroup;
    }

    public getAssessmentType(): String {
      switch (this.assessmentTypeIri) {
        case AssessmentTypeIri.LEARNING_AND_PRODUCTIVITY_ASSESSMENT_TYPE_IRI:
        case AssessmentTypeIri.LEARNING_AND_PRODUCTIVITY_COLLEGE_ASSESSMENT_TYPE_IRI:
          return 'learning-productivity';
        case AssessmentTypeIri.INTELLIGENCES_ASSESSMENT_TYPE_IRI:
          return 'intelligences';
        case AssessmentTypeIri.PERSONALITY_ASSESSMENT_TYPE_IRI:
          return 'personality';
        case AssessmentTypeIri.SKILLS_ASSESSMENT_TYPE_IRI:
          return 'skills';
        case AssessmentTypeIri.TRUTALENTS_ASSESSMENT_TYPE_IRI:
          return 'trutalents';
        default:
          throw new AssessmentTypeInitializationError('invalid configurationId');
      }
    }

    public getCssClasses(): CssClasses {
      if ([
        AssessmentTypeIri.LEARNING_AND_PRODUCTIVITY_ASSESSMENT_TYPE_IRI,
        AssessmentTypeIri.LEARNING_AND_PRODUCTIVITY_COLLEGE_ASSESSMENT_TYPE_IRI
      ].includes(this.assessmentTypeIri)) {
        return {
          'sectionIcon': {
            '48': 'iconSlider',
            '49': 'iconSlider',
            '50': 'iconSlider',
            '10001': 'iconSlider',
            '10002': 'iconSlider',
            '10003': 'iconSlider',
            '21001': 'iconSlider',
            '21002': 'iconSlider',
            '21003': 'iconSlider',
          },
          'sectionBackgroundColor': 'blueBG',
          'graphGroupSection': 'profileSlider',
        };
      }

      if (this.assessmentTypeIri === AssessmentTypeIri.INTELLIGENCES_ASSESSMENT_TYPE_IRI) {
        return {
          'sectionIcon': {
            '31': 'iconStrengthBars',
            '32': 'iconDevelopingIntelligence',
            '33': 'iconEmotionalIntelligence',
            '34': 'iconCareerPathways',
            '11031': 'iconStrengthBars',
            '11032': 'iconDevelopingIntelligence',
            '11033': 'iconEmotionalIntelligence',
            '11034': 'iconCareerPathways',
          },
          'graphGrouping': {
            '5001': 'greenGrouping',
            '5002': 'blueGrouping',
            '5003': 'orangeGrouping',
            '16001': 'greenGrouping',
            '16002': 'blueGrouping',
            '16003': 'orangeGrouping',
          },
          'sectionBackgroundColor': 'purpleBG',
          'graphGroupSection': 'profileStrengthBar',
          'questionTypeIcon': {
            '1': 'iconBodily-Kinesthetic',
            '2': 'iconExistential',
            '3': 'iconInterpersonal',
            '4': 'iconIntrapersonal',
            '5': 'iconLinguistic',
            '6': 'iconLogical-Mathematical',
            '7': 'iconMusical',
            '8': 'iconNaturalist',
            '9': 'iconSpatial',
          },
        };
      }
      if (this.assessmentTypeIri === AssessmentTypeIri.PERSONALITY_ASSESSMENT_TYPE_IRI) {
        return {
          'sectionIcon': {
            '1': 'iconSlider',
            '2': 'iconLearning',
            '3': 'iconProblemSolving',
            '4': 'iconCommunication',
            '5': 'iconCollaboration',
            '6': 'iconCareerPathways',
            '11001': 'iconSlider',
            '11002': 'iconLearning',
            '11003': 'iconProblemSolving',
            '11004': 'iconCommunication',
            '11005': 'iconCollaboration',
            '11006': 'iconCareerPathways',
          },
          'sectionBackgroundColor': 'greenBG',
          'graphGroupSection': 'profileSlider',
        };
      }

      //TODO:add skills v1 if needed
      if (this.assessmentTypeIri === AssessmentTypeIri.SKILLS_ASSESSMENT_TYPE_IRI) {
        return {
          'sectionIcon': {
            '41001': 'iconIntro',
            '41006': 'iconSocEmotional',
            '41008': 'iconSlider',
            '41004': 'iconThinking',
            '41005': 'iconLeadership',
            '41003': 'iconCreativity',
            '41002': 'iconCommitment',
            '41007': 'iconCareerPathways',
            '51001': 'iconIntro',
            '51006': 'iconSocEmotional',
            '51008': 'iconSlider',
            '51004': 'iconThinking',
            '51005': 'iconLeadership',
            '51003': 'iconCreativity',
            '51002': 'iconCommitment',
            '51007': 'iconCareerPathways',
          },
          'sectionLargeIcon': {
            '41006': 'iconSocEmotional',
            '41008': 'iconSlider',
            '41004': 'iconThinking',
            '41005': 'iconLeadership',
            '41003': 'iconCreativity',
            '41002': 'iconCommitment',
          },
          'macroQuestionTypeIcon': {
            '204': 'iconSocEmotional',
            '201': 'iconCreativity',
            '202': 'iconThinking',
            '203': 'iconLeadership',
            '200': 'iconCommitment',
          },
          'graphGrouping': {
            'top': 'green',
            'moderate': 'blue',
            'lower': 'orange',
          },
          'sectionBackgroundColor': 'redBG',
          'graphGroupSection': 'profileSlider',
        };
      }

      // temporary dummy data
      if (this.assessmentTypeIri === AssessmentTypeIri.TRUTALENTS_ASSESSMENT_TYPE_IRI) {
        return {
          'sectionIcon': {
          },
          'sectionBackgroundColor': 'greenBG',
          'graphGroupSection': 'profileSlider',
        };
      }

      throw new AssessmentTypeInitializationError('invalid configurationId');
    }

    public getGraphData(): AssessmentScore {
      if (this.element.assessmentQuestionType) {
        const questionTypeId = this.element.assessmentQuestionType.id,
          { assessmentScores } = this.reportEngine.getReport();
        let graphData: AssessmentScore | null = null;

        assessmentScores.some((assessmentScore: AchieveWorksReportAssessmentScoreFullRead) => {
          const typedAssessmentScore = assessmentScore as AssessmentScore;

          if (typedAssessmentScore.questionType.id === questionTypeId) {
            graphData = typedAssessmentScore;

            return true;
          }

          return false;
        });

        if (graphData === null) {
          throw new GraphDataInitializationError('missing assessment score');
        }

        return graphData;
      }

      throw new GraphDataInitializationError('missing question type while getting assessment score');
    }
}

