/* tslint:disable */
/* eslint-disable */
/**
 * Human eSources API
 * Access various assessment-related resources.
 *
 * The version of the OpenAPI document: 1.29.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AbstractTranslation
 */
export interface AbstractTranslation {
    /**
     * ID
     * @type {any}
     * @memberof AbstractTranslation
     */
    'id'?: any;
    /**
     * 
     * @type {object}
     * @memberof AbstractTranslation
     */
    'translatable'?: object;
    /**
     * Locale
     * @type {any}
     * @memberof AbstractTranslation
     */
    'locale'?: any;
}
/**
 * 
 * @export
 * @interface AchieveWorksReportAnswerFullRead
 */
export interface AchieveWorksReportAnswerFullRead {
    /**
     * 
     * @type {Array<ExampleFullRead>}
     * @memberof AchieveWorksReportAnswerFullRead
     */
    'examples': Array<ExampleFullRead>;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportAnswerFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportAnswerFullRead
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportAnswerFullRead
     */
    'question': string;
}
/**
 * AchieveWorksReportAssessmentMacroScore
 * @export
 * @interface AchieveWorksReportAssessmentMacroScoreFullRead
 */
export interface AchieveWorksReportAssessmentMacroScoreFullRead {
    /**
     * 
     * @type {number}
     * @memberof AchieveWorksReportAssessmentMacroScoreFullRead
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportAssessmentMacroScoreFullRead
     */
    'name': string;
    /**
     * 
     * @type {MacroQuestionTypeFullRead}
     * @memberof AchieveWorksReportAssessmentMacroScoreFullRead
     */
    'macroQuestionType': MacroQuestionTypeFullRead;
}
/**
 * AchieveWorksReportAssessmentScore
 * @export
 * @interface AchieveWorksReportAssessmentScoreFullRead
 */
export interface AchieveWorksReportAssessmentScoreFullRead {
    /**
     * 
     * @type {number}
     * @memberof AchieveWorksReportAssessmentScoreFullRead
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportAssessmentScoreFullRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportAssessmentScoreFullRead
     */
    'description': string;
    /**
     * 
     * @type {QuestionTypeFullRead}
     * @memberof AchieveWorksReportAssessmentScoreFullRead
     */
    'questionType': QuestionTypeFullRead;
}
/**
 * Badge.
 * @export
 * @interface AchieveWorksReportBadgeFullRead
 */
export interface AchieveWorksReportBadgeFullRead {
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportBadgeFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportBadgeFullRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportBadgeFullRead
     */
    'badgeText': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportBadgeFullRead
     */
    'badgeTextEarned': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportBadgeFullRead
     */
    'activityBadgeText': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportBadgeFullRead
     */
    'activityBadgeTextEarned': string;
}
/**
 * Element.
 * @export
 * @interface AchieveWorksReportElementFullRead
 */
export interface AchieveWorksReportElementFullRead {
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportElementFullRead
     */
    'id': string;
    /**
     * 
     * @type {MacroQuestionTypeFullRead}
     * @memberof AchieveWorksReportElementFullRead
     */
    'assessmentMacroQuestionType'?: MacroQuestionTypeFullRead;
    /**
     * 
     * @type {QuestionTypeFullRead}
     * @memberof AchieveWorksReportElementFullRead
     */
    'assessmentQuestionType'?: QuestionTypeFullRead;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportElementFullRead
     */
    'displayType': string;
    /**
     * 
     * @type {ActivityTypeFullRead}
     * @memberof AchieveWorksReportElementFullRead
     */
    'activityType'?: ActivityTypeFullRead;
    /**
     * 
     * @type {Array<AchieveWorksReportElementFullRead>}
     * @memberof AchieveWorksReportElementFullRead
     */
    'elements': Array<AchieveWorksReportElementFullRead>;
    /**
     * 
     * @type {Array<AchieveWorksReportQuestionFullRead>}
     * @memberof AchieveWorksReportElementFullRead
     */
    'questions': Array<AchieveWorksReportQuestionFullRead>;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportElementFullRead
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportElementFullRead
     */
    'subTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportElementFullRead
     */
    'content'?: string;
    /**
     * 
     * @type {Array<CareerMinimumRead>}
     * @memberof AchieveWorksReportElementFullRead
     */
    'careers': Array<CareerMinimumRead>;
}
/**
 * Report.
 * @export
 * @interface AchieveWorksReportFullRead
 */
export interface AchieveWorksReportFullRead {
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportFullRead
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportFullRead
     */
    'assessment': string;
    /**
     * 
     * @type {Array<AchieveWorksReportAssessmentScoreFullRead>}
     * @memberof AchieveWorksReportFullRead
     */
    'assessmentScores': Array<AchieveWorksReportAssessmentScoreFullRead>;
    /**
     * 
     * @type {number}
     * @memberof AchieveWorksReportFullRead
     */
    'emotionalIntelligenceScore'?: number | null;
    /**
     * 
     * @type {Array<AchieveWorksReportElementFullRead>}
     * @memberof AchieveWorksReportFullRead
     */
    'elements': Array<AchieveWorksReportElementFullRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AchieveWorksReportFullRead
     */
    'reportUserAnswers': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AchieveWorksReportFullRead
     */
    'reportElementRatings': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AchieveWorksReportFullRead
     */
    'reportElementBadges': Array<string>;
    /**
     * 
     * @type {ProductConfigurationFullRead}
     * @memberof AchieveWorksReportFullRead
     */
    'productConfiguration': ProductConfigurationFullRead;
    /**
     * 
     * @type {CareerRangeMinimumRead}
     * @memberof AchieveWorksReportFullRead
     */
    'careerRange'?: CareerRangeMinimumRead;
    /**
     * Return all assessment macro scores.
     * @type {Array<AchieveWorksReportAssessmentMacroScoreFullRead>}
     * @memberof AchieveWorksReportFullRead
     */
    'assessmentMacroScores': Array<AchieveWorksReportAssessmentMacroScoreFullRead>;
    /**
     * 
     * @type {UserAnswerFullRead}
     * @memberof AchieveWorksReportFullRead
     */
    'assessmentRating'?: UserAnswerFullRead;
    /**
     * 
     * @type {Array<ReportScoreRangeFullRead>}
     * @memberof AchieveWorksReportFullRead
     */
    'reportScoreRanges': Array<ReportScoreRangeFullRead>;
    /**
     * 
     * @type {UserFullRead}
     * @memberof AchieveWorksReportFullRead
     */
    'user': UserFullRead;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportFullRead
     */
    'assessmentCompletedAt': string;
}
/**
 * Report.
 * @export
 * @interface AchieveWorksReportMinimumRead
 */
export interface AchieveWorksReportMinimumRead {
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportMinimumRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportMinimumRead
     */
    'assessment'?: string | null;
}
/**
 * Question.
 * @export
 * @interface AchieveWorksReportQuestionFullRead
 */
export interface AchieveWorksReportQuestionFullRead {
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportQuestionFullRead
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof AchieveWorksReportQuestionFullRead
     */
    'isMultiAnswer'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AchieveWorksReportQuestionFullRead
     */
    'numAnswersRequired'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AchieveWorksReportQuestionFullRead
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<AchieveWorksReportAnswerFullRead>}
     * @memberof AchieveWorksReportQuestionFullRead
     */
    'answers'?: Array<AchieveWorksReportAnswerFullRead>;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportQuestionFullRead
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface AchieveWorksReportRatingFullRead
 */
export interface AchieveWorksReportRatingFullRead {
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportRatingFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AchieveWorksReportRatingFullRead
     */
    'text'?: string | null;
}
/**
 * ActivityType.
 * @export
 * @interface ActivityTypeFullRead
 */
export interface ActivityTypeFullRead {
    /**
     * 
     * @type {string}
     * @memberof ActivityTypeFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityTypeFullRead
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AnswerFullRead
 */
export interface AnswerFullRead {
    /**
     * 
     * @type {string}
     * @memberof AnswerFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnswerFullRead
     */
    'text': string;
    /**
     * 
     * @type {QuestionFullRead}
     * @memberof AnswerFullRead
     */
    'question': QuestionFullRead;
}
/**
 * 
 * @export
 * @interface AnswerMinimumRead
 */
export interface AnswerMinimumRead {
    /**
     * 
     * @type {string}
     * @memberof AnswerMinimumRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnswerMinimumRead
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof AnswerMinimumRead
     */
    'question': string;
}
/**
 * AssessmentCareerMatches.
 * @export
 * @interface AssessmentCareerMatchesFullRead
 */
export interface AssessmentCareerMatchesFullRead {
    /**
     * 
     * @type {string}
     * @memberof AssessmentCareerMatchesFullRead
     */
    'id': string;
    /**
     * Return Assessment.
     * @type {string}
     * @memberof AssessmentCareerMatchesFullRead
     */
    'assessment': string;
    /**
     * 
     * @type {CareerRangeMinimumRead}
     * @memberof AssessmentCareerMatchesFullRead
     */
    'careerRange': CareerRangeMinimumRead;
    /**
     * 
     * @type {Array<CareerMatchFullRead>}
     * @memberof AssessmentCareerMatchesFullRead
     */
    'careerMatches': Array<CareerMatchFullRead>;
}
/**
 * Assessment.
 * @export
 * @interface AssessmentFullRead
 */
export interface AssessmentFullRead {
    /**
     * 
     * @type {string}
     * @memberof AssessmentFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFullRead
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFullRead
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFullRead
     */
    'completedAt'?: string | null;
    /**
     * 
     * @type {ProductConfigurationFullRead}
     * @memberof AssessmentFullRead
     */
    'productConfiguration': ProductConfigurationFullRead;
    /**
     * 
     * @type {UserFullRead}
     * @memberof AssessmentFullRead
     */
    'user': UserFullRead;
    /**
     * 
     * @type {Array<QuestionFullRead>}
     * @memberof AssessmentFullRead
     */
    'questions': Array<QuestionFullRead>;
    /**
     * 
     * @type {Array<UserAnswerFullRead>}
     * @memberof AssessmentFullRead
     */
    'userAnswers': Array<UserAnswerFullRead>;
    /**
     * 
     * @type {Array<QuestionFullRead>}
     * @memberof AssessmentFullRead
     */
    'tiebreakerQuestions': Array<QuestionFullRead>;
    /**
     * 
     * @type {Array<CareerClusterUserRatingFullRead>}
     * @memberof AssessmentFullRead
     */
    'careerClusterUserRatings': Array<CareerClusterUserRatingFullRead>;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFullRead
     */
    'introductionText': string;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentFullRead
     */
    'isInvalid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentFullRead
     */
    'isResultReviewed': boolean;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFullRead
     */
    'achieveWorksReport'?: string | null;
    /**
     * 
     * @type {QuestionFullRead}
     * @memberof AssessmentFullRead
     */
    'ratingQuestion'?: QuestionFullRead;
    /**
     * 
     * @type {UserAnswerFullRead}
     * @memberof AssessmentFullRead
     */
    'ratingAnswer'?: UserAnswerFullRead;
    /**
     * 
     * @type {Array<UserAnswerFullRead>}
     * @memberof AssessmentFullRead
     */
    'tiebreakerUserAnswers': Array<UserAnswerFullRead>;
    /**
     * Gets the object state.
     * @type {string}
     * @memberof AssessmentFullRead
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFullRead
     */
    'personalityType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFullRead
     */
    'personalityProfile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFullRead
     */
    'assessmentType'?: string;
}
/**
 * 
 * @export
 * @interface AssessmentMacroScoreMinimumRead
 */
export interface AssessmentMacroScoreMinimumRead {
    /**
     * 
     * @type {number}
     * @memberof AssessmentMacroScoreMinimumRead
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMacroScoreMinimumRead
     */
    'name': string;
    /**
     * 
     * @type {MacroQuestionTypeFullRead}
     * @memberof AssessmentMacroScoreMinimumRead
     */
    'macroQuestionType': MacroQuestionTypeFullRead;
}
/**
 * Assessment.
 * @export
 * @interface AssessmentMinimumPatch
 */
export interface AssessmentMinimumPatch {
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentMinimumPatch
     */
    'isResultReviewed'?: boolean;
}
/**
 * Assessment.
 * @export
 * @interface AssessmentMinimumRead
 */
export interface AssessmentMinimumRead {
    /**
     * 
     * @type {string}
     * @memberof AssessmentMinimumRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMinimumRead
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMinimumRead
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMinimumRead
     */
    'completedAt'?: string | null;
    /**
     * e
     * @type {string}
     * @memberof AssessmentMinimumRead
     */
    'productConfiguration': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMinimumRead
     */
    'user': string;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentMinimumRead
     */
    'isInvalid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentMinimumRead
     */
    'isResultReviewed': boolean;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMinimumRead
     */
    'achieveWorksReport'?: string | null;
    /**
     * Gets the object state.
     * @type {string}
     * @memberof AssessmentMinimumRead
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMinimumRead
     */
    'personalityType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMinimumRead
     */
    'assessmentType': string;
    /**
     * 
     * @type {Array<AssessmentScoreMinimumRead>}
     * @memberof AssessmentMinimumRead
     */
    'scores'?: Array<AssessmentScoreMinimumRead>;
    /**
     * 
     * @type {Array<AssessmentMacroScoreMinimumRead>}
     * @memberof AssessmentMinimumRead
     */
    'macroScores'?: Array<AssessmentMacroScoreMinimumRead>;
}
/**
 * Assessment.
 * @export
 * @interface AssessmentMinimumWrite
 */
export interface AssessmentMinimumWrite {
    /**
     * e
     * @type {string}
     * @memberof AssessmentMinimumWrite
     */
    'productConfiguration': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentMinimumWrite
     */
    'user': string;
}
/**
 * 
 * @export
 * @interface AssessmentScoreMinimumRead
 */
export interface AssessmentScoreMinimumRead {
    /**
     * 
     * @type {number}
     * @memberof AssessmentScoreMinimumRead
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentScoreMinimumRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentScoreMinimumRead
     */
    'description': string;
    /**
     * 
     * @type {QuestionTypeFullRead}
     * @memberof AssessmentScoreMinimumRead
     */
    'questionType': QuestionTypeFullRead;
}
/**
 * AssessmentType.
 * @export
 * @interface AssessmentTypeFullRead
 */
export interface AssessmentTypeFullRead {
    /**
     * 
     * @type {string}
     * @memberof AssessmentTypeFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentTypeFullRead
     */
    'name'?: string;
}
/**
 * CareerCluster.
 * @export
 * @interface CareerClusterFullRead
 */
export interface CareerClusterFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerClusterFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerClusterFullRead
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CareerClusterFullRead
     */
    'clusterCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CareerClusterFullRead
     */
    'description': string;
}
/**
 * CareerCluster.
 * @export
 * @interface CareerClusterMinimumRead
 */
export interface CareerClusterMinimumRead {
    /**
     * 
     * @type {string}
     * @memberof CareerClusterMinimumRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerClusterMinimumRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CareerClusterMinimumRead
     */
    'clusterCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CareerClusterMinimumRead
     */
    'description'?: string | null;
}
/**
 * CareerClusterRating.
 * @export
 * @interface CareerClusterRatingFullRead
 */
export interface CareerClusterRatingFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerClusterRatingFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerClusterRatingFullRead
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerClusterRatingFullRead
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface CareerClusterUserRatingFullRead
 */
export interface CareerClusterUserRatingFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerClusterUserRatingFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerClusterUserRatingFullRead
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CareerClusterUserRatingFullRead
     */
    'updatedAt': string;
    /**
     * 
     * @type {CareerClusterFullRead}
     * @memberof CareerClusterUserRatingFullRead
     */
    'careerCluster': CareerClusterFullRead;
    /**
     * 
     * @type {CareerClusterRatingFullRead}
     * @memberof CareerClusterUserRatingFullRead
     */
    'rating': CareerClusterRatingFullRead;
}
/**
 * 
 * @export
 * @interface CareerClusterUserRatingMinimumWrite
 */
export interface CareerClusterUserRatingMinimumWrite {
    /**
     * 
     * @type {string}
     * @memberof CareerClusterUserRatingMinimumWrite
     */
    'assessment': string;
    /**
     * 
     * @type {string}
     * @memberof CareerClusterUserRatingMinimumWrite
     */
    'careerCluster': string;
    /**
     * 
     * @type {string}
     * @memberof CareerClusterUserRatingMinimumWrite
     */
    'rating': string;
}
/**
 * 
 * @export
 * @interface CareerClusterUserRatingUpdateCareerClusterUserRating
 */
export interface CareerClusterUserRatingUpdateCareerClusterUserRating {
    /**
     * 
     * @type {string}
     * @memberof CareerClusterUserRatingUpdateCareerClusterUserRating
     */
    'rating'?: string | null;
}
/**
 * 
 * @export
 * @interface CareerFacet
 */
export interface CareerFacet {
    /**
     * 
     * @type {string}
     * @memberof CareerFacet
     */
    'id'?: string;
    /**
     * 
     * @type {Array<CareerFacetTranslation>}
     * @memberof CareerFacet
     */
    'translations'?: Array<CareerFacetTranslation>;
    /**
     * 
     * @type {string}
     * @memberof CareerFacet
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerFacet
     */
    'description'?: string;
    /**
     * 
     * @type {AbstractTranslation}
     * @memberof CareerFacet
     */
    'currentTranslation'?: AbstractTranslation;
    /**
     * 
     * @type {string}
     * @memberof CareerFacet
     */
    'currentLocale'?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerFacet
     */
    'fallbackLocale'?: string;
}
/**
 * 
 * @export
 * @interface CareerFacetFullRead
 */
export interface CareerFacetFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerFacetFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerFacetFullRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CareerFacetFullRead
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface CareerFacetTranslation
 */
export interface CareerFacetTranslation {
    /**
     * 
     * @type {string}
     * @memberof CareerFacetTranslation
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerFacetTranslation
     */
    'description'?: string | null;
    /**
     * ID
     * @type {any}
     * @memberof CareerFacetTranslation
     */
    'id'?: any;
    /**
     * Translatable model
     * @type {string}
     * @memberof CareerFacetTranslation
     */
    'translatable'?: string;
    /**
     * Locale
     * @type {string}
     * @memberof CareerFacetTranslation
     */
    'locale'?: string;
}
/**
 * Career.
 * @export
 * @interface CareerFullRead
 */
export interface CareerFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerFullRead
     */
    'onetId': string;
    /**
     * 
     * @type {string}
     * @memberof CareerFullRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CareerFullRead
     */
    'annualSalary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerFullRead
     */
    'outlookGrowthPercentage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CareerFullRead
     */
    'outlookJobOpenings'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CareerFullRead
     */
    'overallOutlook'?: number;
    /**
     * 
     * @type {string}
     * @memberof CareerFullRead
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof CareerFullRead
     */
    'onetJobZone'?: number | null;
    /**
     * 
     * @type {Array<CareerClusterFullRead>}
     * @memberof CareerFullRead
     */
    'clusters': Array<CareerClusterFullRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CareerFullRead
     */
    'majors': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CareerFullRead
     */
    'relatedCareers': Array<string>;
    /**
     * 
     * @type {Array<CareerProgramFullRead>}
     * @memberof CareerFullRead
     */
    'careerPrograms': Array<CareerProgramFullRead>;
}
/**
 * Class CareerIntelligencesBaseline.
 * @export
 * @interface CareerIntelligencesBaselineFullRead
 */
export interface CareerIntelligencesBaselineFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerIntelligencesBaselineFullRead
     */
    'career': string;
    /**
     * 
     * @type {QuestionTypeMinimumRead}
     * @memberof CareerIntelligencesBaselineFullRead
     */
    'questionType': QuestionTypeMinimumRead;
    /**
     * 
     * @type {number}
     * @memberof CareerIntelligencesBaselineFullRead
     */
    'value': number;
}
/**
 * Career.
 * @export
 * @interface CareerItemFullRead
 */
export interface CareerItemFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerItemFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerItemFullRead
     */
    'onetId': string;
    /**
     * 
     * @type {string}
     * @memberof CareerItemFullRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CareerItemFullRead
     */
    'annualSalary'?: string | null;
    /**
     * 
     * @type {Array<CareerClusterFullRead>}
     * @memberof CareerItemFullRead
     */
    'clusters': Array<CareerClusterFullRead>;
    /**
     * 
     * @type {string}
     * @memberof CareerItemFullRead
     */
    'outlookGrowthPercentage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CareerItemFullRead
     */
    'outlookJobOpenings'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CareerItemFullRead
     */
    'overallOutlook'?: number;
    /**
     * 
     * @type {Array<RelatedCareerRead>}
     * @memberof CareerItemFullRead
     */
    'relatedCareers': Array<RelatedCareerRead>;
    /**
     * 
     * @type {Array<CareerSkillsBaselineFullRead>}
     * @memberof CareerItemFullRead
     */
    'careerSkillsBaselines': Array<CareerSkillsBaselineFullRead>;
    /**
     * 
     * @type {Array<CareerIntelligencesBaselineFullRead>}
     * @memberof CareerItemFullRead
     */
    'careerIntelligencesBaselines': Array<CareerIntelligencesBaselineFullRead>;
    /**
     * 
     * @type {number}
     * @memberof CareerItemFullRead
     */
    'outlookStartYearJobsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CareerItemFullRead
     */
    'outlookEndYearJobsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CareerItemFullRead
     */
    'description': string;
    /**
     * 
     * @type {Array<CareerProgramFullRead>}
     * @memberof CareerItemFullRead
     */
    'careerPrograms': Array<CareerProgramFullRead>;
    /**
     * 
     * @type {number}
     * @memberof CareerItemFullRead
     */
    'onetJobZone'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CareerItemFullRead
     */
    'onetJobZoneDescription'?: string | null;
    /**
     * 
     * @type {Array<CareerFacetFullRead>}
     * @memberof CareerItemFullRead
     */
    'interests': Array<CareerFacetFullRead>;
    /**
     * 
     * @type {Array<CareerFacetFullRead>}
     * @memberof CareerItemFullRead
     */
    'knowledge': Array<CareerFacetFullRead>;
    /**
     * 
     * @type {Array<CareerFacetFullRead>}
     * @memberof CareerItemFullRead
     */
    'abilities': Array<CareerFacetFullRead>;
    /**
     * 
     * @type {Array<CareerFacetFullRead>}
     * @memberof CareerItemFullRead
     */
    'skills': Array<CareerFacetFullRead>;
    /**
     * 
     * @type {Array<CareerFacetFullRead>}
     * @memberof CareerItemFullRead
     */
    'activities': Array<CareerFacetFullRead>;
    /**
     * 
     * @type {Array<CareerFacetFullRead>}
     * @memberof CareerItemFullRead
     */
    'tasks': Array<CareerFacetFullRead>;
    /**
     * 
     * @type {number}
     * @memberof CareerItemFullRead
     */
    'outlookStartYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof CareerItemFullRead
     */
    'outlookEndYear'?: number;
}
/**
 * 
 * @export
 * @interface CareerMatchFullRead
 */
export interface CareerMatchFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerMatchFullRead
     */
    'career': string;
    /**
     * 
     * @type {number}
     * @memberof CareerMatchFullRead
     */
    'matchScore': number;
}
/**
 * Career.
 * @export
 * @interface CareerMinimumRead
 */
export interface CareerMinimumRead {
    /**
     * 
     * @type {string}
     * @memberof CareerMinimumRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerMinimumRead
     */
    'onetId': string;
    /**
     * 
     * @type {string}
     * @memberof CareerMinimumRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CareerMinimumRead
     */
    'annualSalary'?: string | null;
    /**
     * 
     * @type {Array<CareerClusterMinimumRead>}
     * @memberof CareerMinimumRead
     */
    'clusters'?: Array<CareerClusterMinimumRead>;
    /**
     * 
     * @type {string}
     * @memberof CareerMinimumRead
     */
    'outlookGrowthPercentage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CareerMinimumRead
     */
    'outlookJobOpenings'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CareerMinimumRead
     */
    'overallOutlook'?: number;
    /**
     * 
     * @type {string}
     * @memberof CareerMinimumRead
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof CareerMinimumRead
     */
    'onetJobZone'?: number | null;
}
/**
 * 
 * @export
 * @interface CareerProgramFullRead
 */
export interface CareerProgramFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerProgramFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerProgramFullRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CareerProgramFullRead
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerProgramFullRead
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CareerProgramMatchFullRead
 */
export interface CareerProgramMatchFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerProgramMatchFullRead
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CareerProgramMatchFullRead
     */
    'matchScore'?: number;
    /**
     * 
     * @type {CareerProgramFullRead}
     * @memberof CareerProgramMatchFullRead
     */
    'careerProgram'?: CareerProgramFullRead;
}
/**
 * CareerRange.
 * @export
 * @interface CareerRangeFullRead
 */
export interface CareerRangeFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerRangeFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerRangeFullRead
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerRangeFullRead
     */
    'type'?: string;
}
/**
 * CareerRange.
 * @export
 * @interface CareerRangeMinimumRead
 */
export interface CareerRangeMinimumRead {
    /**
     * 
     * @type {string}
     * @memberof CareerRangeMinimumRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerRangeMinimumRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CareerRangeMinimumRead
     */
    'type': string;
}
/**
 * CareerRating.
 * @export
 * @interface CareerRatingFullRead
 */
export interface CareerRatingFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerRatingFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerRatingFullRead
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CareerRatingFullRead
     */
    'value': number;
}
/**
 * Class CareerSkillsBaseline.
 * @export
 * @interface CareerSkillsBaselineFullRead
 */
export interface CareerSkillsBaselineFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerSkillsBaselineFullRead
     */
    'career': string;
    /**
     * 
     * @type {MacroQuestionTypeMinimumRead}
     * @memberof CareerSkillsBaselineFullRead
     */
    'macroQuestionType': MacroQuestionTypeMinimumRead;
    /**
     * 
     * @type {number}
     * @memberof CareerSkillsBaselineFullRead
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface CareerUserRatingFullRead
 */
export interface CareerUserRatingFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerUserRatingFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerUserRatingFullRead
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerUserRatingFullRead
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerUserRatingFullRead
     */
    'user': string;
    /**
     * 
     * @type {CareerFullRead}
     * @memberof CareerUserRatingFullRead
     */
    'career': CareerFullRead;
    /**
     * 
     * @type {CareerRatingFullRead}
     * @memberof CareerUserRatingFullRead
     */
    'rating': CareerRatingFullRead;
}
/**
 * 
 * @export
 * @interface CareerUserRatingMinimumWrite
 */
export interface CareerUserRatingMinimumWrite {
    /**
     * 
     * @type {string}
     * @memberof CareerUserRatingMinimumWrite
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof CareerUserRatingMinimumWrite
     */
    'career': string;
    /**
     * 
     * @type {string}
     * @memberof CareerUserRatingMinimumWrite
     */
    'rating': string;
}
/**
 * Class CareerWage.
 * @export
 * @interface CareerWageFullRead
 */
export interface CareerWageFullRead {
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'career': string;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'parentAreaId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'areaType': string;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'areaName': string;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'meanHourlyWage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'meanAverageWage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'hourlyWage10thPercentile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'hourlyWage25thPercentile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'hourlyWage50thPercentile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'hourlyWage75thPercentile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'hourlyWage90thPercentile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'annualWage10thPercentile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'annualWage25thPercentile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'annualWage50thPercentile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'annualWage75thPercentile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareerWageFullRead
     */
    'annualWage90thPercentile'?: string | null;
}
/**
 * 
 * @export
 * @interface ExampleFullRead
 */
export interface ExampleFullRead {
    /**
     * 
     * @type {string}
     * @memberof ExampleFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExampleFullRead
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof ExampleFullRead
     */
    'exampleType': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExampleFullRead
     */
    'tags': Array<string>;
}
/**
 * UserToken
 * @export
 * @interface GetTokenUserItem200Response
 */
export interface GetTokenUserItem200Response {
    /**
     * 
     * @type {string}
     * @memberof GetTokenUserItem200Response
     */
    'token': string;
}
/**
 * Account.
 * @export
 * @interface InstitutionFullRead
 */
export interface InstitutionFullRead {
    /**
     * 
     * @type {string}
     * @memberof InstitutionFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionFullRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionFullRead
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionFullRead
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionFullRead
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionFullRead
     */
    'zipcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionFullRead
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionFullRead
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface MacroQuestionTypeFullRead
 */
export interface MacroQuestionTypeFullRead {
    /**
     * 
     * @type {string}
     * @memberof MacroQuestionTypeFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MacroQuestionTypeFullRead
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MacroQuestionTypeMinimumRead
 */
export interface MacroQuestionTypeMinimumRead {
    /**
     * 
     * @type {string}
     * @memberof MacroQuestionTypeMinimumRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MacroQuestionTypeMinimumRead
     */
    'name': string;
}
/**
 * Major.
 * @export
 * @interface MajorReadMajor
 */
export interface MajorReadMajor {
    /**
     * 
     * @type {string}
     * @memberof MajorReadMajor
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MajorReadMajor
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PersonalitySubTypeFullRead
 */
export interface PersonalitySubTypeFullRead {
    /**
     * Get id.
     * @type {string}
     * @memberof PersonalitySubTypeFullRead
     */
    'id': string;
    /**
     * Get abbrev.
     * @type {string}
     * @memberof PersonalitySubTypeFullRead
     */
    'abbrev': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalitySubTypeFullRead
     */
    'name': string;
}
/**
 * ProductConfiguration.
 * @export
 * @interface ProductConfigurationFullRead
 */
export interface ProductConfigurationFullRead {
    /**
     * 
     * @type {string}
     * @memberof ProductConfigurationFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfigurationFullRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfigurationFullRead
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfigurationFullRead
     */
    'productLogo': string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfigurationFullRead
     */
    'achieveWorksReportType': string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfigurationFullRead
     */
    'assessmentType': string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfigurationFullRead
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfigurationFullRead
     */
    'productSuite': string;
}
/**
 * Question.
 * @export
 * @interface QuestionFullRead
 */
export interface QuestionFullRead {
    /**
     * 
     * @type {string}
     * @memberof QuestionFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionFullRead
     */
    'answerDisplayType': string;
    /**
     * 
     * @type {Array<AnswerMinimumRead>}
     * @memberof QuestionFullRead
     */
    'answers': Array<AnswerMinimumRead>;
    /**
     * 
     * @type {string}
     * @memberof QuestionFullRead
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionFullRead
     */
    'leftText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionFullRead
     */
    'rightText'?: string | null;
}
/**
 * Question.
 * @export
 * @interface QuestionMinimumRead
 */
export interface QuestionMinimumRead {
    /**
     * 
     * @type {string}
     * @memberof QuestionMinimumRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionMinimumRead
     */
    'answerDisplayType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionMinimumRead
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionMinimumRead
     */
    'leftText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionMinimumRead
     */
    'rightText'?: string | null;
}
/**
 * QuestionType.
 * @export
 * @interface QuestionTypeFullRead
 */
export interface QuestionTypeFullRead {
    /**
     * 
     * @type {string}
     * @memberof QuestionTypeFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionTypeFullRead
     */
    'abbrev': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionTypeFullRead
     */
    'name': string;
    /**
     * 
     * @type {PersonalitySubTypeFullRead}
     * @memberof QuestionTypeFullRead
     */
    'leftPersonalitySubType'?: PersonalitySubTypeFullRead;
    /**
     * 
     * @type {PersonalitySubTypeFullRead}
     * @memberof QuestionTypeFullRead
     */
    'rightPersonalitySubType'?: PersonalitySubTypeFullRead;
    /**
     * Get doesPreferText.
     * @type {string}
     * @memberof QuestionTypeFullRead
     */
    'doesPreferText'?: string | null;
    /**
     * Get doesNotPreferText.
     * @type {string}
     * @memberof QuestionTypeFullRead
     */
    'doesNotPreferText'?: string | null;
    /**
     * Get doesPreferDescription.
     * @type {string}
     * @memberof QuestionTypeFullRead
     */
    'doesPreferDescription'?: string | null;
    /**
     * Get doesNotPreferDescription.
     * @type {string}
     * @memberof QuestionTypeFullRead
     */
    'doesNotPreferDescription'?: string | null;
}
/**
 * QuestionType.
 * @export
 * @interface QuestionTypeMinimumRead
 */
export interface QuestionTypeMinimumRead {
    /**
     * 
     * @type {string}
     * @memberof QuestionTypeMinimumRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionTypeMinimumRead
     */
    'name': string;
}
/**
 * Career.
 * @export
 * @interface RelatedCareerRead
 */
export interface RelatedCareerRead {
    /**
     * 
     * @type {string}
     * @memberof RelatedCareerRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RelatedCareerRead
     */
    'onetId': string;
    /**
     * 
     * @type {string}
     * @memberof RelatedCareerRead
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RelatedCareerRead
     */
    'annualSalary'?: string | null;
    /**
     * 
     * @type {Array<CareerClusterMinimumRead>}
     * @memberof RelatedCareerRead
     */
    'clusters'?: Array<CareerClusterMinimumRead>;
    /**
     * 
     * @type {string}
     * @memberof RelatedCareerRead
     */
    'outlookGrowthPercentage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelatedCareerRead
     */
    'outlookJobOpenings'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelatedCareerRead
     */
    'overallOutlook'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelatedCareerRead
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof RelatedCareerRead
     */
    'onetJobZone'?: number | null;
    /**
     * 
     * @type {Array<CareerSkillsBaselineFullRead>}
     * @memberof RelatedCareerRead
     */
    'careerSkillsBaselines': Array<CareerSkillsBaselineFullRead>;
    /**
     * 
     * @type {Array<CareerIntelligencesBaselineFullRead>}
     * @memberof RelatedCareerRead
     */
    'careerIntelligencesBaselines': Array<CareerIntelligencesBaselineFullRead>;
}
/**
 * 
 * @export
 * @interface ReportElementBadgesFullRead
 */
export interface ReportElementBadgesFullRead {
    /**
     * 
     * @type {string}
     * @memberof ReportElementBadgesFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportElementBadgesFullRead
     */
    'achieveWorksReportElement': string;
    /**
     * 
     * @type {Array<AchieveWorksReportBadgeFullRead>}
     * @memberof ReportElementBadgesFullRead
     */
    'achieveWorksReportBadges': Array<AchieveWorksReportBadgeFullRead>;
}
/**
 * 
 * @export
 * @interface ReportElementBadgesMinimumWrite
 */
export interface ReportElementBadgesMinimumWrite {
    /**
     * 
     * @type {string}
     * @memberof ReportElementBadgesMinimumWrite
     */
    'achieveWorksReport': string;
    /**
     * 
     * @type {string}
     * @memberof ReportElementBadgesMinimumWrite
     */
    'achieveWorksReportElement': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportElementBadgesMinimumWrite
     */
    'achieveWorksReportBadges': Array<string>;
}
/**
 * 
 * @export
 * @interface ReportElementRatingFullRead
 */
export interface ReportElementRatingFullRead {
    /**
     * 
     * @type {string}
     * @memberof ReportElementRatingFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportElementRatingFullRead
     */
    'achieveWorksReportElement': string;
    /**
     * 
     * @type {AchieveWorksReportRatingFullRead}
     * @memberof ReportElementRatingFullRead
     */
    'achieveWorksReportRating': AchieveWorksReportRatingFullRead;
}
/**
 * 
 * @export
 * @interface ReportElementRatingMinimumWrite
 */
export interface ReportElementRatingMinimumWrite {
    /**
     * 
     * @type {string}
     * @memberof ReportElementRatingMinimumWrite
     */
    'achieveWorksReport': string;
    /**
     * 
     * @type {string}
     * @memberof ReportElementRatingMinimumWrite
     */
    'achieveWorksReportElement': string;
    /**
     * 
     * @type {string}
     * @memberof ReportElementRatingMinimumWrite
     */
    'achieveWorksReportRating': string;
}
/**
 * AssessmentReport.
 * @export
 * @interface ReportFullRead
 */
export interface ReportFullRead {
    /**
     * 
     * @type {string}
     * @memberof ReportFullRead
     */
    'id': string;
    /**
     * 
     * @type {AssessmentFullRead}
     * @memberof ReportFullRead
     */
    'assessment'?: AssessmentFullRead;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportFullRead
     */
    'content'?: Array<string>;
    /**
     * 
     * @type {Array<CareerClusterFullRead>}
     * @memberof ReportFullRead
     */
    'careerClusters'?: Array<CareerClusterFullRead>;
}
/**
 * ReportScoreRange.
 * @export
 * @interface ReportScoreRange
 */
export interface ReportScoreRange {
    /**
     * 
     * @type {string}
     * @memberof ReportScoreRange
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportScoreRange
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportScoreRange
     */
    'max'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportScoreRange
     */
    'keyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportScoreRange
     */
    'assessmentType'?: string | null;
}
/**
 * ReportScoreRange.
 * @export
 * @interface ReportScoreRangeFullRead
 */
export interface ReportScoreRangeFullRead {
    /**
     * 
     * @type {string}
     * @memberof ReportScoreRangeFullRead
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportScoreRangeFullRead
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportScoreRangeFullRead
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface ReportTypeElementBadgeRequirementsFullRead
 */
export interface ReportTypeElementBadgeRequirementsFullRead {
    /**
     * 
     * @type {string}
     * @memberof ReportTypeElementBadgeRequirementsFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportTypeElementBadgeRequirementsFullRead
     */
    'badgeDisplayType': string;
    /**
     * 
     * @type {string}
     * @memberof ReportTypeElementBadgeRequirementsFullRead
     */
    'badgeEarnType': string;
    /**
     * 
     * @type {string}
     * @memberof ReportTypeElementBadgeRequirementsFullRead
     */
    'achieveWorksReportType': string;
    /**
     * 
     * @type {string}
     * @memberof ReportTypeElementBadgeRequirementsFullRead
     */
    'achieveWorksReportElement': string;
    /**
     * 
     * @type {string}
     * @memberof ReportTypeElementBadgeRequirementsFullRead
     */
    'achieveWorksReportBadge': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportTypeElementBadgeRequirementsFullRead
     */
    'achieveWorksReportElementBadgesRequired': Array<string>;
}
/**
 * 
 * @export
 * @interface ReportUserAnswerFullRead
 */
export interface ReportUserAnswerFullRead {
    /**
     * 
     * @type {string}
     * @memberof ReportUserAnswerFullRead
     */
    'id': string;
    /**
     * 
     * @type {AchieveWorksReportQuestionFullRead}
     * @memberof ReportUserAnswerFullRead
     */
    'achieveWorksReportQuestion': AchieveWorksReportQuestionFullRead;
    /**
     * 
     * @type {Array<AchieveWorksReportAnswerFullRead>}
     * @memberof ReportUserAnswerFullRead
     */
    'achieveWorksReportAnswers': Array<AchieveWorksReportAnswerFullRead>;
}
/**
 * 
 * @export
 * @interface ReportUserAnswerMinimumWrite
 */
export interface ReportUserAnswerMinimumWrite {
    /**
     * 
     * @type {string}
     * @memberof ReportUserAnswerMinimumWrite
     */
    'achieveWorksReport': string;
    /**
     * 
     * @type {string}
     * @memberof ReportUserAnswerMinimumWrite
     */
    'achieveWorksReportQuestion': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportUserAnswerMinimumWrite
     */
    'achieveWorksReportAnswers': Array<string>;
}
/**
 * 
 * @export
 * @interface UserAnswerFullRead
 */
export interface UserAnswerFullRead {
    /**
     * 
     * @type {string}
     * @memberof UserAnswerFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAnswerFullRead
     */
    'createdAt': string;
    /**
     * 
     * @type {AnswerFullRead}
     * @memberof UserAnswerFullRead
     */
    'answer': AnswerFullRead;
}
/**
 * 
 * @export
 * @interface UserAnswerMinimumWrite
 */
export interface UserAnswerMinimumWrite {
    /**
     * 
     * @type {string}
     * @memberof UserAnswerMinimumWrite
     */
    'assessment': string;
    /**
     * 
     * @type {string}
     * @memberof UserAnswerMinimumWrite
     */
    'answer': string;
}
/**
 * 
 * @export
 * @interface UserFullRead
 */
export interface UserFullRead {
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'lastLoggedInAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserFullRead
     */
    'isUnder13': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'customData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'externalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFullRead
     */
    'institution': string;
    /**
     * Set gender.
     * @type {string}
     * @memberof UserFullRead
     */
    'gender'?: string | null;
}
/**
 * 
 * @export
 * @interface UserMinimumWrite
 */
export interface UserMinimumWrite {
    /**
     * 
     * @type {string}
     * @memberof UserMinimumWrite
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserMinimumWrite
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMinimumWrite
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserMinimumWrite
     */
    'isUnder13': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserMinimumWrite
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMinimumWrite
     */
    'customData': string;
    /**
     * 
     * @type {string}
     * @memberof UserMinimumWrite
     */
    'externalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMinimumWrite
     */
    'institution'?: string | null;
    /**
     * Set gender.
     * @type {string}
     * @memberof UserMinimumWrite
     */
    'gender'?: string | null;
}

/**
 * AchieveWorksReportApi - axios parameter creator
 * @export
 */
export const AchieveWorksReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of AchieveWorksReport resources.
         * @summary Retrieves the collection of AchieveWorksReport resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/achieve_works_reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAchieveWorksReportItem', 'id', id)
            const localVarPath = `/api/achieve_works_reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerProgramMatchesByReportAchieveWorksReportItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerProgramMatchesByReportAchieveWorksReportItem', 'id', id)
            const localVarPath = `/api/achieve_works_reports/{id}/career_program_matches`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareersByReportAchieveWorksReportItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareersByReportAchieveWorksReportItem', 'id', id)
            const localVarPath = `/api/achieve_works_reports/{id}/careers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportElementBadgesByReportAchieveWorksReportItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportElementBadgesByReportAchieveWorksReportItem', 'id', id)
            const localVarPath = `/api/achieve_works_reports/{id}/report_element_badges`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportElementRatingsByReportAchieveWorksReportItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportElementRatingsByReportAchieveWorksReportItem', 'id', id)
            const localVarPath = `/api/achieve_works_reports/{id}/report_element_ratings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportUserAnswersByReportAchieveWorksReportItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportUserAnswersByReportAchieveWorksReportItem', 'id', id)
            const localVarPath = `/api/achieve_works_reports/{id}/report_user_answers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AchieveWorksReportApi - functional programming interface
 * @export
 */
export const AchieveWorksReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AchieveWorksReportApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of AchieveWorksReport resources.
         * @summary Retrieves the collection of AchieveWorksReport resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAchieveWorksReportCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AchieveWorksReportMinimumRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAchieveWorksReportCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportApi.getAchieveWorksReportCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchieveWorksReportFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAchieveWorksReportItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportApi.getAchieveWorksReportItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerProgramMatchesByReportAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerProgramMatchesByReportAchieveWorksReportItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportApi.getCareerProgramMatchesByReportAchieveWorksReportItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareersByReportAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareersByReportAchieveWorksReportItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportApi.getCareersByReportAchieveWorksReportItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportElementBadgesByReportAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportElementBadgesFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportElementBadgesByReportAchieveWorksReportItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportApi.getReportElementBadgesByReportAchieveWorksReportItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportElementRatingsByReportAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportElementRatingFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportElementRatingsByReportAchieveWorksReportItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportApi.getReportElementRatingsByReportAchieveWorksReportItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportUserAnswersByReportAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportUserAnswerFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportUserAnswersByReportAchieveWorksReportItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportApi.getReportUserAnswersByReportAchieveWorksReportItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AchieveWorksReportApi - factory interface
 * @export
 */
export const AchieveWorksReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AchieveWorksReportApiFp(configuration)
    return {
        /**
         * Retrieves the collection of AchieveWorksReport resources.
         * @summary Retrieves the collection of AchieveWorksReport resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<AchieveWorksReportMinimumRead>> {
            return localVarFp.getAchieveWorksReportCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportItem(id: string, options?: any): AxiosPromise<AchieveWorksReportFullRead> {
            return localVarFp.getAchieveWorksReportItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerProgramMatchesByReportAchieveWorksReportItem(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.getCareerProgramMatchesByReportAchieveWorksReportItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareersByReportAchieveWorksReportItem(id: string, options?: any): AxiosPromise<Array<CareerFullRead>> {
            return localVarFp.getCareersByReportAchieveWorksReportItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportElementBadgesByReportAchieveWorksReportItem(id: string, options?: any): AxiosPromise<Array<ReportElementBadgesFullRead>> {
            return localVarFp.getReportElementBadgesByReportAchieveWorksReportItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportElementRatingsByReportAchieveWorksReportItem(id: string, options?: any): AxiosPromise<Array<ReportElementRatingFullRead>> {
            return localVarFp.getReportElementRatingsByReportAchieveWorksReportItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a AchieveWorksReport resource.
         * @summary Retrieves a AchieveWorksReport resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportUserAnswersByReportAchieveWorksReportItem(id: string, options?: any): AxiosPromise<Array<ReportUserAnswerFullRead>> {
            return localVarFp.getReportUserAnswersByReportAchieveWorksReportItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AchieveWorksReportApi - object-oriented interface
 * @export
 * @class AchieveWorksReportApi
 * @extends {BaseAPI}
 */
export class AchieveWorksReportApi extends BaseAPI {
    /**
     * Retrieves the collection of AchieveWorksReport resources.
     * @summary Retrieves the collection of AchieveWorksReport resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportApi
     */
    public getAchieveWorksReportCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportApiFp(this.configuration).getAchieveWorksReportCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a AchieveWorksReport resource.
     * @summary Retrieves a AchieveWorksReport resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportApi
     */
    public getAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportApiFp(this.configuration).getAchieveWorksReportItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a AchieveWorksReport resource.
     * @summary Retrieves a AchieveWorksReport resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportApi
     */
    public getCareerProgramMatchesByReportAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportApiFp(this.configuration).getCareerProgramMatchesByReportAchieveWorksReportItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a AchieveWorksReport resource.
     * @summary Retrieves a AchieveWorksReport resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportApi
     */
    public getCareersByReportAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportApiFp(this.configuration).getCareersByReportAchieveWorksReportItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a AchieveWorksReport resource.
     * @summary Retrieves a AchieveWorksReport resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportApi
     */
    public getReportElementBadgesByReportAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportApiFp(this.configuration).getReportElementBadgesByReportAchieveWorksReportItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a AchieveWorksReport resource.
     * @summary Retrieves a AchieveWorksReport resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportApi
     */
    public getReportElementRatingsByReportAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportApiFp(this.configuration).getReportElementRatingsByReportAchieveWorksReportItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a AchieveWorksReport resource.
     * @summary Retrieves a AchieveWorksReport resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportApi
     */
    public getReportUserAnswersByReportAchieveWorksReportItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportApiFp(this.configuration).getReportUserAnswersByReportAchieveWorksReportItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AchieveWorksReportAnswerApi - axios parameter creator
 * @export
 */
export const AchieveWorksReportAnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a AchieveWorksReportAnswer resource.
         * @summary Retrieves a AchieveWorksReportAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportAnswerItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAchieveWorksReportAnswerItem', 'id', id)
            const localVarPath = `/api/achieve_works_report_answers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AchieveWorksReportAnswerApi - functional programming interface
 * @export
 */
export const AchieveWorksReportAnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AchieveWorksReportAnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a AchieveWorksReportAnswer resource.
         * @summary Retrieves a AchieveWorksReportAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAchieveWorksReportAnswerItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchieveWorksReportAnswerFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAchieveWorksReportAnswerItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportAnswerApi.getAchieveWorksReportAnswerItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AchieveWorksReportAnswerApi - factory interface
 * @export
 */
export const AchieveWorksReportAnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AchieveWorksReportAnswerApiFp(configuration)
    return {
        /**
         * Retrieves a AchieveWorksReportAnswer resource.
         * @summary Retrieves a AchieveWorksReportAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportAnswerItem(id: string, options?: any): AxiosPromise<AchieveWorksReportAnswerFullRead> {
            return localVarFp.getAchieveWorksReportAnswerItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AchieveWorksReportAnswerApi - object-oriented interface
 * @export
 * @class AchieveWorksReportAnswerApi
 * @extends {BaseAPI}
 */
export class AchieveWorksReportAnswerApi extends BaseAPI {
    /**
     * Retrieves a AchieveWorksReportAnswer resource.
     * @summary Retrieves a AchieveWorksReportAnswer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportAnswerApi
     */
    public getAchieveWorksReportAnswerItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportAnswerApiFp(this.configuration).getAchieveWorksReportAnswerItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AchieveWorksReportBadgeApi - axios parameter creator
 * @export
 */
export const AchieveWorksReportBadgeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of AchieveWorksReportBadge resources.
         * @summary Retrieves the collection of AchieveWorksReportBadge resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportBadgeCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/achieve_works_report_badges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a AchieveWorksReportBadge resource.
         * @summary Retrieves a AchieveWorksReportBadge resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportBadgeItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAchieveWorksReportBadgeItem', 'id', id)
            const localVarPath = `/api/achieve_works_report_badges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AchieveWorksReportBadgeApi - functional programming interface
 * @export
 */
export const AchieveWorksReportBadgeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AchieveWorksReportBadgeApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of AchieveWorksReportBadge resources.
         * @summary Retrieves the collection of AchieveWorksReportBadge resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAchieveWorksReportBadgeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AchieveWorksReportBadgeFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAchieveWorksReportBadgeCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportBadgeApi.getAchieveWorksReportBadgeCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a AchieveWorksReportBadge resource.
         * @summary Retrieves a AchieveWorksReportBadge resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAchieveWorksReportBadgeItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchieveWorksReportBadgeFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAchieveWorksReportBadgeItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportBadgeApi.getAchieveWorksReportBadgeItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AchieveWorksReportBadgeApi - factory interface
 * @export
 */
export const AchieveWorksReportBadgeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AchieveWorksReportBadgeApiFp(configuration)
    return {
        /**
         * Retrieves the collection of AchieveWorksReportBadge resources.
         * @summary Retrieves the collection of AchieveWorksReportBadge resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportBadgeCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<AchieveWorksReportBadgeFullRead>> {
            return localVarFp.getAchieveWorksReportBadgeCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a AchieveWorksReportBadge resource.
         * @summary Retrieves a AchieveWorksReportBadge resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportBadgeItem(id: string, options?: any): AxiosPromise<AchieveWorksReportBadgeFullRead> {
            return localVarFp.getAchieveWorksReportBadgeItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AchieveWorksReportBadgeApi - object-oriented interface
 * @export
 * @class AchieveWorksReportBadgeApi
 * @extends {BaseAPI}
 */
export class AchieveWorksReportBadgeApi extends BaseAPI {
    /**
     * Retrieves the collection of AchieveWorksReportBadge resources.
     * @summary Retrieves the collection of AchieveWorksReportBadge resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportBadgeApi
     */
    public getAchieveWorksReportBadgeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportBadgeApiFp(this.configuration).getAchieveWorksReportBadgeCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a AchieveWorksReportBadge resource.
     * @summary Retrieves a AchieveWorksReportBadge resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportBadgeApi
     */
    public getAchieveWorksReportBadgeItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportBadgeApiFp(this.configuration).getAchieveWorksReportBadgeItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AchieveWorksReportElementApi - axios parameter creator
 * @export
 */
export const AchieveWorksReportElementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a AchieveWorksReportElement resource.
         * @summary Retrieves a AchieveWorksReportElement resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportElementItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAchieveWorksReportElementItem', 'id', id)
            const localVarPath = `/api/achieve_works_report_elements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AchieveWorksReportElementApi - functional programming interface
 * @export
 */
export const AchieveWorksReportElementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AchieveWorksReportElementApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a AchieveWorksReportElement resource.
         * @summary Retrieves a AchieveWorksReportElement resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAchieveWorksReportElementItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchieveWorksReportElementFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAchieveWorksReportElementItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportElementApi.getAchieveWorksReportElementItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AchieveWorksReportElementApi - factory interface
 * @export
 */
export const AchieveWorksReportElementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AchieveWorksReportElementApiFp(configuration)
    return {
        /**
         * Retrieves a AchieveWorksReportElement resource.
         * @summary Retrieves a AchieveWorksReportElement resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportElementItem(id: string, options?: any): AxiosPromise<AchieveWorksReportElementFullRead> {
            return localVarFp.getAchieveWorksReportElementItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AchieveWorksReportElementApi - object-oriented interface
 * @export
 * @class AchieveWorksReportElementApi
 * @extends {BaseAPI}
 */
export class AchieveWorksReportElementApi extends BaseAPI {
    /**
     * Retrieves a AchieveWorksReportElement resource.
     * @summary Retrieves a AchieveWorksReportElement resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportElementApi
     */
    public getAchieveWorksReportElementItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportElementApiFp(this.configuration).getAchieveWorksReportElementItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AchieveWorksReportQuestionApi - axios parameter creator
 * @export
 */
export const AchieveWorksReportQuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a AchieveWorksReportQuestion resource.
         * @summary Retrieves a AchieveWorksReportQuestion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportQuestionItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAchieveWorksReportQuestionItem', 'id', id)
            const localVarPath = `/api/achieve_works_report_questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AchieveWorksReportQuestionApi - functional programming interface
 * @export
 */
export const AchieveWorksReportQuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AchieveWorksReportQuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a AchieveWorksReportQuestion resource.
         * @summary Retrieves a AchieveWorksReportQuestion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAchieveWorksReportQuestionItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchieveWorksReportQuestionFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAchieveWorksReportQuestionItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportQuestionApi.getAchieveWorksReportQuestionItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AchieveWorksReportQuestionApi - factory interface
 * @export
 */
export const AchieveWorksReportQuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AchieveWorksReportQuestionApiFp(configuration)
    return {
        /**
         * Retrieves a AchieveWorksReportQuestion resource.
         * @summary Retrieves a AchieveWorksReportQuestion resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportQuestionItem(id: string, options?: any): AxiosPromise<AchieveWorksReportQuestionFullRead> {
            return localVarFp.getAchieveWorksReportQuestionItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AchieveWorksReportQuestionApi - object-oriented interface
 * @export
 * @class AchieveWorksReportQuestionApi
 * @extends {BaseAPI}
 */
export class AchieveWorksReportQuestionApi extends BaseAPI {
    /**
     * Retrieves a AchieveWorksReportQuestion resource.
     * @summary Retrieves a AchieveWorksReportQuestion resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportQuestionApi
     */
    public getAchieveWorksReportQuestionItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportQuestionApiFp(this.configuration).getAchieveWorksReportQuestionItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AchieveWorksReportRatingApi - axios parameter creator
 * @export
 */
export const AchieveWorksReportRatingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of AchieveWorksReportRating resources.
         * @summary Retrieves the collection of AchieveWorksReportRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportRatingCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/achieve_works_report_ratings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a AchieveWorksReportRating resource.
         * @summary Retrieves a AchieveWorksReportRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportRatingItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAchieveWorksReportRatingItem', 'id', id)
            const localVarPath = `/api/achieve_works_report_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AchieveWorksReportRatingApi - functional programming interface
 * @export
 */
export const AchieveWorksReportRatingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AchieveWorksReportRatingApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of AchieveWorksReportRating resources.
         * @summary Retrieves the collection of AchieveWorksReportRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAchieveWorksReportRatingCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AchieveWorksReportRatingFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAchieveWorksReportRatingCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportRatingApi.getAchieveWorksReportRatingCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a AchieveWorksReportRating resource.
         * @summary Retrieves a AchieveWorksReportRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAchieveWorksReportRatingItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchieveWorksReportRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAchieveWorksReportRatingItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AchieveWorksReportRatingApi.getAchieveWorksReportRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AchieveWorksReportRatingApi - factory interface
 * @export
 */
export const AchieveWorksReportRatingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AchieveWorksReportRatingApiFp(configuration)
    return {
        /**
         * Retrieves the collection of AchieveWorksReportRating resources.
         * @summary Retrieves the collection of AchieveWorksReportRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportRatingCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<AchieveWorksReportRatingFullRead>> {
            return localVarFp.getAchieveWorksReportRatingCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a AchieveWorksReportRating resource.
         * @summary Retrieves a AchieveWorksReportRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchieveWorksReportRatingItem(id: string, options?: any): AxiosPromise<AchieveWorksReportRatingFullRead> {
            return localVarFp.getAchieveWorksReportRatingItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AchieveWorksReportRatingApi - object-oriented interface
 * @export
 * @class AchieveWorksReportRatingApi
 * @extends {BaseAPI}
 */
export class AchieveWorksReportRatingApi extends BaseAPI {
    /**
     * Retrieves the collection of AchieveWorksReportRating resources.
     * @summary Retrieves the collection of AchieveWorksReportRating resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportRatingApi
     */
    public getAchieveWorksReportRatingCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportRatingApiFp(this.configuration).getAchieveWorksReportRatingCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a AchieveWorksReportRating resource.
     * @summary Retrieves a AchieveWorksReportRating resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchieveWorksReportRatingApi
     */
    public getAchieveWorksReportRatingItem(id: string, options?: RawAxiosRequestConfig) {
        return AchieveWorksReportRatingApiFp(this.configuration).getAchieveWorksReportRatingItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ActivityTypeApi - axios parameter creator
 * @export
 */
export const ActivityTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a ActivityType resource.
         * @summary Retrieves a ActivityType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityTypeItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityTypeItem', 'id', id)
            const localVarPath = `/api/activity_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityTypeApi - functional programming interface
 * @export
 */
export const ActivityTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a ActivityType resource.
         * @summary Retrieves a ActivityType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityTypeItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityTypeFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityTypeItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityTypeApi.getActivityTypeItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActivityTypeApi - factory interface
 * @export
 */
export const ActivityTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityTypeApiFp(configuration)
    return {
        /**
         * Retrieves a ActivityType resource.
         * @summary Retrieves a ActivityType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityTypeItem(id: string, options?: any): AxiosPromise<ActivityTypeFullRead> {
            return localVarFp.getActivityTypeItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityTypeApi - object-oriented interface
 * @export
 * @class ActivityTypeApi
 * @extends {BaseAPI}
 */
export class ActivityTypeApi extends BaseAPI {
    /**
     * Retrieves a ActivityType resource.
     * @summary Retrieves a ActivityType resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityTypeApi
     */
    public getActivityTypeItem(id: string, options?: RawAxiosRequestConfig) {
        return ActivityTypeApiFp(this.configuration).getActivityTypeItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AnswerApi - axios parameter creator
 * @export
 */
export const AnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Question resource.
         * @summary Retrieves a Question resource.
         * @param {string} id Question identifier
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsAnswersGetSubresourceQuestionSubresource: async (id: string, page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiQuestionsAnswersGetSubresourceQuestionSubresource', 'id', id)
            const localVarPath = `/api/questions/{id}/answers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Answer resources.
         * @summary Retrieves the collection of Answer resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnswerCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Answer resource.
         * @summary Retrieves a Answer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnswerItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnswerItem', 'id', id)
            const localVarPath = `/api/answers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnswerApi - functional programming interface
 * @export
 */
export const AnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a Question resource.
         * @summary Retrieves a Question resource.
         * @param {string} id Question identifier
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsAnswersGetSubresourceQuestionSubresource(id: string, page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnswerMinimumRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsAnswersGetSubresourceQuestionSubresource(id, page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnswerApi.apiQuestionsAnswersGetSubresourceQuestionSubresource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the collection of Answer resources.
         * @summary Retrieves the collection of Answer resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnswerCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnswerFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnswerCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnswerApi.getAnswerCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Answer resource.
         * @summary Retrieves a Answer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnswerItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnswerFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnswerItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnswerApi.getAnswerItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AnswerApi - factory interface
 * @export
 */
export const AnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnswerApiFp(configuration)
    return {
        /**
         * Retrieves a Question resource.
         * @summary Retrieves a Question resource.
         * @param {string} id Question identifier
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsAnswersGetSubresourceQuestionSubresource(id: string, page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<AnswerMinimumRead>> {
            return localVarFp.apiQuestionsAnswersGetSubresourceQuestionSubresource(id, page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Answer resources.
         * @summary Retrieves the collection of Answer resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnswerCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<AnswerFullRead>> {
            return localVarFp.getAnswerCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Answer resource.
         * @summary Retrieves a Answer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnswerItem(id: string, options?: any): AxiosPromise<AnswerFullRead> {
            return localVarFp.getAnswerItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnswerApi - object-oriented interface
 * @export
 * @class AnswerApi
 * @extends {BaseAPI}
 */
export class AnswerApi extends BaseAPI {
    /**
     * Retrieves a Question resource.
     * @summary Retrieves a Question resource.
     * @param {string} id Question identifier
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnswerApi
     */
    public apiQuestionsAnswersGetSubresourceQuestionSubresource(id: string, page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return AnswerApiFp(this.configuration).apiQuestionsAnswersGetSubresourceQuestionSubresource(id, page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Answer resources.
     * @summary Retrieves the collection of Answer resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnswerApi
     */
    public getAnswerCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return AnswerApiFp(this.configuration).getAnswerCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Answer resource.
     * @summary Retrieves a Answer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnswerApi
     */
    public getAnswerItem(id: string, options?: RawAxiosRequestConfig) {
        return AnswerApiFp(this.configuration).getAnswerItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssessmentApi - axios parameter creator
 * @export
 */
export const AssessmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAssessmentsGetSubresourceUserSubresource: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersAssessmentsGetSubresourceUserSubresource', 'id', id)
            const localVarPath = `/api/users/{id}/assessments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Assessment resource.
         * @summary Replaces the Assessment resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Assessment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAssessmentAssessmentItem: async (id: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calculateAssessmentAssessmentItem', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('calculateAssessmentAssessmentItem', 'body', body)
            const localVarPath = `/api/assessments/{id}/calculate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Assessment resource.
         * @summary Removes the Assessment resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAssessmentItem', 'id', id)
            const localVarPath = `/api/assessments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Assessment resources.
         * @summary Retrieves the collection of Assessment resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {number} [id] 
         * @param {Array<number>} [id2] 
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCollection: async (page?: number, pagination?: boolean, id?: number, id2?: Array<number>, user?: string, user2?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/assessments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (user2) {
                localVarQueryParameter['user[]'] = user2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Assessment resource.
         * @summary Retrieves a Assessment resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssessmentItem', 'id', id)
            const localVarPath = `/api/assessments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Assessment resource.
         * @summary Updates the Assessment resource.
         * @param {string} id Resource identifier
         * @param {AssessmentMinimumPatch} assessmentMinimumPatch The updated Assessment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAssessmentItem: async (id: string, assessmentMinimumPatch: AssessmentMinimumPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchAssessmentItem', 'id', id)
            // verify required parameter 'assessmentMinimumPatch' is not null or undefined
            assertParamExists('patchAssessmentItem', 'assessmentMinimumPatch', assessmentMinimumPatch)
            const localVarPath = `/api/assessments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assessmentMinimumPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Assessment resource.
         * @summary Creates a Assessment resource.
         * @param {AssessmentMinimumWrite} assessmentMinimumWrite The new Assessment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startAssessmentAssessmentCollection: async (assessmentMinimumWrite: AssessmentMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentMinimumWrite' is not null or undefined
            assertParamExists('startAssessmentAssessmentCollection', 'assessmentMinimumWrite', assessmentMinimumWrite)
            const localVarPath = `/api/assessments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assessmentMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssessmentApi - functional programming interface
 * @export
 */
export const AssessmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssessmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersAssessmentsGetSubresourceUserSubresource(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentMinimumRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersAssessmentsGetSubresourceUserSubresource(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.apiUsersAssessmentsGetSubresourceUserSubresource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Assessment resource.
         * @summary Replaces the Assessment resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Assessment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateAssessmentAssessmentItem(id: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateAssessmentAssessmentItem(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.calculateAssessmentAssessmentItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the Assessment resource.
         * @summary Removes the Assessment resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssessmentItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssessmentItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.deleteAssessmentItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the collection of Assessment resources.
         * @summary Retrieves the collection of Assessment resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {number} [id] 
         * @param {Array<number>} [id2] 
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentCollection(page?: number, pagination?: boolean, id?: number, id2?: Array<number>, user?: string, user2?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentMinimumRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentCollection(page, pagination, id, id2, user, user2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.getAssessmentCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Assessment resource.
         * @summary Retrieves a Assessment resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.getAssessmentItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the Assessment resource.
         * @summary Updates the Assessment resource.
         * @param {string} id Resource identifier
         * @param {AssessmentMinimumPatch} assessmentMinimumPatch The updated Assessment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAssessmentItem(id: string, assessmentMinimumPatch: AssessmentMinimumPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentMinimumRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAssessmentItem(id, assessmentMinimumPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.patchAssessmentItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Assessment resource.
         * @summary Creates a Assessment resource.
         * @param {AssessmentMinimumWrite} assessmentMinimumWrite The new Assessment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startAssessmentAssessmentCollection(assessmentMinimumWrite: AssessmentMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startAssessmentAssessmentCollection(assessmentMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.startAssessmentAssessmentCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssessmentApi - factory interface
 * @export
 */
export const AssessmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssessmentApiFp(configuration)
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAssessmentsGetSubresourceUserSubresource(id: string, options?: any): AxiosPromise<Array<AssessmentMinimumRead>> {
            return localVarFp.apiUsersAssessmentsGetSubresourceUserSubresource(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Assessment resource.
         * @summary Replaces the Assessment resource.
         * @param {string} id Resource identifier
         * @param {object} body The updated Assessment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAssessmentAssessmentItem(id: string, body: object, options?: any): AxiosPromise<AssessmentFullRead> {
            return localVarFp.calculateAssessmentAssessmentItem(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the Assessment resource.
         * @summary Removes the Assessment resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAssessmentItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Assessment resources.
         * @summary Retrieves the collection of Assessment resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {number} [id] 
         * @param {Array<number>} [id2] 
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCollection(page?: number, pagination?: boolean, id?: number, id2?: Array<number>, user?: string, user2?: Array<string>, options?: any): AxiosPromise<Array<AssessmentMinimumRead>> {
            return localVarFp.getAssessmentCollection(page, pagination, id, id2, user, user2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Assessment resource.
         * @summary Retrieves a Assessment resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentItem(id: string, options?: any): AxiosPromise<AssessmentFullRead> {
            return localVarFp.getAssessmentItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Assessment resource.
         * @summary Updates the Assessment resource.
         * @param {string} id Resource identifier
         * @param {AssessmentMinimumPatch} assessmentMinimumPatch The updated Assessment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAssessmentItem(id: string, assessmentMinimumPatch: AssessmentMinimumPatch, options?: any): AxiosPromise<AssessmentMinimumRead> {
            return localVarFp.patchAssessmentItem(id, assessmentMinimumPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Assessment resource.
         * @summary Creates a Assessment resource.
         * @param {AssessmentMinimumWrite} assessmentMinimumWrite The new Assessment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startAssessmentAssessmentCollection(assessmentMinimumWrite: AssessmentMinimumWrite, options?: any): AxiosPromise<AssessmentFullRead> {
            return localVarFp.startAssessmentAssessmentCollection(assessmentMinimumWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssessmentApi - object-oriented interface
 * @export
 * @class AssessmentApi
 * @extends {BaseAPI}
 */
export class AssessmentApi extends BaseAPI {
    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id User identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public apiUsersAssessmentsGetSubresourceUserSubresource(id: string, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).apiUsersAssessmentsGetSubresourceUserSubresource(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Assessment resource.
     * @summary Replaces the Assessment resource.
     * @param {string} id Resource identifier
     * @param {object} body The updated Assessment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public calculateAssessmentAssessmentItem(id: string, body: object, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).calculateAssessmentAssessmentItem(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Assessment resource.
     * @summary Removes the Assessment resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public deleteAssessmentItem(id: string, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).deleteAssessmentItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Assessment resources.
     * @summary Retrieves the collection of Assessment resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {number} [id] 
     * @param {Array<number>} [id2] 
     * @param {string} [user] 
     * @param {Array<string>} [user2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public getAssessmentCollection(page?: number, pagination?: boolean, id?: number, id2?: Array<number>, user?: string, user2?: Array<string>, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).getAssessmentCollection(page, pagination, id, id2, user, user2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Assessment resource.
     * @summary Retrieves a Assessment resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public getAssessmentItem(id: string, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).getAssessmentItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Assessment resource.
     * @summary Updates the Assessment resource.
     * @param {string} id Resource identifier
     * @param {AssessmentMinimumPatch} assessmentMinimumPatch The updated Assessment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public patchAssessmentItem(id: string, assessmentMinimumPatch: AssessmentMinimumPatch, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).patchAssessmentItem(id, assessmentMinimumPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Assessment resource.
     * @summary Creates a Assessment resource.
     * @param {AssessmentMinimumWrite} assessmentMinimumWrite The new Assessment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public startAssessmentAssessmentCollection(assessmentMinimumWrite: AssessmentMinimumWrite, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).startAssessmentAssessmentCollection(assessmentMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssessmentCareerMatchesApi - axios parameter creator
 * @export
 */
export const AssessmentCareerMatchesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of AssessmentCareerMatches resources.
         * @summary Retrieves the collection of AssessmentCareerMatches resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCareerMatchesCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/assessment_career_matches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a AssessmentCareerMatches resource.
         * @summary Retrieves a AssessmentCareerMatches resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCareerMatchesItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssessmentCareerMatchesItem', 'id', id)
            const localVarPath = `/api/assessment_career_matches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssessmentCareerMatchesApi - functional programming interface
 * @export
 */
export const AssessmentCareerMatchesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssessmentCareerMatchesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of AssessmentCareerMatches resources.
         * @summary Retrieves the collection of AssessmentCareerMatches resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentCareerMatchesCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentCareerMatchesFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentCareerMatchesCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentCareerMatchesApi.getAssessmentCareerMatchesCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a AssessmentCareerMatches resource.
         * @summary Retrieves a AssessmentCareerMatches resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentCareerMatchesItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentCareerMatchesFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentCareerMatchesItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentCareerMatchesApi.getAssessmentCareerMatchesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssessmentCareerMatchesApi - factory interface
 * @export
 */
export const AssessmentCareerMatchesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssessmentCareerMatchesApiFp(configuration)
    return {
        /**
         * Retrieves the collection of AssessmentCareerMatches resources.
         * @summary Retrieves the collection of AssessmentCareerMatches resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCareerMatchesCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<AssessmentCareerMatchesFullRead>> {
            return localVarFp.getAssessmentCareerMatchesCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a AssessmentCareerMatches resource.
         * @summary Retrieves a AssessmentCareerMatches resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCareerMatchesItem(id: string, options?: any): AxiosPromise<AssessmentCareerMatchesFullRead> {
            return localVarFp.getAssessmentCareerMatchesItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssessmentCareerMatchesApi - object-oriented interface
 * @export
 * @class AssessmentCareerMatchesApi
 * @extends {BaseAPI}
 */
export class AssessmentCareerMatchesApi extends BaseAPI {
    /**
     * Retrieves the collection of AssessmentCareerMatches resources.
     * @summary Retrieves the collection of AssessmentCareerMatches resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCareerMatchesApi
     */
    public getAssessmentCareerMatchesCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return AssessmentCareerMatchesApiFp(this.configuration).getAssessmentCareerMatchesCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a AssessmentCareerMatches resource.
     * @summary Retrieves a AssessmentCareerMatches resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCareerMatchesApi
     */
    public getAssessmentCareerMatchesItem(id: string, options?: RawAxiosRequestConfig) {
        return AssessmentCareerMatchesApiFp(this.configuration).getAssessmentCareerMatchesItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssessmentTypeApi - axios parameter creator
 * @export
 */
export const AssessmentTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of AssessmentType resources.
         * @summary Retrieves the collection of AssessmentType resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentTypeCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/assessment_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a AssessmentType resource.
         * @summary Retrieves a AssessmentType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentTypeItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssessmentTypeItem', 'id', id)
            const localVarPath = `/api/assessment_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssessmentTypeApi - functional programming interface
 * @export
 */
export const AssessmentTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssessmentTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of AssessmentType resources.
         * @summary Retrieves the collection of AssessmentType resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentTypeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentTypeFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentTypeCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentTypeApi.getAssessmentTypeCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a AssessmentType resource.
         * @summary Retrieves a AssessmentType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentTypeItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentTypeFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentTypeItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentTypeApi.getAssessmentTypeItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssessmentTypeApi - factory interface
 * @export
 */
export const AssessmentTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssessmentTypeApiFp(configuration)
    return {
        /**
         * Retrieves the collection of AssessmentType resources.
         * @summary Retrieves the collection of AssessmentType resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentTypeCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<AssessmentTypeFullRead>> {
            return localVarFp.getAssessmentTypeCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a AssessmentType resource.
         * @summary Retrieves a AssessmentType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentTypeItem(id: string, options?: any): AxiosPromise<AssessmentTypeFullRead> {
            return localVarFp.getAssessmentTypeItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssessmentTypeApi - object-oriented interface
 * @export
 * @class AssessmentTypeApi
 * @extends {BaseAPI}
 */
export class AssessmentTypeApi extends BaseAPI {
    /**
     * Retrieves the collection of AssessmentType resources.
     * @summary Retrieves the collection of AssessmentType resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentTypeApi
     */
    public getAssessmentTypeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return AssessmentTypeApiFp(this.configuration).getAssessmentTypeCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a AssessmentType resource.
     * @summary Retrieves a AssessmentType resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentTypeApi
     */
    public getAssessmentTypeItem(id: string, options?: RawAxiosRequestConfig) {
        return AssessmentTypeApiFp(this.configuration).getAssessmentTypeItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerApi - axios parameter creator
 * @export
 */
export const CareerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Career resources.
         * @summary Retrieves the collection of Career resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/careers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Career resource.
         * @summary Retrieves a Career resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerItem', 'id', id)
            const localVarPath = `/api/careers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerApi - functional programming interface
 * @export
 */
export const CareerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of Career resources.
         * @summary Retrieves the collection of Career resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerMinimumRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerApi.getCareerCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Career resource.
         * @summary Retrieves a Career resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerItemFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerApi.getCareerItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerApi - factory interface
 * @export
 */
export const CareerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerApiFp(configuration)
    return {
        /**
         * Retrieves the collection of Career resources.
         * @summary Retrieves the collection of Career resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerMinimumRead>> {
            return localVarFp.getCareerCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Career resource.
         * @summary Retrieves a Career resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerItem(id: string, options?: any): AxiosPromise<CareerItemFullRead> {
            return localVarFp.getCareerItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerApi - object-oriented interface
 * @export
 * @class CareerApi
 * @extends {BaseAPI}
 */
export class CareerApi extends BaseAPI {
    /**
     * Retrieves the collection of Career resources.
     * @summary Retrieves the collection of Career resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerApi
     */
    public getCareerCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerApiFp(this.configuration).getCareerCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Career resource.
     * @summary Retrieves a Career resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerApi
     */
    public getCareerItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerApiFp(this.configuration).getCareerItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerClusterApi - axios parameter creator
 * @export
 */
export const CareerClusterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of CareerCluster resources.
         * @summary Retrieves the collection of CareerCluster resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusterCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerCluster resource.
         * @summary Retrieves a CareerCluster resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusterItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerClusterItem', 'id', id)
            const localVarPath = `/api/career_clusters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerClusterApi - functional programming interface
 * @export
 */
export const CareerClusterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerClusterApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of CareerCluster resources.
         * @summary Retrieves the collection of CareerCluster resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerClusterCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerClusterFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerClusterCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerClusterApi.getCareerClusterCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerCluster resource.
         * @summary Retrieves a CareerCluster resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerClusterItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerClusterFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerClusterItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerClusterApi.getCareerClusterItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerClusterApi - factory interface
 * @export
 */
export const CareerClusterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerClusterApiFp(configuration)
    return {
        /**
         * Retrieves the collection of CareerCluster resources.
         * @summary Retrieves the collection of CareerCluster resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusterCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerClusterFullRead>> {
            return localVarFp.getCareerClusterCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerCluster resource.
         * @summary Retrieves a CareerCluster resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusterItem(id: string, options?: any): AxiosPromise<CareerClusterFullRead> {
            return localVarFp.getCareerClusterItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerClusterApi - object-oriented interface
 * @export
 * @class CareerClusterApi
 * @extends {BaseAPI}
 */
export class CareerClusterApi extends BaseAPI {
    /**
     * Retrieves the collection of CareerCluster resources.
     * @summary Retrieves the collection of CareerCluster resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerClusterApi
     */
    public getCareerClusterCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerClusterApiFp(this.configuration).getCareerClusterCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerCluster resource.
     * @summary Retrieves a CareerCluster resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerClusterApi
     */
    public getCareerClusterItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerClusterApiFp(this.configuration).getCareerClusterItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerClusterRatingApi - axios parameter creator
 * @export
 */
export const CareerClusterRatingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of CareerClusterRating resources.
         * @summary Retrieves the collection of CareerClusterRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusterRatingCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_cluster_ratings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerClusterRating resource.
         * @summary Retrieves a CareerClusterRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusterRatingItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerClusterRatingItem', 'id', id)
            const localVarPath = `/api/career_cluster_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerClusterRatingApi - functional programming interface
 * @export
 */
export const CareerClusterRatingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerClusterRatingApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of CareerClusterRating resources.
         * @summary Retrieves the collection of CareerClusterRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerClusterRatingCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerClusterRatingFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerClusterRatingCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerClusterRatingApi.getCareerClusterRatingCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerClusterRating resource.
         * @summary Retrieves a CareerClusterRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerClusterRatingItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerClusterRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerClusterRatingItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerClusterRatingApi.getCareerClusterRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerClusterRatingApi - factory interface
 * @export
 */
export const CareerClusterRatingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerClusterRatingApiFp(configuration)
    return {
        /**
         * Retrieves the collection of CareerClusterRating resources.
         * @summary Retrieves the collection of CareerClusterRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusterRatingCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerClusterRatingFullRead>> {
            return localVarFp.getCareerClusterRatingCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerClusterRating resource.
         * @summary Retrieves a CareerClusterRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusterRatingItem(id: string, options?: any): AxiosPromise<CareerClusterRatingFullRead> {
            return localVarFp.getCareerClusterRatingItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerClusterRatingApi - object-oriented interface
 * @export
 * @class CareerClusterRatingApi
 * @extends {BaseAPI}
 */
export class CareerClusterRatingApi extends BaseAPI {
    /**
     * Retrieves the collection of CareerClusterRating resources.
     * @summary Retrieves the collection of CareerClusterRating resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerClusterRatingApi
     */
    public getCareerClusterRatingCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerClusterRatingApiFp(this.configuration).getCareerClusterRatingCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerClusterRating resource.
     * @summary Retrieves a CareerClusterRating resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerClusterRatingApi
     */
    public getCareerClusterRatingItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerClusterRatingApiFp(this.configuration).getCareerClusterRatingItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerClusterUserRatingApi - axios parameter creator
 * @export
 */
export const CareerClusterUserRatingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces the CareerClusterUserRating resource.
         * @summary Replaces the CareerClusterUserRating resource.
         * @param {string} id Resource identifier
         * @param {CareerClusterUserRatingUpdateCareerClusterUserRating} careerClusterUserRatingUpdateCareerClusterUserRating The updated CareerClusterUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCareerClusterRatingCareerClusterUserRatingItem: async (id: string, careerClusterUserRatingUpdateCareerClusterUserRating: CareerClusterUserRatingUpdateCareerClusterUserRating, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeCareerClusterRatingCareerClusterUserRatingItem', 'id', id)
            // verify required parameter 'careerClusterUserRatingUpdateCareerClusterUserRating' is not null or undefined
            assertParamExists('changeCareerClusterRatingCareerClusterUserRatingItem', 'careerClusterUserRatingUpdateCareerClusterUserRating', careerClusterUserRatingUpdateCareerClusterUserRating)
            const localVarPath = `/api/career_cluster_user_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(careerClusterUserRatingUpdateCareerClusterUserRating, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the CareerClusterUserRating resource.
         * @summary Removes the CareerClusterUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCareerClusterUserRatingItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCareerClusterUserRatingItem', 'id', id)
            const localVarPath = `/api/career_cluster_user_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerClusterUserRating resource.
         * @summary Retrieves a CareerClusterUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusterUserRatingItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerClusterUserRatingItem', 'id', id)
            const localVarPath = `/api/career_cluster_user_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a CareerClusterUserRating resource.
         * @summary Creates a CareerClusterUserRating resource.
         * @param {CareerClusterUserRatingMinimumWrite} careerClusterUserRatingMinimumWrite The new CareerClusterUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToCareerClusterRatingCareerClusterUserRatingCollection: async (careerClusterUserRatingMinimumWrite: CareerClusterUserRatingMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'careerClusterUserRatingMinimumWrite' is not null or undefined
            assertParamExists('respondToCareerClusterRatingCareerClusterUserRatingCollection', 'careerClusterUserRatingMinimumWrite', careerClusterUserRatingMinimumWrite)
            const localVarPath = `/api/career_cluster_user_ratings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(careerClusterUserRatingMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerClusterUserRatingApi - functional programming interface
 * @export
 */
export const CareerClusterUserRatingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerClusterUserRatingApiAxiosParamCreator(configuration)
    return {
        /**
         * Replaces the CareerClusterUserRating resource.
         * @summary Replaces the CareerClusterUserRating resource.
         * @param {string} id Resource identifier
         * @param {CareerClusterUserRatingUpdateCareerClusterUserRating} careerClusterUserRatingUpdateCareerClusterUserRating The updated CareerClusterUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeCareerClusterRatingCareerClusterUserRatingItem(id: string, careerClusterUserRatingUpdateCareerClusterUserRating: CareerClusterUserRatingUpdateCareerClusterUserRating, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerClusterUserRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeCareerClusterRatingCareerClusterUserRatingItem(id, careerClusterUserRatingUpdateCareerClusterUserRating, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerClusterUserRatingApi.changeCareerClusterRatingCareerClusterUserRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the CareerClusterUserRating resource.
         * @summary Removes the CareerClusterUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCareerClusterUserRatingItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCareerClusterUserRatingItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerClusterUserRatingApi.deleteCareerClusterUserRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerClusterUserRating resource.
         * @summary Retrieves a CareerClusterUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerClusterUserRatingItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerClusterUserRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerClusterUserRatingItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerClusterUserRatingApi.getCareerClusterUserRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a CareerClusterUserRating resource.
         * @summary Creates a CareerClusterUserRating resource.
         * @param {CareerClusterUserRatingMinimumWrite} careerClusterUserRatingMinimumWrite The new CareerClusterUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async respondToCareerClusterRatingCareerClusterUserRatingCollection(careerClusterUserRatingMinimumWrite: CareerClusterUserRatingMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerClusterUserRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.respondToCareerClusterRatingCareerClusterUserRatingCollection(careerClusterUserRatingMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerClusterUserRatingApi.respondToCareerClusterRatingCareerClusterUserRatingCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerClusterUserRatingApi - factory interface
 * @export
 */
export const CareerClusterUserRatingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerClusterUserRatingApiFp(configuration)
    return {
        /**
         * Replaces the CareerClusterUserRating resource.
         * @summary Replaces the CareerClusterUserRating resource.
         * @param {string} id Resource identifier
         * @param {CareerClusterUserRatingUpdateCareerClusterUserRating} careerClusterUserRatingUpdateCareerClusterUserRating The updated CareerClusterUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCareerClusterRatingCareerClusterUserRatingItem(id: string, careerClusterUserRatingUpdateCareerClusterUserRating: CareerClusterUserRatingUpdateCareerClusterUserRating, options?: any): AxiosPromise<CareerClusterUserRatingFullRead> {
            return localVarFp.changeCareerClusterRatingCareerClusterUserRatingItem(id, careerClusterUserRatingUpdateCareerClusterUserRating, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the CareerClusterUserRating resource.
         * @summary Removes the CareerClusterUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCareerClusterUserRatingItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCareerClusterUserRatingItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerClusterUserRating resource.
         * @summary Retrieves a CareerClusterUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerClusterUserRatingItem(id: string, options?: any): AxiosPromise<CareerClusterUserRatingFullRead> {
            return localVarFp.getCareerClusterUserRatingItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a CareerClusterUserRating resource.
         * @summary Creates a CareerClusterUserRating resource.
         * @param {CareerClusterUserRatingMinimumWrite} careerClusterUserRatingMinimumWrite The new CareerClusterUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToCareerClusterRatingCareerClusterUserRatingCollection(careerClusterUserRatingMinimumWrite: CareerClusterUserRatingMinimumWrite, options?: any): AxiosPromise<CareerClusterUserRatingFullRead> {
            return localVarFp.respondToCareerClusterRatingCareerClusterUserRatingCollection(careerClusterUserRatingMinimumWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerClusterUserRatingApi - object-oriented interface
 * @export
 * @class CareerClusterUserRatingApi
 * @extends {BaseAPI}
 */
export class CareerClusterUserRatingApi extends BaseAPI {
    /**
     * Replaces the CareerClusterUserRating resource.
     * @summary Replaces the CareerClusterUserRating resource.
     * @param {string} id Resource identifier
     * @param {CareerClusterUserRatingUpdateCareerClusterUserRating} careerClusterUserRatingUpdateCareerClusterUserRating The updated CareerClusterUserRating resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerClusterUserRatingApi
     */
    public changeCareerClusterRatingCareerClusterUserRatingItem(id: string, careerClusterUserRatingUpdateCareerClusterUserRating: CareerClusterUserRatingUpdateCareerClusterUserRating, options?: RawAxiosRequestConfig) {
        return CareerClusterUserRatingApiFp(this.configuration).changeCareerClusterRatingCareerClusterUserRatingItem(id, careerClusterUserRatingUpdateCareerClusterUserRating, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the CareerClusterUserRating resource.
     * @summary Removes the CareerClusterUserRating resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerClusterUserRatingApi
     */
    public deleteCareerClusterUserRatingItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerClusterUserRatingApiFp(this.configuration).deleteCareerClusterUserRatingItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerClusterUserRating resource.
     * @summary Retrieves a CareerClusterUserRating resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerClusterUserRatingApi
     */
    public getCareerClusterUserRatingItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerClusterUserRatingApiFp(this.configuration).getCareerClusterUserRatingItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a CareerClusterUserRating resource.
     * @summary Creates a CareerClusterUserRating resource.
     * @param {CareerClusterUserRatingMinimumWrite} careerClusterUserRatingMinimumWrite The new CareerClusterUserRating resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerClusterUserRatingApi
     */
    public respondToCareerClusterRatingCareerClusterUserRatingCollection(careerClusterUserRatingMinimumWrite: CareerClusterUserRatingMinimumWrite, options?: RawAxiosRequestConfig) {
        return CareerClusterUserRatingApiFp(this.configuration).respondToCareerClusterRatingCareerClusterUserRatingCollection(careerClusterUserRatingMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerFacetApi - axios parameter creator
 * @export
 */
export const CareerFacetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of CareerFacet resources.
         * @summary Retrieves the collection of CareerFacet resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerFacetCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_facets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerFacet resource.
         * @summary Retrieves a CareerFacet resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerFacetItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerFacetItem', 'id', id)
            const localVarPath = `/api/career_facets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a CareerFacet resource.
         * @summary Creates a CareerFacet resource.
         * @param {CareerFacet} careerFacet The new CareerFacet resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCareerFacetCollection: async (careerFacet: CareerFacet, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'careerFacet' is not null or undefined
            assertParamExists('postCareerFacetCollection', 'careerFacet', careerFacet)
            const localVarPath = `/api/career_facets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(careerFacet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerFacetApi - functional programming interface
 * @export
 */
export const CareerFacetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerFacetApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of CareerFacet resources.
         * @summary Retrieves the collection of CareerFacet resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerFacetCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerFacetFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerFacetCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerFacetApi.getCareerFacetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerFacet resource.
         * @summary Retrieves a CareerFacet resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerFacetItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerFacetFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerFacetItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerFacetApi.getCareerFacetItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a CareerFacet resource.
         * @summary Creates a CareerFacet resource.
         * @param {CareerFacet} careerFacet The new CareerFacet resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCareerFacetCollection(careerFacet: CareerFacet, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerFacetFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCareerFacetCollection(careerFacet, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerFacetApi.postCareerFacetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerFacetApi - factory interface
 * @export
 */
export const CareerFacetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerFacetApiFp(configuration)
    return {
        /**
         * Retrieves the collection of CareerFacet resources.
         * @summary Retrieves the collection of CareerFacet resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerFacetCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerFacetFullRead>> {
            return localVarFp.getCareerFacetCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerFacet resource.
         * @summary Retrieves a CareerFacet resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerFacetItem(id: string, options?: any): AxiosPromise<CareerFacetFullRead> {
            return localVarFp.getCareerFacetItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a CareerFacet resource.
         * @summary Creates a CareerFacet resource.
         * @param {CareerFacet} careerFacet The new CareerFacet resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCareerFacetCollection(careerFacet: CareerFacet, options?: any): AxiosPromise<CareerFacetFullRead> {
            return localVarFp.postCareerFacetCollection(careerFacet, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerFacetApi - object-oriented interface
 * @export
 * @class CareerFacetApi
 * @extends {BaseAPI}
 */
export class CareerFacetApi extends BaseAPI {
    /**
     * Retrieves the collection of CareerFacet resources.
     * @summary Retrieves the collection of CareerFacet resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerFacetApi
     */
    public getCareerFacetCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerFacetApiFp(this.configuration).getCareerFacetCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerFacet resource.
     * @summary Retrieves a CareerFacet resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerFacetApi
     */
    public getCareerFacetItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerFacetApiFp(this.configuration).getCareerFacetItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a CareerFacet resource.
     * @summary Creates a CareerFacet resource.
     * @param {CareerFacet} careerFacet The new CareerFacet resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerFacetApi
     */
    public postCareerFacetCollection(careerFacet: CareerFacet, options?: RawAxiosRequestConfig) {
        return CareerFacetApiFp(this.configuration).postCareerFacetCollection(careerFacet, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerIntelligencesBaselineApi - axios parameter creator
 * @export
 */
export const CareerIntelligencesBaselineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of CareerIntelligencesBaseline resources.
         * @summary Retrieves the collection of CareerIntelligencesBaseline resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerIntelligencesBaselineCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_intelligences_baselines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerIntelligencesBaseline resource.
         * @summary Retrieves a CareerIntelligencesBaseline resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerIntelligencesBaselineItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerIntelligencesBaselineItem', 'id', id)
            const localVarPath = `/api/career_intelligences_baselines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerIntelligencesBaselineApi - functional programming interface
 * @export
 */
export const CareerIntelligencesBaselineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerIntelligencesBaselineApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of CareerIntelligencesBaseline resources.
         * @summary Retrieves the collection of CareerIntelligencesBaseline resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerIntelligencesBaselineCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerIntelligencesBaselineFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerIntelligencesBaselineCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerIntelligencesBaselineApi.getCareerIntelligencesBaselineCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerIntelligencesBaseline resource.
         * @summary Retrieves a CareerIntelligencesBaseline resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerIntelligencesBaselineItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerIntelligencesBaselineFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerIntelligencesBaselineItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerIntelligencesBaselineApi.getCareerIntelligencesBaselineItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerIntelligencesBaselineApi - factory interface
 * @export
 */
export const CareerIntelligencesBaselineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerIntelligencesBaselineApiFp(configuration)
    return {
        /**
         * Retrieves the collection of CareerIntelligencesBaseline resources.
         * @summary Retrieves the collection of CareerIntelligencesBaseline resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerIntelligencesBaselineCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerIntelligencesBaselineFullRead>> {
            return localVarFp.getCareerIntelligencesBaselineCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerIntelligencesBaseline resource.
         * @summary Retrieves a CareerIntelligencesBaseline resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerIntelligencesBaselineItem(id: string, options?: any): AxiosPromise<CareerIntelligencesBaselineFullRead> {
            return localVarFp.getCareerIntelligencesBaselineItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerIntelligencesBaselineApi - object-oriented interface
 * @export
 * @class CareerIntelligencesBaselineApi
 * @extends {BaseAPI}
 */
export class CareerIntelligencesBaselineApi extends BaseAPI {
    /**
     * Retrieves the collection of CareerIntelligencesBaseline resources.
     * @summary Retrieves the collection of CareerIntelligencesBaseline resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerIntelligencesBaselineApi
     */
    public getCareerIntelligencesBaselineCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerIntelligencesBaselineApiFp(this.configuration).getCareerIntelligencesBaselineCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerIntelligencesBaseline resource.
     * @summary Retrieves a CareerIntelligencesBaseline resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerIntelligencesBaselineApi
     */
    public getCareerIntelligencesBaselineItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerIntelligencesBaselineApiFp(this.configuration).getCareerIntelligencesBaselineItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerProgramApi - axios parameter creator
 * @export
 */
export const CareerProgramApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of CareerProgram resources.
         * @summary Retrieves the collection of CareerProgram resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerProgramCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerProgram resource.
         * @summary Retrieves a CareerProgram resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerProgramItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerProgramItem', 'id', id)
            const localVarPath = `/api/career_programs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerProgramApi - functional programming interface
 * @export
 */
export const CareerProgramApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerProgramApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of CareerProgram resources.
         * @summary Retrieves the collection of CareerProgram resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerProgramCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerProgramFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerProgramCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerProgramApi.getCareerProgramCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerProgram resource.
         * @summary Retrieves a CareerProgram resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerProgramItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerProgramFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerProgramItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerProgramApi.getCareerProgramItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerProgramApi - factory interface
 * @export
 */
export const CareerProgramApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerProgramApiFp(configuration)
    return {
        /**
         * Retrieves the collection of CareerProgram resources.
         * @summary Retrieves the collection of CareerProgram resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerProgramCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerProgramFullRead>> {
            return localVarFp.getCareerProgramCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerProgram resource.
         * @summary Retrieves a CareerProgram resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerProgramItem(id: string, options?: any): AxiosPromise<CareerProgramFullRead> {
            return localVarFp.getCareerProgramItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerProgramApi - object-oriented interface
 * @export
 * @class CareerProgramApi
 * @extends {BaseAPI}
 */
export class CareerProgramApi extends BaseAPI {
    /**
     * Retrieves the collection of CareerProgram resources.
     * @summary Retrieves the collection of CareerProgram resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerProgramApi
     */
    public getCareerProgramCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerProgramApiFp(this.configuration).getCareerProgramCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerProgram resource.
     * @summary Retrieves a CareerProgram resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerProgramApi
     */
    public getCareerProgramItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerProgramApiFp(this.configuration).getCareerProgramItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerProgramMatchApi - axios parameter creator
 * @export
 */
export const CareerProgramMatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of CareerProgramMatch resources.
         * @summary Retrieves the collection of CareerProgramMatch resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerProgramMatchCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_program_matches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerProgramMatch resource.
         * @summary Retrieves a CareerProgramMatch resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerProgramMatchItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerProgramMatchItem', 'id', id)
            const localVarPath = `/api/career_program_matches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerProgramMatchApi - functional programming interface
 * @export
 */
export const CareerProgramMatchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerProgramMatchApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of CareerProgramMatch resources.
         * @summary Retrieves the collection of CareerProgramMatch resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerProgramMatchCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerProgramMatchFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerProgramMatchCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerProgramMatchApi.getCareerProgramMatchCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerProgramMatch resource.
         * @summary Retrieves a CareerProgramMatch resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerProgramMatchItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerProgramMatchFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerProgramMatchItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerProgramMatchApi.getCareerProgramMatchItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerProgramMatchApi - factory interface
 * @export
 */
export const CareerProgramMatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerProgramMatchApiFp(configuration)
    return {
        /**
         * Retrieves the collection of CareerProgramMatch resources.
         * @summary Retrieves the collection of CareerProgramMatch resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerProgramMatchCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerProgramMatchFullRead>> {
            return localVarFp.getCareerProgramMatchCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerProgramMatch resource.
         * @summary Retrieves a CareerProgramMatch resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerProgramMatchItem(id: string, options?: any): AxiosPromise<CareerProgramMatchFullRead> {
            return localVarFp.getCareerProgramMatchItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerProgramMatchApi - object-oriented interface
 * @export
 * @class CareerProgramMatchApi
 * @extends {BaseAPI}
 */
export class CareerProgramMatchApi extends BaseAPI {
    /**
     * Retrieves the collection of CareerProgramMatch resources.
     * @summary Retrieves the collection of CareerProgramMatch resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerProgramMatchApi
     */
    public getCareerProgramMatchCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerProgramMatchApiFp(this.configuration).getCareerProgramMatchCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerProgramMatch resource.
     * @summary Retrieves a CareerProgramMatch resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerProgramMatchApi
     */
    public getCareerProgramMatchItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerProgramMatchApiFp(this.configuration).getCareerProgramMatchItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerRangeApi - axios parameter creator
 * @export
 */
export const CareerRangeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of CareerRange resources.
         * @summary Retrieves the collection of CareerRange resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerRangeCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_ranges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerRange resource.
         * @summary Retrieves a CareerRange resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerRangeItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerRangeItem', 'id', id)
            const localVarPath = `/api/career_ranges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerRangeApi - functional programming interface
 * @export
 */
export const CareerRangeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerRangeApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of CareerRange resources.
         * @summary Retrieves the collection of CareerRange resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerRangeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerRangeMinimumRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerRangeCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerRangeApi.getCareerRangeCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerRange resource.
         * @summary Retrieves a CareerRange resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerRangeItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerRangeMinimumRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerRangeItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerRangeApi.getCareerRangeItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerRangeApi - factory interface
 * @export
 */
export const CareerRangeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerRangeApiFp(configuration)
    return {
        /**
         * Retrieves the collection of CareerRange resources.
         * @summary Retrieves the collection of CareerRange resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerRangeCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerRangeMinimumRead>> {
            return localVarFp.getCareerRangeCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerRange resource.
         * @summary Retrieves a CareerRange resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerRangeItem(id: string, options?: any): AxiosPromise<CareerRangeMinimumRead> {
            return localVarFp.getCareerRangeItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerRangeApi - object-oriented interface
 * @export
 * @class CareerRangeApi
 * @extends {BaseAPI}
 */
export class CareerRangeApi extends BaseAPI {
    /**
     * Retrieves the collection of CareerRange resources.
     * @summary Retrieves the collection of CareerRange resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerRangeApi
     */
    public getCareerRangeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerRangeApiFp(this.configuration).getCareerRangeCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerRange resource.
     * @summary Retrieves a CareerRange resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerRangeApi
     */
    public getCareerRangeItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerRangeApiFp(this.configuration).getCareerRangeItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerRatingApi - axios parameter creator
 * @export
 */
export const CareerRatingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of CareerRating resources.
         * @summary Retrieves the collection of CareerRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerRatingCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_ratings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerRating resource.
         * @summary Retrieves a CareerRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerRatingItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerRatingItem', 'id', id)
            const localVarPath = `/api/career_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerRatingApi - functional programming interface
 * @export
 */
export const CareerRatingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerRatingApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of CareerRating resources.
         * @summary Retrieves the collection of CareerRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerRatingCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerRatingFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerRatingCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerRatingApi.getCareerRatingCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerRating resource.
         * @summary Retrieves a CareerRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerRatingItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerRatingItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerRatingApi.getCareerRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerRatingApi - factory interface
 * @export
 */
export const CareerRatingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerRatingApiFp(configuration)
    return {
        /**
         * Retrieves the collection of CareerRating resources.
         * @summary Retrieves the collection of CareerRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerRatingCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerRatingFullRead>> {
            return localVarFp.getCareerRatingCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerRating resource.
         * @summary Retrieves a CareerRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerRatingItem(id: string, options?: any): AxiosPromise<CareerRatingFullRead> {
            return localVarFp.getCareerRatingItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerRatingApi - object-oriented interface
 * @export
 * @class CareerRatingApi
 * @extends {BaseAPI}
 */
export class CareerRatingApi extends BaseAPI {
    /**
     * Retrieves the collection of CareerRating resources.
     * @summary Retrieves the collection of CareerRating resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerRatingApi
     */
    public getCareerRatingCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerRatingApiFp(this.configuration).getCareerRatingCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerRating resource.
     * @summary Retrieves a CareerRating resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerRatingApi
     */
    public getCareerRatingItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerRatingApiFp(this.configuration).getCareerRatingItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerSkillsBaselineApi - axios parameter creator
 * @export
 */
export const CareerSkillsBaselineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of CareerSkillsBaseline resources.
         * @summary Retrieves the collection of CareerSkillsBaseline resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerSkillsBaselineCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_skills_baselines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerSkillsBaseline resource.
         * @summary Retrieves a CareerSkillsBaseline resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerSkillsBaselineItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerSkillsBaselineItem', 'id', id)
            const localVarPath = `/api/career_skills_baselines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerSkillsBaselineApi - functional programming interface
 * @export
 */
export const CareerSkillsBaselineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerSkillsBaselineApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of CareerSkillsBaseline resources.
         * @summary Retrieves the collection of CareerSkillsBaseline resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerSkillsBaselineCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerSkillsBaselineFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerSkillsBaselineCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerSkillsBaselineApi.getCareerSkillsBaselineCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerSkillsBaseline resource.
         * @summary Retrieves a CareerSkillsBaseline resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerSkillsBaselineItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerSkillsBaselineFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerSkillsBaselineItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerSkillsBaselineApi.getCareerSkillsBaselineItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerSkillsBaselineApi - factory interface
 * @export
 */
export const CareerSkillsBaselineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerSkillsBaselineApiFp(configuration)
    return {
        /**
         * Retrieves the collection of CareerSkillsBaseline resources.
         * @summary Retrieves the collection of CareerSkillsBaseline resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerSkillsBaselineCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerSkillsBaselineFullRead>> {
            return localVarFp.getCareerSkillsBaselineCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerSkillsBaseline resource.
         * @summary Retrieves a CareerSkillsBaseline resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerSkillsBaselineItem(id: string, options?: any): AxiosPromise<CareerSkillsBaselineFullRead> {
            return localVarFp.getCareerSkillsBaselineItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerSkillsBaselineApi - object-oriented interface
 * @export
 * @class CareerSkillsBaselineApi
 * @extends {BaseAPI}
 */
export class CareerSkillsBaselineApi extends BaseAPI {
    /**
     * Retrieves the collection of CareerSkillsBaseline resources.
     * @summary Retrieves the collection of CareerSkillsBaseline resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSkillsBaselineApi
     */
    public getCareerSkillsBaselineCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerSkillsBaselineApiFp(this.configuration).getCareerSkillsBaselineCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerSkillsBaseline resource.
     * @summary Retrieves a CareerSkillsBaseline resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerSkillsBaselineApi
     */
    public getCareerSkillsBaselineItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerSkillsBaselineApiFp(this.configuration).getCareerSkillsBaselineItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerUserRatingApi - axios parameter creator
 * @export
 */
export const CareerUserRatingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces the CareerUserRating resource.
         * @summary Replaces the CareerUserRating resource.
         * @param {string} id Resource identifier
         * @param {CareerUserRatingMinimumWrite} careerUserRatingMinimumWrite The updated CareerUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCareerUserRatingCareerUserRatingItem: async (id: string, careerUserRatingMinimumWrite: CareerUserRatingMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeCareerUserRatingCareerUserRatingItem', 'id', id)
            // verify required parameter 'careerUserRatingMinimumWrite' is not null or undefined
            assertParamExists('changeCareerUserRatingCareerUserRatingItem', 'careerUserRatingMinimumWrite', careerUserRatingMinimumWrite)
            const localVarPath = `/api/career_user_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(careerUserRatingMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the CareerUserRating resource.
         * @summary Removes the CareerUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCareerUserRatingItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCareerUserRatingItem', 'id', id)
            const localVarPath = `/api/career_user_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of CareerUserRating resources.
         * @summary Retrieves the collection of CareerUserRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerUserRatingCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_user_ratings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerUserRating resource.
         * @summary Retrieves a CareerUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerUserRatingItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerUserRatingItem', 'id', id)
            const localVarPath = `/api/career_user_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a CareerUserRating resource.
         * @summary Creates a CareerUserRating resource.
         * @param {CareerUserRatingMinimumWrite} careerUserRatingMinimumWrite The new CareerUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToCareerUserRatingCareerUserRatingCollection: async (careerUserRatingMinimumWrite: CareerUserRatingMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'careerUserRatingMinimumWrite' is not null or undefined
            assertParamExists('respondToCareerUserRatingCareerUserRatingCollection', 'careerUserRatingMinimumWrite', careerUserRatingMinimumWrite)
            const localVarPath = `/api/career_user_ratings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(careerUserRatingMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerUserRatingApi - functional programming interface
 * @export
 */
export const CareerUserRatingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerUserRatingApiAxiosParamCreator(configuration)
    return {
        /**
         * Replaces the CareerUserRating resource.
         * @summary Replaces the CareerUserRating resource.
         * @param {string} id Resource identifier
         * @param {CareerUserRatingMinimumWrite} careerUserRatingMinimumWrite The updated CareerUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeCareerUserRatingCareerUserRatingItem(id: string, careerUserRatingMinimumWrite: CareerUserRatingMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerUserRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeCareerUserRatingCareerUserRatingItem(id, careerUserRatingMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerUserRatingApi.changeCareerUserRatingCareerUserRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the CareerUserRating resource.
         * @summary Removes the CareerUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCareerUserRatingItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCareerUserRatingItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerUserRatingApi.deleteCareerUserRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the collection of CareerUserRating resources.
         * @summary Retrieves the collection of CareerUserRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerUserRatingCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerUserRatingFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerUserRatingCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerUserRatingApi.getCareerUserRatingCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerUserRating resource.
         * @summary Retrieves a CareerUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerUserRatingItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerUserRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerUserRatingItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerUserRatingApi.getCareerUserRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a CareerUserRating resource.
         * @summary Creates a CareerUserRating resource.
         * @param {CareerUserRatingMinimumWrite} careerUserRatingMinimumWrite The new CareerUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async respondToCareerUserRatingCareerUserRatingCollection(careerUserRatingMinimumWrite: CareerUserRatingMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerUserRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.respondToCareerUserRatingCareerUserRatingCollection(careerUserRatingMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerUserRatingApi.respondToCareerUserRatingCareerUserRatingCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerUserRatingApi - factory interface
 * @export
 */
export const CareerUserRatingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerUserRatingApiFp(configuration)
    return {
        /**
         * Replaces the CareerUserRating resource.
         * @summary Replaces the CareerUserRating resource.
         * @param {string} id Resource identifier
         * @param {CareerUserRatingMinimumWrite} careerUserRatingMinimumWrite The updated CareerUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCareerUserRatingCareerUserRatingItem(id: string, careerUserRatingMinimumWrite: CareerUserRatingMinimumWrite, options?: any): AxiosPromise<CareerUserRatingFullRead> {
            return localVarFp.changeCareerUserRatingCareerUserRatingItem(id, careerUserRatingMinimumWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the CareerUserRating resource.
         * @summary Removes the CareerUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCareerUserRatingItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCareerUserRatingItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of CareerUserRating resources.
         * @summary Retrieves the collection of CareerUserRating resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerUserRatingCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<CareerUserRatingFullRead>> {
            return localVarFp.getCareerUserRatingCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerUserRating resource.
         * @summary Retrieves a CareerUserRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerUserRatingItem(id: string, options?: any): AxiosPromise<CareerUserRatingFullRead> {
            return localVarFp.getCareerUserRatingItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a CareerUserRating resource.
         * @summary Creates a CareerUserRating resource.
         * @param {CareerUserRatingMinimumWrite} careerUserRatingMinimumWrite The new CareerUserRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToCareerUserRatingCareerUserRatingCollection(careerUserRatingMinimumWrite: CareerUserRatingMinimumWrite, options?: any): AxiosPromise<CareerUserRatingFullRead> {
            return localVarFp.respondToCareerUserRatingCareerUserRatingCollection(careerUserRatingMinimumWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerUserRatingApi - object-oriented interface
 * @export
 * @class CareerUserRatingApi
 * @extends {BaseAPI}
 */
export class CareerUserRatingApi extends BaseAPI {
    /**
     * Replaces the CareerUserRating resource.
     * @summary Replaces the CareerUserRating resource.
     * @param {string} id Resource identifier
     * @param {CareerUserRatingMinimumWrite} careerUserRatingMinimumWrite The updated CareerUserRating resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerUserRatingApi
     */
    public changeCareerUserRatingCareerUserRatingItem(id: string, careerUserRatingMinimumWrite: CareerUserRatingMinimumWrite, options?: RawAxiosRequestConfig) {
        return CareerUserRatingApiFp(this.configuration).changeCareerUserRatingCareerUserRatingItem(id, careerUserRatingMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the CareerUserRating resource.
     * @summary Removes the CareerUserRating resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerUserRatingApi
     */
    public deleteCareerUserRatingItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerUserRatingApiFp(this.configuration).deleteCareerUserRatingItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of CareerUserRating resources.
     * @summary Retrieves the collection of CareerUserRating resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerUserRatingApi
     */
    public getCareerUserRatingCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return CareerUserRatingApiFp(this.configuration).getCareerUserRatingCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerUserRating resource.
     * @summary Retrieves a CareerUserRating resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerUserRatingApi
     */
    public getCareerUserRatingItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerUserRatingApiFp(this.configuration).getCareerUserRatingItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a CareerUserRating resource.
     * @summary Creates a CareerUserRating resource.
     * @param {CareerUserRatingMinimumWrite} careerUserRatingMinimumWrite The new CareerUserRating resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerUserRatingApi
     */
    public respondToCareerUserRatingCareerUserRatingCollection(careerUserRatingMinimumWrite: CareerUserRatingMinimumWrite, options?: RawAxiosRequestConfig) {
        return CareerUserRatingApiFp(this.configuration).respondToCareerUserRatingCareerUserRatingCollection(careerUserRatingMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CareerWageApi - axios parameter creator
 * @export
 */
export const CareerWageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of CareerWage resources.
         * @summary Retrieves the collection of CareerWage resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {string} [career] 
         * @param {Array<string>} [career2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerWageCollection: async (page?: number, pagination?: boolean, career?: string, career2?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/career_wages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (career !== undefined) {
                localVarQueryParameter['career'] = career;
            }

            if (career2) {
                localVarQueryParameter['career[]'] = career2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a CareerWage resource.
         * @summary Retrieves a CareerWage resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerWageItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareerWageItem', 'id', id)
            const localVarPath = `/api/career_wages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareerWageApi - functional programming interface
 * @export
 */
export const CareerWageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareerWageApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of CareerWage resources.
         * @summary Retrieves the collection of CareerWage resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {string} [career] 
         * @param {Array<string>} [career2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerWageCollection(page?: number, pagination?: boolean, career?: string, career2?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareerWageFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerWageCollection(page, pagination, career, career2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerWageApi.getCareerWageCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a CareerWage resource.
         * @summary Retrieves a CareerWage resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareerWageItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareerWageFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareerWageItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CareerWageApi.getCareerWageItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CareerWageApi - factory interface
 * @export
 */
export const CareerWageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareerWageApiFp(configuration)
    return {
        /**
         * Retrieves the collection of CareerWage resources.
         * @summary Retrieves the collection of CareerWage resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {string} [career] 
         * @param {Array<string>} [career2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerWageCollection(page?: number, pagination?: boolean, career?: string, career2?: Array<string>, options?: any): AxiosPromise<Array<CareerWageFullRead>> {
            return localVarFp.getCareerWageCollection(page, pagination, career, career2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a CareerWage resource.
         * @summary Retrieves a CareerWage resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareerWageItem(id: string, options?: any): AxiosPromise<CareerWageFullRead> {
            return localVarFp.getCareerWageItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareerWageApi - object-oriented interface
 * @export
 * @class CareerWageApi
 * @extends {BaseAPI}
 */
export class CareerWageApi extends BaseAPI {
    /**
     * Retrieves the collection of CareerWage resources.
     * @summary Retrieves the collection of CareerWage resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {string} [career] 
     * @param {Array<string>} [career2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerWageApi
     */
    public getCareerWageCollection(page?: number, pagination?: boolean, career?: string, career2?: Array<string>, options?: RawAxiosRequestConfig) {
        return CareerWageApiFp(this.configuration).getCareerWageCollection(page, pagination, career, career2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a CareerWage resource.
     * @summary Retrieves a CareerWage resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareerWageApi
     */
    public getCareerWageItem(id: string, options?: RawAxiosRequestConfig) {
        return CareerWageApiFp(this.configuration).getCareerWageItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InstitutionApi - axios parameter creator
 * @export
 */
export const InstitutionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Institution resources.
         * @summary Retrieves the collection of Institution resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutionCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Institution resource.
         * @summary Retrieves a Institution resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutionItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInstitutionItem', 'id', id)
            const localVarPath = `/api/institutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstitutionApi - functional programming interface
 * @export
 */
export const InstitutionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstitutionApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of Institution resources.
         * @summary Retrieves the collection of Institution resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstitutionCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstitutionFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitutionCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstitutionApi.getInstitutionCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Institution resource.
         * @summary Retrieves a Institution resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstitutionItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitutionItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstitutionApi.getInstitutionItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InstitutionApi - factory interface
 * @export
 */
export const InstitutionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstitutionApiFp(configuration)
    return {
        /**
         * Retrieves the collection of Institution resources.
         * @summary Retrieves the collection of Institution resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutionCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<InstitutionFullRead>> {
            return localVarFp.getInstitutionCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Institution resource.
         * @summary Retrieves a Institution resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutionItem(id: string, options?: any): AxiosPromise<InstitutionFullRead> {
            return localVarFp.getInstitutionItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstitutionApi - object-oriented interface
 * @export
 * @class InstitutionApi
 * @extends {BaseAPI}
 */
export class InstitutionApi extends BaseAPI {
    /**
     * Retrieves the collection of Institution resources.
     * @summary Retrieves the collection of Institution resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutionApi
     */
    public getInstitutionCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return InstitutionApiFp(this.configuration).getInstitutionCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Institution resource.
     * @summary Retrieves a Institution resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutionApi
     */
    public getInstitutionItem(id: string, options?: RawAxiosRequestConfig) {
        return InstitutionApiFp(this.configuration).getInstitutionItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MacroQuestionTypeApi - axios parameter creator
 * @export
 */
export const MacroQuestionTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of MacroQuestionType resources.
         * @summary Retrieves the collection of MacroQuestionType resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMacroQuestionTypeCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/macro_question_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a MacroQuestionType resource.
         * @summary Retrieves a MacroQuestionType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMacroQuestionTypeItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMacroQuestionTypeItem', 'id', id)
            const localVarPath = `/api/macro_question_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MacroQuestionTypeApi - functional programming interface
 * @export
 */
export const MacroQuestionTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MacroQuestionTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of MacroQuestionType resources.
         * @summary Retrieves the collection of MacroQuestionType resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMacroQuestionTypeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MacroQuestionTypeFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMacroQuestionTypeCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MacroQuestionTypeApi.getMacroQuestionTypeCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a MacroQuestionType resource.
         * @summary Retrieves a MacroQuestionType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMacroQuestionTypeItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MacroQuestionTypeFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMacroQuestionTypeItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MacroQuestionTypeApi.getMacroQuestionTypeItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MacroQuestionTypeApi - factory interface
 * @export
 */
export const MacroQuestionTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MacroQuestionTypeApiFp(configuration)
    return {
        /**
         * Retrieves the collection of MacroQuestionType resources.
         * @summary Retrieves the collection of MacroQuestionType resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMacroQuestionTypeCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<MacroQuestionTypeFullRead>> {
            return localVarFp.getMacroQuestionTypeCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a MacroQuestionType resource.
         * @summary Retrieves a MacroQuestionType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMacroQuestionTypeItem(id: string, options?: any): AxiosPromise<MacroQuestionTypeFullRead> {
            return localVarFp.getMacroQuestionTypeItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MacroQuestionTypeApi - object-oriented interface
 * @export
 * @class MacroQuestionTypeApi
 * @extends {BaseAPI}
 */
export class MacroQuestionTypeApi extends BaseAPI {
    /**
     * Retrieves the collection of MacroQuestionType resources.
     * @summary Retrieves the collection of MacroQuestionType resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MacroQuestionTypeApi
     */
    public getMacroQuestionTypeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return MacroQuestionTypeApiFp(this.configuration).getMacroQuestionTypeCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a MacroQuestionType resource.
     * @summary Retrieves a MacroQuestionType resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MacroQuestionTypeApi
     */
    public getMacroQuestionTypeItem(id: string, options?: RawAxiosRequestConfig) {
        return MacroQuestionTypeApiFp(this.configuration).getMacroQuestionTypeItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MajorApi - axios parameter creator
 * @export
 */
export const MajorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Major resources.
         * @summary Retrieves the collection of Major resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMajorCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/majors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Major resource.
         * @summary Retrieves a Major resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMajorItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMajorItem', 'id', id)
            const localVarPath = `/api/majors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MajorApi - functional programming interface
 * @export
 */
export const MajorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MajorApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of Major resources.
         * @summary Retrieves the collection of Major resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMajorCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MajorReadMajor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMajorCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MajorApi.getMajorCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Major resource.
         * @summary Retrieves a Major resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMajorItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MajorReadMajor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMajorItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MajorApi.getMajorItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MajorApi - factory interface
 * @export
 */
export const MajorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MajorApiFp(configuration)
    return {
        /**
         * Retrieves the collection of Major resources.
         * @summary Retrieves the collection of Major resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMajorCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<MajorReadMajor>> {
            return localVarFp.getMajorCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Major resource.
         * @summary Retrieves a Major resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMajorItem(id: string, options?: any): AxiosPromise<MajorReadMajor> {
            return localVarFp.getMajorItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MajorApi - object-oriented interface
 * @export
 * @class MajorApi
 * @extends {BaseAPI}
 */
export class MajorApi extends BaseAPI {
    /**
     * Retrieves the collection of Major resources.
     * @summary Retrieves the collection of Major resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MajorApi
     */
    public getMajorCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return MajorApiFp(this.configuration).getMajorCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Major resource.
     * @summary Retrieves a Major resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MajorApi
     */
    public getMajorItem(id: string, options?: RawAxiosRequestConfig) {
        return MajorApiFp(this.configuration).getMajorItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductConfigurationApi - axios parameter creator
 * @export
 */
export const ProductConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of ProductConfiguration resources.
         * @summary Retrieves the collection of ProductConfiguration resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {string} [name] 
         * @param {string} [product] 
         * @param {Array<string>} [product2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductConfigurationCollection: async (page?: number, pagination?: boolean, name?: string, product?: string, product2?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/product_configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (product2) {
                localVarQueryParameter['product[]'] = product2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a ProductConfiguration resource.
         * @summary Retrieves a ProductConfiguration resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductConfigurationItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductConfigurationItem', 'id', id)
            const localVarPath = `/api/product_configurations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductConfigurationApi - functional programming interface
 * @export
 */
export const ProductConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of ProductConfiguration resources.
         * @summary Retrieves the collection of ProductConfiguration resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {string} [name] 
         * @param {string} [product] 
         * @param {Array<string>} [product2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductConfigurationCollection(page?: number, pagination?: boolean, name?: string, product?: string, product2?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductConfigurationFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductConfigurationCollection(page, pagination, name, product, product2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductConfigurationApi.getProductConfigurationCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a ProductConfiguration resource.
         * @summary Retrieves a ProductConfiguration resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductConfigurationItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductConfigurationFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductConfigurationItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductConfigurationApi.getProductConfigurationItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductConfigurationApi - factory interface
 * @export
 */
export const ProductConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductConfigurationApiFp(configuration)
    return {
        /**
         * Retrieves the collection of ProductConfiguration resources.
         * @summary Retrieves the collection of ProductConfiguration resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {string} [name] 
         * @param {string} [product] 
         * @param {Array<string>} [product2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductConfigurationCollection(page?: number, pagination?: boolean, name?: string, product?: string, product2?: Array<string>, options?: any): AxiosPromise<Array<ProductConfigurationFullRead>> {
            return localVarFp.getProductConfigurationCollection(page, pagination, name, product, product2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a ProductConfiguration resource.
         * @summary Retrieves a ProductConfiguration resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductConfigurationItem(id: string, options?: any): AxiosPromise<ProductConfigurationFullRead> {
            return localVarFp.getProductConfigurationItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductConfigurationApi - object-oriented interface
 * @export
 * @class ProductConfigurationApi
 * @extends {BaseAPI}
 */
export class ProductConfigurationApi extends BaseAPI {
    /**
     * Retrieves the collection of ProductConfiguration resources.
     * @summary Retrieves the collection of ProductConfiguration resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {string} [name] 
     * @param {string} [product] 
     * @param {Array<string>} [product2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductConfigurationApi
     */
    public getProductConfigurationCollection(page?: number, pagination?: boolean, name?: string, product?: string, product2?: Array<string>, options?: RawAxiosRequestConfig) {
        return ProductConfigurationApiFp(this.configuration).getProductConfigurationCollection(page, pagination, name, product, product2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a ProductConfiguration resource.
     * @summary Retrieves a ProductConfiguration resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductConfigurationApi
     */
    public getProductConfigurationItem(id: string, options?: RawAxiosRequestConfig) {
        return ProductConfigurationApiFp(this.configuration).getProductConfigurationItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * QuestionApi - axios parameter creator
 * @export
 */
export const QuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Question resource.
         * @summary Retrieves a Question resource.
         * @param {string} id Question identifier
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsAnswersGetSubresourceQuestionSubresource: async (id: string, page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiQuestionsAnswersGetSubresourceQuestionSubresource', 'id', id)
            const localVarPath = `/api/questions/{id}/answers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Question resources.
         * @summary Retrieves the collection of Question resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Question resource.
         * @summary Retrieves a Question resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuestionItem', 'id', id)
            const localVarPath = `/api/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a Question resource.
         * @summary Retrieves a Question resource.
         * @param {string} id Question identifier
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsAnswersGetSubresourceQuestionSubresource(id: string, page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnswerMinimumRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsAnswersGetSubresourceQuestionSubresource(id, page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionApi.apiQuestionsAnswersGetSubresourceQuestionSubresource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the collection of Question resources.
         * @summary Retrieves the collection of Question resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionMinimumRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionApi.getQuestionCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Question resource.
         * @summary Retrieves a Question resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionApi.getQuestionItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionApiFp(configuration)
    return {
        /**
         * Retrieves a Question resource.
         * @summary Retrieves a Question resource.
         * @param {string} id Question identifier
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsAnswersGetSubresourceQuestionSubresource(id: string, page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<AnswerMinimumRead>> {
            return localVarFp.apiQuestionsAnswersGetSubresourceQuestionSubresource(id, page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Question resources.
         * @summary Retrieves the collection of Question resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<QuestionMinimumRead>> {
            return localVarFp.getQuestionCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Question resource.
         * @summary Retrieves a Question resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionItem(id: string, options?: any): AxiosPromise<QuestionFullRead> {
            return localVarFp.getQuestionItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI {
    /**
     * Retrieves a Question resource.
     * @summary Retrieves a Question resource.
     * @param {string} id Question identifier
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionsAnswersGetSubresourceQuestionSubresource(id: string, page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionsAnswersGetSubresourceQuestionSubresource(id, page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Question resources.
     * @summary Retrieves the collection of Question resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public getQuestionCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).getQuestionCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Question resource.
     * @summary Retrieves a Question resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public getQuestionItem(id: string, options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).getQuestionItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * QuestionTypeApi - axios parameter creator
 * @export
 */
export const QuestionTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a QuestionType resource.
         * @summary Retrieves a QuestionType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionTypeItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuestionTypeItem', 'id', id)
            const localVarPath = `/api/question_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionTypeApi - functional programming interface
 * @export
 */
export const QuestionTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a QuestionType resource.
         * @summary Retrieves a QuestionType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionTypeItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionTypeFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionTypeItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionTypeApi.getQuestionTypeItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuestionTypeApi - factory interface
 * @export
 */
export const QuestionTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionTypeApiFp(configuration)
    return {
        /**
         * Retrieves a QuestionType resource.
         * @summary Retrieves a QuestionType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionTypeItem(id: string, options?: any): AxiosPromise<QuestionTypeFullRead> {
            return localVarFp.getQuestionTypeItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionTypeApi - object-oriented interface
 * @export
 * @class QuestionTypeApi
 * @extends {BaseAPI}
 */
export class QuestionTypeApi extends BaseAPI {
    /**
     * Retrieves a QuestionType resource.
     * @summary Retrieves a QuestionType resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionTypeApi
     */
    public getQuestionTypeItem(id: string, options?: RawAxiosRequestConfig) {
        return QuestionTypeApiFp(this.configuration).getQuestionTypeItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Report resource.
         * @summary Retrieves a Report resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentReportReportItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssessmentReportReportItem', 'id', id)
            const localVarPath = `/api/assessments/{id}/report`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a Report resource.
         * @summary Retrieves a Report resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentReportReportItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentReportReportItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.getAssessmentReportReportItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * Retrieves a Report resource.
         * @summary Retrieves a Report resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentReportReportItem(id: string, options?: any): AxiosPromise<ReportFullRead> {
            return localVarFp.getAssessmentReportReportItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * Retrieves a Report resource.
     * @summary Retrieves a Report resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getAssessmentReportReportItem(id: string, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).getAssessmentReportReportItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportElementBadgesApi - axios parameter creator
 * @export
 */
export const ReportElementBadgesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a ReportElementBadges resource.
         * @summary Retrieves a ReportElementBadges resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportElementBadgesItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportElementBadgesItem', 'id', id)
            const localVarPath = `/api/report_element_badges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a ReportElementBadges resource.
         * @summary Creates a ReportElementBadges resource.
         * @param {ReportElementBadgesMinimumWrite} reportElementBadgesMinimumWrite The new ReportElementBadges resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportElementBadgesCollection: async (reportElementBadgesMinimumWrite: ReportElementBadgesMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportElementBadgesMinimumWrite' is not null or undefined
            assertParamExists('postReportElementBadgesCollection', 'reportElementBadgesMinimumWrite', reportElementBadgesMinimumWrite)
            const localVarPath = `/api/report_element_badges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportElementBadgesMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the ReportElementBadges resource.
         * @summary Replaces the ReportElementBadges resource.
         * @param {string} id Resource identifier
         * @param {ReportElementBadgesMinimumWrite} reportElementBadgesMinimumWrite The updated ReportElementBadges resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReportElementBadgesItem: async (id: string, reportElementBadgesMinimumWrite: ReportElementBadgesMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putReportElementBadgesItem', 'id', id)
            // verify required parameter 'reportElementBadgesMinimumWrite' is not null or undefined
            assertParamExists('putReportElementBadgesItem', 'reportElementBadgesMinimumWrite', reportElementBadgesMinimumWrite)
            const localVarPath = `/api/report_element_badges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportElementBadgesMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportElementBadgesApi - functional programming interface
 * @export
 */
export const ReportElementBadgesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportElementBadgesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a ReportElementBadges resource.
         * @summary Retrieves a ReportElementBadges resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportElementBadgesItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportElementBadgesFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportElementBadgesItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportElementBadgesApi.getReportElementBadgesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a ReportElementBadges resource.
         * @summary Creates a ReportElementBadges resource.
         * @param {ReportElementBadgesMinimumWrite} reportElementBadgesMinimumWrite The new ReportElementBadges resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReportElementBadgesCollection(reportElementBadgesMinimumWrite: ReportElementBadgesMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportElementBadgesFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReportElementBadgesCollection(reportElementBadgesMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportElementBadgesApi.postReportElementBadgesCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the ReportElementBadges resource.
         * @summary Replaces the ReportElementBadges resource.
         * @param {string} id Resource identifier
         * @param {ReportElementBadgesMinimumWrite} reportElementBadgesMinimumWrite The updated ReportElementBadges resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReportElementBadgesItem(id: string, reportElementBadgesMinimumWrite: ReportElementBadgesMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportElementBadgesFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReportElementBadgesItem(id, reportElementBadgesMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportElementBadgesApi.putReportElementBadgesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportElementBadgesApi - factory interface
 * @export
 */
export const ReportElementBadgesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportElementBadgesApiFp(configuration)
    return {
        /**
         * Retrieves a ReportElementBadges resource.
         * @summary Retrieves a ReportElementBadges resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportElementBadgesItem(id: string, options?: any): AxiosPromise<ReportElementBadgesFullRead> {
            return localVarFp.getReportElementBadgesItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a ReportElementBadges resource.
         * @summary Creates a ReportElementBadges resource.
         * @param {ReportElementBadgesMinimumWrite} reportElementBadgesMinimumWrite The new ReportElementBadges resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportElementBadgesCollection(reportElementBadgesMinimumWrite: ReportElementBadgesMinimumWrite, options?: any): AxiosPromise<ReportElementBadgesFullRead> {
            return localVarFp.postReportElementBadgesCollection(reportElementBadgesMinimumWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the ReportElementBadges resource.
         * @summary Replaces the ReportElementBadges resource.
         * @param {string} id Resource identifier
         * @param {ReportElementBadgesMinimumWrite} reportElementBadgesMinimumWrite The updated ReportElementBadges resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReportElementBadgesItem(id: string, reportElementBadgesMinimumWrite: ReportElementBadgesMinimumWrite, options?: any): AxiosPromise<ReportElementBadgesFullRead> {
            return localVarFp.putReportElementBadgesItem(id, reportElementBadgesMinimumWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportElementBadgesApi - object-oriented interface
 * @export
 * @class ReportElementBadgesApi
 * @extends {BaseAPI}
 */
export class ReportElementBadgesApi extends BaseAPI {
    /**
     * Retrieves a ReportElementBadges resource.
     * @summary Retrieves a ReportElementBadges resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportElementBadgesApi
     */
    public getReportElementBadgesItem(id: string, options?: RawAxiosRequestConfig) {
        return ReportElementBadgesApiFp(this.configuration).getReportElementBadgesItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a ReportElementBadges resource.
     * @summary Creates a ReportElementBadges resource.
     * @param {ReportElementBadgesMinimumWrite} reportElementBadgesMinimumWrite The new ReportElementBadges resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportElementBadgesApi
     */
    public postReportElementBadgesCollection(reportElementBadgesMinimumWrite: ReportElementBadgesMinimumWrite, options?: RawAxiosRequestConfig) {
        return ReportElementBadgesApiFp(this.configuration).postReportElementBadgesCollection(reportElementBadgesMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the ReportElementBadges resource.
     * @summary Replaces the ReportElementBadges resource.
     * @param {string} id Resource identifier
     * @param {ReportElementBadgesMinimumWrite} reportElementBadgesMinimumWrite The updated ReportElementBadges resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportElementBadgesApi
     */
    public putReportElementBadgesItem(id: string, reportElementBadgesMinimumWrite: ReportElementBadgesMinimumWrite, options?: RawAxiosRequestConfig) {
        return ReportElementBadgesApiFp(this.configuration).putReportElementBadgesItem(id, reportElementBadgesMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportElementRatingApi - axios parameter creator
 * @export
 */
export const ReportElementRatingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the ReportElementRating resource.
         * @summary Removes the ReportElementRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportElementRatingItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteReportElementRatingItem', 'id', id)
            const localVarPath = `/api/report_element_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a ReportElementRating resource.
         * @summary Retrieves a ReportElementRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportElementRatingItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportElementRatingItem', 'id', id)
            const localVarPath = `/api/report_element_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a ReportElementRating resource.
         * @summary Creates a ReportElementRating resource.
         * @param {ReportElementRatingMinimumWrite} reportElementRatingMinimumWrite The new ReportElementRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportElementRatingCollection: async (reportElementRatingMinimumWrite: ReportElementRatingMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportElementRatingMinimumWrite' is not null or undefined
            assertParamExists('postReportElementRatingCollection', 'reportElementRatingMinimumWrite', reportElementRatingMinimumWrite)
            const localVarPath = `/api/report_element_ratings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportElementRatingMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the ReportElementRating resource.
         * @summary Replaces the ReportElementRating resource.
         * @param {string} id Resource identifier
         * @param {ReportElementRatingMinimumWrite} reportElementRatingMinimumWrite The updated ReportElementRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReportElementRatingItem: async (id: string, reportElementRatingMinimumWrite: ReportElementRatingMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putReportElementRatingItem', 'id', id)
            // verify required parameter 'reportElementRatingMinimumWrite' is not null or undefined
            assertParamExists('putReportElementRatingItem', 'reportElementRatingMinimumWrite', reportElementRatingMinimumWrite)
            const localVarPath = `/api/report_element_ratings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportElementRatingMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportElementRatingApi - functional programming interface
 * @export
 */
export const ReportElementRatingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportElementRatingApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the ReportElementRating resource.
         * @summary Removes the ReportElementRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportElementRatingItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportElementRatingItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportElementRatingApi.deleteReportElementRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a ReportElementRating resource.
         * @summary Retrieves a ReportElementRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportElementRatingItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportElementRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportElementRatingItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportElementRatingApi.getReportElementRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a ReportElementRating resource.
         * @summary Creates a ReportElementRating resource.
         * @param {ReportElementRatingMinimumWrite} reportElementRatingMinimumWrite The new ReportElementRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReportElementRatingCollection(reportElementRatingMinimumWrite: ReportElementRatingMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportElementRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReportElementRatingCollection(reportElementRatingMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportElementRatingApi.postReportElementRatingCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the ReportElementRating resource.
         * @summary Replaces the ReportElementRating resource.
         * @param {string} id Resource identifier
         * @param {ReportElementRatingMinimumWrite} reportElementRatingMinimumWrite The updated ReportElementRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReportElementRatingItem(id: string, reportElementRatingMinimumWrite: ReportElementRatingMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportElementRatingFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReportElementRatingItem(id, reportElementRatingMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportElementRatingApi.putReportElementRatingItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportElementRatingApi - factory interface
 * @export
 */
export const ReportElementRatingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportElementRatingApiFp(configuration)
    return {
        /**
         * Removes the ReportElementRating resource.
         * @summary Removes the ReportElementRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportElementRatingItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReportElementRatingItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a ReportElementRating resource.
         * @summary Retrieves a ReportElementRating resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportElementRatingItem(id: string, options?: any): AxiosPromise<ReportElementRatingFullRead> {
            return localVarFp.getReportElementRatingItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a ReportElementRating resource.
         * @summary Creates a ReportElementRating resource.
         * @param {ReportElementRatingMinimumWrite} reportElementRatingMinimumWrite The new ReportElementRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportElementRatingCollection(reportElementRatingMinimumWrite: ReportElementRatingMinimumWrite, options?: any): AxiosPromise<ReportElementRatingFullRead> {
            return localVarFp.postReportElementRatingCollection(reportElementRatingMinimumWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the ReportElementRating resource.
         * @summary Replaces the ReportElementRating resource.
         * @param {string} id Resource identifier
         * @param {ReportElementRatingMinimumWrite} reportElementRatingMinimumWrite The updated ReportElementRating resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReportElementRatingItem(id: string, reportElementRatingMinimumWrite: ReportElementRatingMinimumWrite, options?: any): AxiosPromise<ReportElementRatingFullRead> {
            return localVarFp.putReportElementRatingItem(id, reportElementRatingMinimumWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportElementRatingApi - object-oriented interface
 * @export
 * @class ReportElementRatingApi
 * @extends {BaseAPI}
 */
export class ReportElementRatingApi extends BaseAPI {
    /**
     * Removes the ReportElementRating resource.
     * @summary Removes the ReportElementRating resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportElementRatingApi
     */
    public deleteReportElementRatingItem(id: string, options?: RawAxiosRequestConfig) {
        return ReportElementRatingApiFp(this.configuration).deleteReportElementRatingItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a ReportElementRating resource.
     * @summary Retrieves a ReportElementRating resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportElementRatingApi
     */
    public getReportElementRatingItem(id: string, options?: RawAxiosRequestConfig) {
        return ReportElementRatingApiFp(this.configuration).getReportElementRatingItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a ReportElementRating resource.
     * @summary Creates a ReportElementRating resource.
     * @param {ReportElementRatingMinimumWrite} reportElementRatingMinimumWrite The new ReportElementRating resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportElementRatingApi
     */
    public postReportElementRatingCollection(reportElementRatingMinimumWrite: ReportElementRatingMinimumWrite, options?: RawAxiosRequestConfig) {
        return ReportElementRatingApiFp(this.configuration).postReportElementRatingCollection(reportElementRatingMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the ReportElementRating resource.
     * @summary Replaces the ReportElementRating resource.
     * @param {string} id Resource identifier
     * @param {ReportElementRatingMinimumWrite} reportElementRatingMinimumWrite The updated ReportElementRating resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportElementRatingApi
     */
    public putReportElementRatingItem(id: string, reportElementRatingMinimumWrite: ReportElementRatingMinimumWrite, options?: RawAxiosRequestConfig) {
        return ReportElementRatingApiFp(this.configuration).putReportElementRatingItem(id, reportElementRatingMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportScoreRangeApi - axios parameter creator
 * @export
 */
export const ReportScoreRangeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of ReportScoreRange resources.
         * @summary Retrieves the collection of ReportScoreRange resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportScoreRangeCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report_score_ranges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a ReportScoreRange resource.
         * @summary Retrieves a ReportScoreRange resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportScoreRangeItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportScoreRangeItem', 'id', id)
            const localVarPath = `/api/report_score_ranges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a ReportScoreRange resource.
         * @summary Creates a ReportScoreRange resource.
         * @param {ReportScoreRange} reportScoreRange The new ReportScoreRange resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportScoreRangeCollection: async (reportScoreRange: ReportScoreRange, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportScoreRange' is not null or undefined
            assertParamExists('postReportScoreRangeCollection', 'reportScoreRange', reportScoreRange)
            const localVarPath = `/api/report_score_ranges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportScoreRange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportScoreRangeApi - functional programming interface
 * @export
 */
export const ReportScoreRangeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportScoreRangeApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of ReportScoreRange resources.
         * @summary Retrieves the collection of ReportScoreRange resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportScoreRangeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportScoreRangeFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportScoreRangeCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportScoreRangeApi.getReportScoreRangeCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a ReportScoreRange resource.
         * @summary Retrieves a ReportScoreRange resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportScoreRangeItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportScoreRangeFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportScoreRangeItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportScoreRangeApi.getReportScoreRangeItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a ReportScoreRange resource.
         * @summary Creates a ReportScoreRange resource.
         * @param {ReportScoreRange} reportScoreRange The new ReportScoreRange resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReportScoreRangeCollection(reportScoreRange: ReportScoreRange, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportScoreRangeFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReportScoreRangeCollection(reportScoreRange, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportScoreRangeApi.postReportScoreRangeCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportScoreRangeApi - factory interface
 * @export
 */
export const ReportScoreRangeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportScoreRangeApiFp(configuration)
    return {
        /**
         * Retrieves the collection of ReportScoreRange resources.
         * @summary Retrieves the collection of ReportScoreRange resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportScoreRangeCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<ReportScoreRangeFullRead>> {
            return localVarFp.getReportScoreRangeCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a ReportScoreRange resource.
         * @summary Retrieves a ReportScoreRange resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportScoreRangeItem(id: string, options?: any): AxiosPromise<ReportScoreRangeFullRead> {
            return localVarFp.getReportScoreRangeItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a ReportScoreRange resource.
         * @summary Creates a ReportScoreRange resource.
         * @param {ReportScoreRange} reportScoreRange The new ReportScoreRange resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportScoreRangeCollection(reportScoreRange: ReportScoreRange, options?: any): AxiosPromise<ReportScoreRangeFullRead> {
            return localVarFp.postReportScoreRangeCollection(reportScoreRange, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportScoreRangeApi - object-oriented interface
 * @export
 * @class ReportScoreRangeApi
 * @extends {BaseAPI}
 */
export class ReportScoreRangeApi extends BaseAPI {
    /**
     * Retrieves the collection of ReportScoreRange resources.
     * @summary Retrieves the collection of ReportScoreRange resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportScoreRangeApi
     */
    public getReportScoreRangeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return ReportScoreRangeApiFp(this.configuration).getReportScoreRangeCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a ReportScoreRange resource.
     * @summary Retrieves a ReportScoreRange resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportScoreRangeApi
     */
    public getReportScoreRangeItem(id: string, options?: RawAxiosRequestConfig) {
        return ReportScoreRangeApiFp(this.configuration).getReportScoreRangeItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a ReportScoreRange resource.
     * @summary Creates a ReportScoreRange resource.
     * @param {ReportScoreRange} reportScoreRange The new ReportScoreRange resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportScoreRangeApi
     */
    public postReportScoreRangeCollection(reportScoreRange: ReportScoreRange, options?: RawAxiosRequestConfig) {
        return ReportScoreRangeApiFp(this.configuration).postReportScoreRangeCollection(reportScoreRange, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportTypeApi - axios parameter creator
 * @export
 */
export const ReportTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of ReportType resources.
         * @summary Retrieves the collection of ReportType resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTypeCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a ReportType resource.
         * @summary Retrieves a ReportType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTypeItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportTypeItem', 'id', id)
            const localVarPath = `/api/report_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportTypeApi - functional programming interface
 * @export
 */
export const ReportTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of ReportType resources.
         * @summary Retrieves the collection of ReportType resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportTypeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportTypeCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportTypeApi.getReportTypeCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a ReportType resource.
         * @summary Retrieves a ReportType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportTypeItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportTypeItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportTypeApi.getReportTypeItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportTypeApi - factory interface
 * @export
 */
export const ReportTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportTypeApiFp(configuration)
    return {
        /**
         * Retrieves the collection of ReportType resources.
         * @summary Retrieves the collection of ReportType resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTypeCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getReportTypeCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a ReportType resource.
         * @summary Retrieves a ReportType resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTypeItem(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.getReportTypeItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportTypeApi - object-oriented interface
 * @export
 * @class ReportTypeApi
 * @extends {BaseAPI}
 */
export class ReportTypeApi extends BaseAPI {
    /**
     * Retrieves the collection of ReportType resources.
     * @summary Retrieves the collection of ReportType resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTypeApi
     */
    public getReportTypeCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return ReportTypeApiFp(this.configuration).getReportTypeCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a ReportType resource.
     * @summary Retrieves a ReportType resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTypeApi
     */
    public getReportTypeItem(id: string, options?: RawAxiosRequestConfig) {
        return ReportTypeApiFp(this.configuration).getReportTypeItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportTypeElementBadgeRequirementsApi - axios parameter creator
 * @export
 */
export const ReportTypeElementBadgeRequirementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of ReportTypeElementBadgeRequirements resources.
         * @summary Retrieves the collection of ReportTypeElementBadgeRequirements resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTypeElementBadgeRequirementsCollection: async (page?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report_type_element_badge_requirements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a ReportTypeElementBadgeRequirements resource.
         * @summary Retrieves a ReportTypeElementBadgeRequirements resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTypeElementBadgeRequirementsItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportTypeElementBadgeRequirementsItem', 'id', id)
            const localVarPath = `/api/report_type_element_badge_requirements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportTypeElementBadgeRequirementsApi - functional programming interface
 * @export
 */
export const ReportTypeElementBadgeRequirementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportTypeElementBadgeRequirementsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of ReportTypeElementBadgeRequirements resources.
         * @summary Retrieves the collection of ReportTypeElementBadgeRequirements resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportTypeElementBadgeRequirementsCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportTypeElementBadgeRequirementsFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportTypeElementBadgeRequirementsCollection(page, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportTypeElementBadgeRequirementsApi.getReportTypeElementBadgeRequirementsCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a ReportTypeElementBadgeRequirements resource.
         * @summary Retrieves a ReportTypeElementBadgeRequirements resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportTypeElementBadgeRequirementsItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportTypeElementBadgeRequirementsFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportTypeElementBadgeRequirementsItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportTypeElementBadgeRequirementsApi.getReportTypeElementBadgeRequirementsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportTypeElementBadgeRequirementsApi - factory interface
 * @export
 */
export const ReportTypeElementBadgeRequirementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportTypeElementBadgeRequirementsApiFp(configuration)
    return {
        /**
         * Retrieves the collection of ReportTypeElementBadgeRequirements resources.
         * @summary Retrieves the collection of ReportTypeElementBadgeRequirements resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTypeElementBadgeRequirementsCollection(page?: number, pagination?: boolean, options?: any): AxiosPromise<Array<ReportTypeElementBadgeRequirementsFullRead>> {
            return localVarFp.getReportTypeElementBadgeRequirementsCollection(page, pagination, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a ReportTypeElementBadgeRequirements resource.
         * @summary Retrieves a ReportTypeElementBadgeRequirements resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportTypeElementBadgeRequirementsItem(id: string, options?: any): AxiosPromise<ReportTypeElementBadgeRequirementsFullRead> {
            return localVarFp.getReportTypeElementBadgeRequirementsItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportTypeElementBadgeRequirementsApi - object-oriented interface
 * @export
 * @class ReportTypeElementBadgeRequirementsApi
 * @extends {BaseAPI}
 */
export class ReportTypeElementBadgeRequirementsApi extends BaseAPI {
    /**
     * Retrieves the collection of ReportTypeElementBadgeRequirements resources.
     * @summary Retrieves the collection of ReportTypeElementBadgeRequirements resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTypeElementBadgeRequirementsApi
     */
    public getReportTypeElementBadgeRequirementsCollection(page?: number, pagination?: boolean, options?: RawAxiosRequestConfig) {
        return ReportTypeElementBadgeRequirementsApiFp(this.configuration).getReportTypeElementBadgeRequirementsCollection(page, pagination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a ReportTypeElementBadgeRequirements resource.
     * @summary Retrieves a ReportTypeElementBadgeRequirements resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTypeElementBadgeRequirementsApi
     */
    public getReportTypeElementBadgeRequirementsItem(id: string, options?: RawAxiosRequestConfig) {
        return ReportTypeElementBadgeRequirementsApiFp(this.configuration).getReportTypeElementBadgeRequirementsItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportUserAnswerApi - axios parameter creator
 * @export
 */
export const ReportUserAnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the ReportUserAnswer resource.
         * @summary Removes the ReportUserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportUserAnswerItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteReportUserAnswerItem', 'id', id)
            const localVarPath = `/api/report_user_answers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a ReportUserAnswer resource.
         * @summary Retrieves a ReportUserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportUserAnswerItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportUserAnswerItem', 'id', id)
            const localVarPath = `/api/report_user_answers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a ReportUserAnswer resource.
         * @summary Creates a ReportUserAnswer resource.
         * @param {ReportUserAnswerMinimumWrite} reportUserAnswerMinimumWrite The new ReportUserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportUserAnswerCollection: async (reportUserAnswerMinimumWrite: ReportUserAnswerMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportUserAnswerMinimumWrite' is not null or undefined
            assertParamExists('postReportUserAnswerCollection', 'reportUserAnswerMinimumWrite', reportUserAnswerMinimumWrite)
            const localVarPath = `/api/report_user_answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportUserAnswerMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the ReportUserAnswer resource.
         * @summary Replaces the ReportUserAnswer resource.
         * @param {string} id Resource identifier
         * @param {ReportUserAnswerMinimumWrite} reportUserAnswerMinimumWrite The updated ReportUserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReportUserAnswerItem: async (id: string, reportUserAnswerMinimumWrite: ReportUserAnswerMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putReportUserAnswerItem', 'id', id)
            // verify required parameter 'reportUserAnswerMinimumWrite' is not null or undefined
            assertParamExists('putReportUserAnswerItem', 'reportUserAnswerMinimumWrite', reportUserAnswerMinimumWrite)
            const localVarPath = `/api/report_user_answers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportUserAnswerMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportUserAnswerApi - functional programming interface
 * @export
 */
export const ReportUserAnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportUserAnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the ReportUserAnswer resource.
         * @summary Removes the ReportUserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportUserAnswerItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportUserAnswerItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportUserAnswerApi.deleteReportUserAnswerItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a ReportUserAnswer resource.
         * @summary Retrieves a ReportUserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportUserAnswerItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportUserAnswerFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportUserAnswerItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportUserAnswerApi.getReportUserAnswerItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a ReportUserAnswer resource.
         * @summary Creates a ReportUserAnswer resource.
         * @param {ReportUserAnswerMinimumWrite} reportUserAnswerMinimumWrite The new ReportUserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReportUserAnswerCollection(reportUserAnswerMinimumWrite: ReportUserAnswerMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportUserAnswerFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReportUserAnswerCollection(reportUserAnswerMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportUserAnswerApi.postReportUserAnswerCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the ReportUserAnswer resource.
         * @summary Replaces the ReportUserAnswer resource.
         * @param {string} id Resource identifier
         * @param {ReportUserAnswerMinimumWrite} reportUserAnswerMinimumWrite The updated ReportUserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReportUserAnswerItem(id: string, reportUserAnswerMinimumWrite: ReportUserAnswerMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportUserAnswerFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReportUserAnswerItem(id, reportUserAnswerMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportUserAnswerApi.putReportUserAnswerItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportUserAnswerApi - factory interface
 * @export
 */
export const ReportUserAnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportUserAnswerApiFp(configuration)
    return {
        /**
         * Removes the ReportUserAnswer resource.
         * @summary Removes the ReportUserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportUserAnswerItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReportUserAnswerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a ReportUserAnswer resource.
         * @summary Retrieves a ReportUserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportUserAnswerItem(id: string, options?: any): AxiosPromise<ReportUserAnswerFullRead> {
            return localVarFp.getReportUserAnswerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a ReportUserAnswer resource.
         * @summary Creates a ReportUserAnswer resource.
         * @param {ReportUserAnswerMinimumWrite} reportUserAnswerMinimumWrite The new ReportUserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportUserAnswerCollection(reportUserAnswerMinimumWrite: ReportUserAnswerMinimumWrite, options?: any): AxiosPromise<ReportUserAnswerFullRead> {
            return localVarFp.postReportUserAnswerCollection(reportUserAnswerMinimumWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the ReportUserAnswer resource.
         * @summary Replaces the ReportUserAnswer resource.
         * @param {string} id Resource identifier
         * @param {ReportUserAnswerMinimumWrite} reportUserAnswerMinimumWrite The updated ReportUserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReportUserAnswerItem(id: string, reportUserAnswerMinimumWrite: ReportUserAnswerMinimumWrite, options?: any): AxiosPromise<ReportUserAnswerFullRead> {
            return localVarFp.putReportUserAnswerItem(id, reportUserAnswerMinimumWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportUserAnswerApi - object-oriented interface
 * @export
 * @class ReportUserAnswerApi
 * @extends {BaseAPI}
 */
export class ReportUserAnswerApi extends BaseAPI {
    /**
     * Removes the ReportUserAnswer resource.
     * @summary Removes the ReportUserAnswer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportUserAnswerApi
     */
    public deleteReportUserAnswerItem(id: string, options?: RawAxiosRequestConfig) {
        return ReportUserAnswerApiFp(this.configuration).deleteReportUserAnswerItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a ReportUserAnswer resource.
     * @summary Retrieves a ReportUserAnswer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportUserAnswerApi
     */
    public getReportUserAnswerItem(id: string, options?: RawAxiosRequestConfig) {
        return ReportUserAnswerApiFp(this.configuration).getReportUserAnswerItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a ReportUserAnswer resource.
     * @summary Creates a ReportUserAnswer resource.
     * @param {ReportUserAnswerMinimumWrite} reportUserAnswerMinimumWrite The new ReportUserAnswer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportUserAnswerApi
     */
    public postReportUserAnswerCollection(reportUserAnswerMinimumWrite: ReportUserAnswerMinimumWrite, options?: RawAxiosRequestConfig) {
        return ReportUserAnswerApiFp(this.configuration).postReportUserAnswerCollection(reportUserAnswerMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the ReportUserAnswer resource.
     * @summary Replaces the ReportUserAnswer resource.
     * @param {string} id Resource identifier
     * @param {ReportUserAnswerMinimumWrite} reportUserAnswerMinimumWrite The updated ReportUserAnswer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportUserAnswerApi
     */
    public putReportUserAnswerItem(id: string, reportUserAnswerMinimumWrite: ReportUserAnswerMinimumWrite, options?: RawAxiosRequestConfig) {
        return ReportUserAnswerApiFp(this.configuration).putReportUserAnswerItem(id, reportUserAnswerMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAssessmentsGetSubresourceUserSubresource: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersAssessmentsGetSubresourceUserSubresource', 'id', id)
            const localVarPath = `/api/users/{id}/assessments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserMinimumWrite} userMinimumWrite The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUserCollection: async (userMinimumWrite: UserMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userMinimumWrite' is not null or undefined
            assertParamExists('createUserUserCollection', 'userMinimumWrite', userMinimumWrite)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the User resource.
         * @summary Removes the User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserItem', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenUserItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTokenUserItem', 'id', id)
            const localVarPath = `/api/users/{id}/token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [emailAddress] 
         * @param {Array<string>} [emailAddress2] 
         * @param {string} [externalId] 
         * @param {Array<string>} [externalId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCollection: async (page?: number, pagination?: boolean, firstName?: string, lastName?: string, emailAddress?: string, emailAddress2?: Array<string>, externalId?: string, externalId2?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (emailAddress2) {
                localVarQueryParameter['emailAddress[]'] = emailAddress2;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (externalId2) {
                localVarQueryParameter['externalId[]'] = externalId2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserItem', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the User resource.
         * @summary Replaces the User resource.
         * @param {string} id Resource identifier
         * @param {UserMinimumWrite} userMinimumWrite The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserItem: async (id: string, userMinimumWrite: UserMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putUserItem', 'id', id)
            // verify required parameter 'userMinimumWrite' is not null or undefined
            assertParamExists('putUserItem', 'userMinimumWrite', userMinimumWrite)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersAssessmentsGetSubresourceUserSubresource(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentMinimumRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersAssessmentsGetSubresourceUserSubresource(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUsersAssessmentsGetSubresourceUserSubresource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserMinimumWrite} userMinimumWrite The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserUserCollection(userMinimumWrite: UserMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserUserCollection(userMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.createUserUserCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the User resource.
         * @summary Removes the User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteUserItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenUserItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTokenUserItem200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenUserItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getTokenUserItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [emailAddress] 
         * @param {Array<string>} [emailAddress2] 
         * @param {string} [externalId] 
         * @param {Array<string>} [externalId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCollection(page?: number, pagination?: boolean, firstName?: string, lastName?: string, emailAddress?: string, emailAddress2?: Array<string>, externalId?: string, externalId2?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserFullRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCollection(page, pagination, firstName, lastName, emailAddress, emailAddress2, externalId, externalId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the User resource.
         * @summary Replaces the User resource.
         * @param {string} id Resource identifier
         * @param {UserMinimumWrite} userMinimumWrite The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserItem(id: string, userMinimumWrite: UserMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserItem(id, userMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.putUserItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAssessmentsGetSubresourceUserSubresource(id: string, options?: any): AxiosPromise<Array<AssessmentMinimumRead>> {
            return localVarFp.apiUsersAssessmentsGetSubresourceUserSubresource(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserMinimumWrite} userMinimumWrite The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUserCollection(userMinimumWrite: UserMinimumWrite, options?: any): AxiosPromise<UserFullRead> {
            return localVarFp.createUserUserCollection(userMinimumWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the User resource.
         * @summary Removes the User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenUserItem(id: string, options?: any): AxiosPromise<GetTokenUserItem200Response> {
            return localVarFp.getTokenUserItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [emailAddress] 
         * @param {Array<string>} [emailAddress2] 
         * @param {string} [externalId] 
         * @param {Array<string>} [externalId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCollection(page?: number, pagination?: boolean, firstName?: string, lastName?: string, emailAddress?: string, emailAddress2?: Array<string>, externalId?: string, externalId2?: Array<string>, options?: any): AxiosPromise<Array<UserFullRead>> {
            return localVarFp.getUserCollection(page, pagination, firstName, lastName, emailAddress, emailAddress2, externalId, externalId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItem(id: string, options?: any): AxiosPromise<UserFullRead> {
            return localVarFp.getUserItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the User resource.
         * @summary Replaces the User resource.
         * @param {string} id Resource identifier
         * @param {UserMinimumWrite} userMinimumWrite The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserItem(id: string, userMinimumWrite: UserMinimumWrite, options?: any): AxiosPromise<UserFullRead> {
            return localVarFp.putUserItem(id, userMinimumWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id User identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUsersAssessmentsGetSubresourceUserSubresource(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUsersAssessmentsGetSubresourceUserSubresource(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a User resource.
     * @summary Creates a User resource.
     * @param {UserMinimumWrite} userMinimumWrite The new User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUserUserCollection(userMinimumWrite: UserMinimumWrite, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createUserUserCollection(userMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the User resource.
     * @summary Removes the User resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserItem(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getTokenUserItem(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getTokenUserItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of User resources.
     * @summary Retrieves the collection of User resources.
     * @param {number} [page] The collection page number
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [emailAddress] 
     * @param {Array<string>} [emailAddress2] 
     * @param {string} [externalId] 
     * @param {Array<string>} [externalId2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserCollection(page?: number, pagination?: boolean, firstName?: string, lastName?: string, emailAddress?: string, emailAddress2?: Array<string>, externalId?: string, externalId2?: Array<string>, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserCollection(page, pagination, firstName, lastName, emailAddress, emailAddress2, externalId, externalId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserItem(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the User resource.
     * @summary Replaces the User resource.
     * @param {string} id Resource identifier
     * @param {UserMinimumWrite} userMinimumWrite The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUserItem(id: string, userMinimumWrite: UserMinimumWrite, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).putUserItem(id, userMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserAnswerApi - axios parameter creator
 * @export
 */
export const UserAnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces the UserAnswer resource.
         * @summary Replaces the UserAnswer resource.
         * @param {string} id Resource identifier
         * @param {UserAnswerMinimumWrite} userAnswerMinimumWrite The updated UserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserAnswerUserAnswerItem: async (id: string, userAnswerMinimumWrite: UserAnswerMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeUserAnswerUserAnswerItem', 'id', id)
            // verify required parameter 'userAnswerMinimumWrite' is not null or undefined
            assertParamExists('changeUserAnswerUserAnswerItem', 'userAnswerMinimumWrite', userAnswerMinimumWrite)
            const localVarPath = `/api/user_answers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAnswerMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the UserAnswer resource.
         * @summary Removes the UserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAnswerItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserAnswerItem', 'id', id)
            const localVarPath = `/api/user_answers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a UserAnswer resource.
         * @summary Retrieves a UserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAnswerItem: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserAnswerItem', 'id', id)
            const localVarPath = `/api/user_answers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a UserAnswer resource.
         * @summary Creates a UserAnswer resource.
         * @param {UserAnswerMinimumWrite} userAnswerMinimumWrite The new UserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToQuestionUserAnswerCollection: async (userAnswerMinimumWrite: UserAnswerMinimumWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAnswerMinimumWrite' is not null or undefined
            assertParamExists('respondToQuestionUserAnswerCollection', 'userAnswerMinimumWrite', userAnswerMinimumWrite)
            const localVarPath = `/api/user_answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAnswerMinimumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAnswerApi - functional programming interface
 * @export
 */
export const UserAnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * Replaces the UserAnswer resource.
         * @summary Replaces the UserAnswer resource.
         * @param {string} id Resource identifier
         * @param {UserAnswerMinimumWrite} userAnswerMinimumWrite The updated UserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserAnswerUserAnswerItem(id: string, userAnswerMinimumWrite: UserAnswerMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAnswerFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserAnswerUserAnswerItem(id, userAnswerMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAnswerApi.changeUserAnswerUserAnswerItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the UserAnswer resource.
         * @summary Removes the UserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserAnswerItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserAnswerItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAnswerApi.deleteUserAnswerItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a UserAnswer resource.
         * @summary Retrieves a UserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAnswerItem(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAnswerFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAnswerItem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAnswerApi.getUserAnswerItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a UserAnswer resource.
         * @summary Creates a UserAnswer resource.
         * @param {UserAnswerMinimumWrite} userAnswerMinimumWrite The new UserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async respondToQuestionUserAnswerCollection(userAnswerMinimumWrite: UserAnswerMinimumWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAnswerFullRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.respondToQuestionUserAnswerCollection(userAnswerMinimumWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAnswerApi.respondToQuestionUserAnswerCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserAnswerApi - factory interface
 * @export
 */
export const UserAnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAnswerApiFp(configuration)
    return {
        /**
         * Replaces the UserAnswer resource.
         * @summary Replaces the UserAnswer resource.
         * @param {string} id Resource identifier
         * @param {UserAnswerMinimumWrite} userAnswerMinimumWrite The updated UserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserAnswerUserAnswerItem(id: string, userAnswerMinimumWrite: UserAnswerMinimumWrite, options?: any): AxiosPromise<UserAnswerFullRead> {
            return localVarFp.changeUserAnswerUserAnswerItem(id, userAnswerMinimumWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the UserAnswer resource.
         * @summary Removes the UserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAnswerItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserAnswerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a UserAnswer resource.
         * @summary Retrieves a UserAnswer resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAnswerItem(id: string, options?: any): AxiosPromise<UserAnswerFullRead> {
            return localVarFp.getUserAnswerItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a UserAnswer resource.
         * @summary Creates a UserAnswer resource.
         * @param {UserAnswerMinimumWrite} userAnswerMinimumWrite The new UserAnswer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToQuestionUserAnswerCollection(userAnswerMinimumWrite: UserAnswerMinimumWrite, options?: any): AxiosPromise<UserAnswerFullRead> {
            return localVarFp.respondToQuestionUserAnswerCollection(userAnswerMinimumWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAnswerApi - object-oriented interface
 * @export
 * @class UserAnswerApi
 * @extends {BaseAPI}
 */
export class UserAnswerApi extends BaseAPI {
    /**
     * Replaces the UserAnswer resource.
     * @summary Replaces the UserAnswer resource.
     * @param {string} id Resource identifier
     * @param {UserAnswerMinimumWrite} userAnswerMinimumWrite The updated UserAnswer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAnswerApi
     */
    public changeUserAnswerUserAnswerItem(id: string, userAnswerMinimumWrite: UserAnswerMinimumWrite, options?: RawAxiosRequestConfig) {
        return UserAnswerApiFp(this.configuration).changeUserAnswerUserAnswerItem(id, userAnswerMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the UserAnswer resource.
     * @summary Removes the UserAnswer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAnswerApi
     */
    public deleteUserAnswerItem(id: string, options?: RawAxiosRequestConfig) {
        return UserAnswerApiFp(this.configuration).deleteUserAnswerItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a UserAnswer resource.
     * @summary Retrieves a UserAnswer resource.
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAnswerApi
     */
    public getUserAnswerItem(id: string, options?: RawAxiosRequestConfig) {
        return UserAnswerApiFp(this.configuration).getUserAnswerItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a UserAnswer resource.
     * @summary Creates a UserAnswer resource.
     * @param {UserAnswerMinimumWrite} userAnswerMinimumWrite The new UserAnswer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAnswerApi
     */
    public respondToQuestionUserAnswerCollection(userAnswerMinimumWrite: UserAnswerMinimumWrite, options?: RawAxiosRequestConfig) {
        return UserAnswerApiFp(this.configuration).respondToQuestionUserAnswerCollection(userAnswerMinimumWrite, options).then((request) => request(this.axios, this.basePath));
    }
}



