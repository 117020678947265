import { AssessmentClient } from '../lib/assessment-client';
import * as Api from '../lib/typescript-axios';
import { AssessmentEngine } from './assessment-engine';
import { AssessmentTypeIri } from './assessment-type-iri';
import { PersonalityAssessmentEngine } from './personality-assessment-engine';
import { ReportEngine } from './report-engine';

export class AssessmentEngineFactory {
  private static async getAssessment(
    basePath: string,
    userId: string,
    token: string,
    productConfigurationId: string,
    assessmentId?: string,
    locale?: string,
  ): Promise<Api.AssessmentFullRead> {
    const client = new AssessmentClient(basePath, token, locale);

    if (assessmentId === undefined || assessmentId === null) {
      return client.startAssessment(userId, productConfigurationId);
    }

    return client.getAssessment(assessmentId);
  }

  private static async getMinimumAsessment(
    basePath: string,
    token: string,
    assessmentId: string,
    locale?: string,
  ): Promise<Api.AssessmentMinimumRead> {
    const client = new AssessmentClient(basePath, token, locale);

    return client.getMinimumAssessment(assessmentId);
  }

  public static async getPersonalityAssessmentEngine(
    client: AssessmentClient,
    assessment: Api.AssessmentFullRead,
    userId: string,
    token: string,
    hasCareers: boolean,
    hasBadges: boolean,
  ): Promise<PersonalityAssessmentEngine> {
    if (
      assessment.productConfiguration.assessmentType !==
      AssessmentTypeIri.PERSONALITY_ASSESSMENT_TYPE_IRI
    ) {
      throw new Error('invalid assessment type');
    }

    const [ careerClusters, careerClusterRatings ] = await Promise.all([client.getCareerClusters(), client.getCareerClusterRatings()]);

    return new PersonalityAssessmentEngine(
      userId,
      client,
      assessment,
      careerClusters,
      careerClusterRatings,
      hasCareers,
      hasBadges,
    );
  }

  public static async getAssessmentEngine(
    basePath: string,
    userId: string,
    token: string,
    productConfigurationId: string,
    hasCareers: boolean,
    hasBadges: boolean,
    enableReportBlocking: boolean,
    assessmentId?: string,
    locale?: string,
  ): Promise<AssessmentEngine | PersonalityAssessmentEngine | ReportEngine> {
    const assessmentClient: AssessmentClient = new AssessmentClient(basePath, token, locale);
    let assessmentMinimumRead: Api.AssessmentMinimumRead | null = null;
    let assessmentFullRead: Api.AssessmentFullRead | null = null;

    if (assessmentId) {
      assessmentMinimumRead = await AssessmentEngineFactory.getMinimumAsessment(
        basePath,
        token,
        assessmentId,
        locale,
      );

      if (
        assessmentMinimumRead.status === AssessmentEngine.STATE_REPORT &&
        assessmentMinimumRead.achieveWorksReport
      ) {
        const achieveWorksReportId = ReportEngine.getIdFromIri(
          assessmentMinimumRead.achieveWorksReport,
        );

        return ReportEngine.getReportEngine(
          basePath,
          userId,
          token,
          achieveWorksReportId,
          hasCareers,
          hasBadges,
          enableReportBlocking,
          assessmentMinimumRead.isResultReviewed,
          locale,
        );
      }
    }

    assessmentFullRead = await AssessmentEngineFactory.getAssessment(
      basePath,
      userId,
      token,
      productConfigurationId,
      assessmentId,
      locale,
    );

    if (
      assessmentFullRead &&
      assessmentFullRead.productConfiguration.assessmentType ===
        AssessmentTypeIri.PERSONALITY_ASSESSMENT_TYPE_IRI
    ) {
      return this.getPersonalityAssessmentEngine(
        assessmentClient,
        assessmentFullRead,
        userId,
        token,
        hasCareers,
        hasBadges,
      );
    }

    return new AssessmentEngine(
      userId,
      assessmentClient,
      assessmentFullRead,
      hasCareers,
      hasBadges,
    );
  }
}
