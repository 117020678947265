import { boolean } from 'fp-ts';
import { ConfigurationValidationError } from '../errors/configuration-validation-error';

export interface Language {
    text: string;
    value: string;
    rtl: boolean
}
export const defaultLocales : Array<string> = [
  'en-US',
  'es-ES',
  'pt-BR',
  'fr-CA',
  'zh-CN',
  'ar-EG',
  'en-AU'
],
  defaultLanguage: Language = {
    text: 'English',
    value: 'en-US',
    rtl: false
  },
  availableLanguages: Array<Language> = [
    { text: 'English',
      value: 'en-US',
      rtl: false },
    { text: 'Español',
      value: 'es-ES',
      rtl: false },
    { text: 'Português',
      value: 'pt-BR',
      rtl: false },
    { text: 'Français',
      value: 'fr-CA',
      rtl: false },
    { text: '中文',
      value: 'zh-CN',
      rtl: false },
    { text: 'العربية',
      value: 'ar-EG',
      rtl: true },
    { text: 'العربية',
      value: 'ar-SA',
      rtl: true },
    { text: 'English (Australia)',
      value: 'en-AU',
      rtl: false }
  ],
  getLanguage = (locale: string): Language => {
    const matchingLanguage: Language | undefined = availableLanguages.find(language => language.value === locale);

    if (matchingLanguage) {
      return {
        text: matchingLanguage.text,
        value: matchingLanguage.value,
        rtl: matchingLanguage.rtl,
      };
    }
    throw new ConfigurationValidationError(`invalid language: ${locale}`);

  };
